import { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import useToken from './useToken';
import Header from "./Header";
import Footer from "./Footer";
import config from "../config";
import axios from "axios";
import handleError from "./handleError";

const ShareReferral = () => {
  const { token, setToken } = useToken();

  const history = useHistory();

  const referralList1 = [
    {
      created_date:"10 June 2033",
      name:"Chintu",
      cowrie_token:"fgdf654h"
    },
    {
      created_date:"10 June 2033",
      name:"Chintu",
      cowrie_token:"fgdf654h"
    },
    {
      created_date:"10 June 2033",
      name:"Chintu",
      cowrie_token:"fgdf654h"
    } ];

  const [referralCode, setReferralCode] = useState(
    localStorage.getItem("refer_code")
  );
  const [copyText, setCopyText] = useState("COPY CODE");
  const [copyLink, setCopyLink] = useState("COPY LINK");
  const [referralLink, setReferralLink] = useState("COPY LINK");
  const [referralList, setReferralList] = useState([]);
  const pathname = window.location.origin;
  //console.log(referralCode)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getReferralCode();
    setTimeout(() => setCopyText("COPY CODE"), 5000);
    setTimeout(() => setCopyLink("COPY LINK"), 5000);
    setReferralLink(pathname + "/signup?referal_code=" + referralCode);
  }, [copyText, copyLink]);

  const copyReferral = (e) => {
    //console.log(e.target.dataset.code);
    navigator.clipboard.writeText(referralCode);
    setCopyText("COPIED");
  };

  const copyReferralLink = (e) => {
    //console.log(e.target.dataset.link);
    navigator.clipboard.writeText(referralLink);
    setCopyLink("COPIED");
  };

  const getReferralCode = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/fetch-my-referrals/`, {
        headers: headers,
      })
      .then((response) => {
        //console.log(response.data.data.referrals)
        setReferralList(response.data.data.referrals);
      })
      .catch(function (error) {
        handleError(error,setToken);
      });
  };

  return (
    <>
      <Header activeTab="myprofile" />
      <section className="sec setting-sec">
        <div className="container">
          <div className="setting-box">
            <div className="setting-list">
              <div className="setting-head">
                <span className="back-btn">
                  <Link to="/myprofile">
                    <i className="fas fa-chevron-left"></i>
                  </Link>
                </span>
                <h3>My Referral Code</h3>
              </div>
              <div className="update-col text-center">
                <p className="ref-link">{referralCode}</p>
              </div>
              <div className="btn-row max-center">
                <div className="buy-btn">
                  <button
                    data-code={referralCode}
                    onClick={(e) => copyReferral(e)}
                    className="custom-btn share-btn"
                  >
                    {copyText}
                  </button>
                </div>
                <div className="buy-btn">
                  <button
                    data-link={referralLink}
                    onClick={(e) => copyReferralLink(e)}
                    className="custom-btn share-btn"
                  >
                    {copyLink}
                  </button>
                </div>
              </div>
              <div className="max-center">
                <div className="buy-btn">
                  {/* <button data-link={referralLink} onClick={(e) => copyReferralLink(e)} className="custom-btn">{copyLink}</button> */}
                  <Link
                    to={{
                      pathname: "https://community.mycowrie.org/regiments/the-cowrie-refer-and-earn-program/",
                    }}
                    target="_blank"
                    className="custom-btn share-btn"
                  >
                    LEARN MORE
                  </Link>
                </div>
              </div>
              <div className="update-col text-center">
                <p className="share-by">
                  <strong>SHARE MY LINK:</strong>{" "}
                  <a href={`whatsapp://send?text=${referralLink}`} data-action="share/whatsapp/share">
                    <i className="fab fa-whatsapp"></i>
                  </a>{" "}
                  <a href={`mailto:?subject=I wanted you to see this site &body=Check out this site ${referralLink} .`}>
                    <i className="fas fa-envelope"></i>
                  </a>{" "}
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${referralLink}`} target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook-f"></i>
                  </a>{" "}
                  {/* <a href="#">
                    <i className="fab fa-tiktok"></i>
                  </a>{" "} */}
                  <a href={`https://www.instagram.com/sharer.php?u=${referralLink}`} target="_blank" rel="noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>{" "}
                  <a href={`http://twitter.com/share?url=${referralLink}`} target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter"></i>
                  </a>{" "}
                  <a href={`https://telegram.me/share/url?url=${referralLink}`} target="_blank" rel="noreferrer">
                    <i className="fas fa-paper-plane"></i>
                  </a>{" "}
                </p>

                <p>
                  <strong>My Referrals History</strong>
                </p>
                <table className="table">
                  <thead>
                    <tr>
                      <td align="left" valign="middle">
                        <strong>DATE</strong>
                      </td>
                      <td align="center" valign="middle">
                        <strong>PERSON REFERRED</strong>
                      </td>
                      <td align="right" valign="middle">
                        <strong>EARNED</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {referralList.map((data, index) => (
                      <tr key={index}>
                        <td align="left" valign="middle">
                          {data.created_date}
                        </td>
                        <td align="center" valign="middle">
                          {data.name}
                        </td>
                        <td align="right" valign="middle">
                          {parseFloat(data.cowrie_commission).toFixed(2)} COWRIE
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ShareReferral;
