import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import useToken from "./useToken";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import handleError from "./handleError";
import { Modal, Button } from "react-bootstrap";

const StakedTokens = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [isStakedCancelModal, setIsStakedCancelModal] = useState(false);
  const [errorAPI, setErrorAPI] = useState("");
  const [deleteId, setDeleteId] = useState();

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };
  useEffect(() => {
    axios
      .get(`${config.URL}/trade/staked-tokens/`, {
        headers: headers,
      })
      .then(({ data }) => {
        console.log("data 456", data);
        setTransactions(data);
        console.log("###", transactions);
      })
      .catch(function (error) {
        handleError(error, setToken);
      });
  }, []);

  function updateAfterCancelAPI(id) {
    console.log("res.data.id", id);

    setTransactions(
      transactions.map((trans) => {
        if (id == trans.id) {
          console.log("res.data", trans);
          return { ...trans, status: "Canceled" };
        } else {
          console.log("res else", trans);
          return trans;
        }
      })
    );
  }

  const StakedCancelAPI = () => {
    axios
      .post(
        `${config.URL}/trade/staked-token-canceled/`,
        {
          id: deleteId,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res);
        setIsStakedCancelModal(false);
        updateAfterCancelAPI(res.data.data);
        console.log("res", res, "res.data.id", res.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          setErrorAPI(err.response.data.message);
        }
      });
  };

  if (!token) {
    history.push("/login");
  }

  const [isLoading, setIsLoading] = useState(false);
  const first_name = localStorage.getItem("first_name");
  //  console.log("first_name: ", first_name);

  function addDaysInDate(date, days) {
    var date = new Date(date);
    return date.setDate(date.getDate() + days);
  }

  return (
    <div>
      <Header activeTab="wallet" isLoading={isLoading} />
      <StakedCancelModal
        show={isStakedCancelModal}
        setShow={setIsStakedCancelModal}
      />
      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span className="back-btn" onClick={history.goBack}>
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link to="/wallet" className="active">
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup">SIGN UP</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <div className="mov-h wallet-top">
        <div className="container">
          <div className="top-header wallet-header">
            <div className="wallet-head trans-head">
              <span className="back-btn" onClick={history.goBack}>
                <i className="fas fa-chevron-left"></i>
              </span>
              <h2>My Staked Tokens</h2>
              <p>Hello {first_name}</p>

              <div className="tabitems">
                <div class="tab-content setoverflow" id="myTabContent">
                  {transactions.length === 0 ? (
                    <h3 className=" text-success text-center py-5 my-3">
                      {" "}
                      No Record Found{" "}
                    </h3>
                  ) : (
                    <div
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      {/* {transactions} */}
                      <table className="table">
                        <thead>
                          <tr>
                            <td align="center" valign="middle">
                              <strong>Start Date</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Token</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Pool Index</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Status</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Amount</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Fee</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Rate</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Days</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Earn Cowrie</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Wallet Address</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Network Chain</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>End Date</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Action</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions.map((data, index) => (
                            <tr key={index}>
                              <td align="center" valign="middle">
                                {new Date(data.created_date).toLocaleDateString(
                                  "en-us",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                              <td align="center" valign="middle">
                                COWRIE
                              </td>
                              <td align="center" valign="middle">
                                {data.pool_index}
                              </td>
                              <td align="center" valign="middle">
                                {data.status}
                              </td>
                              <td align="center" valign="middle">
                                {data.amount}
                              </td>
                              <td align="center" valign="middle">
                                {data.fee}
                              </td>
                              <td align="center" valign="middle">
                                {data.rate}
                              </td>
                              <td align="center" valign="middle">
                                {data.days}
                              </td>
                              <td align="center" valign="middle">
                                {data.earned_cowrie}
                              </td>
                              <td align="center" valign="middle">
                                {data.wallet_address}
                              </td>
                              <td align="center" valign="middle">
                                {data.network_chain}
                              </td>
                              <td align="center" valign="middle">
                                {new Date(
                                  addDaysInDate(data.created_date, data.days)
                                ).toLocaleDateString("en-us", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </td>
                              <td>
                                {data.status == "Staked" ? (
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                      setIsStakedCancelModal(true);
                                      setDeleteId(data.id);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                ) : (
                                  <h6>{data.status}</h6>
                                )}
                                {/* {data.status == "Canceled" ? (
                                  <h6> Already Deleted</h6>
                                ) : (
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                      setIsStakedCancelModal(true);
                                      setDeleteId(data.id);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                )} */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="sec wallet-sec">
        <div className="container">
          <div className="setting-box dest-head" style={{ maxWidth: "750px" }}>
            <div className="setting-list">
              <div className="setting-head ">
                <span className="back-btn" onClick={history.goBack}>
                  <i className="fas fa-chevron-left"></i>
                </span>
                <h2>My Staked Tokens</h2>
                <p>Hello {first_name} </p>

                <div className="tabitems">
                  <div class="tab-content setoverflow" id="myTabContent">
                    {transactions.length === 0 ? (
                      <h3 className=" text-success text-center py-5 my-3">
                        {" "}
                        No Record Found{" "}
                      </h3>
                    ) : (
                      <div
                        class="tab-pane fade show active"
                        id="home1"
                        role="tabpanel"
                        aria-labelledby="home-tab1"
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <td align="center" valign="middle">
                                <strong>Start Date</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Pool Index</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Status</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Amount</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Fee</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Rate</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Days</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Earn Cowrie</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Wallet Address</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Network Chain</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>End Date</strong>
                              </td>
                              <td align="center" valign="middle">
                                <strong>Action</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((data, index) => (
                              <tr key={index}>
                                <td align="center" valign="middle">
                                  {new Date(
                                    data.created_date
                                  ).toLocaleDateString("en-us", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}
                                </td>
                                <td align="center" valign="middle">
                                  {data.pool_index + 1}
                                </td>
                                <td align="center" valign="middle">
                                  {data.status}
                                </td>
                                <td align="center" valign="middle">
                                  {data.amount}
                                </td>
                                <td align="center" valign="middle">
                                  {data.fee}
                                </td>
                                <td align="center" valign="middle">
                                  {data.rate}
                                </td>
                                <td align="center" valign="middle">
                                  {data.days}
                                </td>
                                <td align="center" valign="middle">
                                  {data.earned_cowrie}
                                </td>
                                <td align="center" valign="middle">
                                  {data.wallet_address}
                                </td>
                                <td align="center" valign="middle">
                                  {data.network_chain}
                                </td>
                                <td align="center" valign="middle">
                                  {new Date(
                                    addDaysInDate(data.created_date, data.days)
                                  ).toLocaleDateString("en-us", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}
                                </td>
                                <td>
                                  {data.status == "Staked" ? (
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        setIsStakedCancelModal(true);
                                        setDeleteId(data.id);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  ) : (
                                    <h6>{data.status}</h6>
                                  )}
                                  {/* {data.status == "Canceled" ? (
                                    <h6> Already Canceled</h6>
                                  ) : data.status == "Completed" ? (
                                    <h6> Already Completed</h6>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        setIsStakedCancelModal(true);
                                        setDeleteId(data.id);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  )} */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
  function StakedCancelModal({ show, setShow }) {
    const history = useHistory();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
      <Modal show={show} onHide={handleClose}>
        <div className="modal-header modal-headerBlock">
          <h5 className="modal-title modal-headerTitle" id="exampleModalLabel">
            <b>Cancel</b>
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body modalBody">
          <p>Are you sure you want to cancel your Staking COWRIE ?</p>
        </div>
        <Modal.Footer class="modal-footer justify-content-start">
          <Button
            variant="success"
            onClick={(e) => {
              StakedCancelAPI();
            }}
          >
            Yes I'M sure
          </Button>
          <Button data-dismiss="modal" variant="error" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default StakedTokens;
