import { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import config from "../config";
import axios from "axios";
import useToken from "./useToken";
import { Modal, Button } from "react-bootstrap";
import InfoModal from "./InfoModal";
import ReactTooltip from "react-tooltip";
import OneForYouPayment from "./OneForYouPayment";
import BankTransferPayment from "./BankTransferPayment";
import handleError from "./handleError";
import DisclaimerModal from "./DisclaimerModal";

const Buy = ({}) => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  // if(!token) {
  //   history.push('/login')
  // }
  // const [state, setState] = useState({
  //   isOpen: false
  // });

  // const openModal = () => this.setState({ isOpen: true });
  // const closeModal = () => this.setState({ isOpen: false });

  const [dollerToZar, setdollerToZar] = useState(0.1);
  const [livePrice, setlivePrice] = useState(0.1);
  const [conversionPrice, setConversionPrice] = useState(1.0);
  const [activeStep, setActiveStep] = useState(0);
  const [currency, setCurrency] = useState("ZAR");
  const [amount, setAmount] = useState(18.0);
  const [feePercent, setFeePercent] = useState(6.0);
  const [amountWithFee, setAmountWithFee] = useState(0.0);
  const [cowrieToken, setCowrieToken] = useState(0.0);
  const [paymentMethod, setPaymentMethod] = useState("banktransfer");
  const [showLoginRequiredModal, setShowLoginRequiredModal] = useState(false);
  const [showPhoneVerifiedRequiredModal, setshowPhoneVerifiedRequiredModal] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [staticData, setStaticData] = useState(null);
  const [userCountryName, setUserCountryName] = useState("");
  const [internationalUserModal, setInternationalUserModal] = useState(false);
  const [disclaimerModalKey, setDisclaimerModalKey] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/setting/`)
      .then(({ data }) => setStaticData(data))
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setTimeout(() => setErrorMessage(""), 5000);
  }, [errorMessage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    //  console.log("activeStep", activeStep);
  }, [activeStep]);

  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + token.access,
  };
  useEffect(() => {
    axios
      .get(`${config.URL}/trade/fetch-live-price-pancake-swap/`, {
        headers: headers,
      })
      .then(({ data }) => {
        console.log("data: ", data.data);
        setlivePrice(data.data);
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
  }, []);

  const next = () => setActiveStep((active) => active + 1);
  const prev = () => setActiveStep((active) => active - 1);

  const paymentMethodFee = {
    paygate: 3,
    ozow: 3,
    "1foryou": 7,
    banktransfer: 0,
    qr: 0,
    crypto: 0,
    "zar-wallet": 0,
  };

  useEffect(() => {
    localStorage.setItem("location", location.pathname);
    setUserCountryName(localStorage.getItem("country"));
    //
  }, []);

  useEffect(() => {
    axios
      .get(`${config.URL}/fetch-token-price-and-fee/`)
      .then(({ data }) => {
        setdollerToZar(parseFloat(data.data.dollar_price_in_zar));
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    setCowrieToken(
      parseFloat(amount / dollerToZar / livePrice.toFixed(3)).toFixed(2)
    );
  }, [amount, livePrice, dollerToZar]);

  useEffect(() => {
    setAmountWithFee(
      parseFloat(amount + (amount * feePercent) / 100).toFixed(2)
    );
  }, [feePercent, amount]);

  useEffect(() => {
    setFeePercent(paymentMethodFee[paymentMethod]);
  }, [paymentMethod]);

  const nextScreen = (e) => {
    e.preventDefault();
    setDisclaimerModalKey(false);
    setIsLoading(true);
    const request_payload = {
      amount: amount,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    //  console.log("request_payload: ", request_payload);
    //  console.log("headers: ", headers);

    axios
      .post(`${config.URL}/check-allowed-buy-limit-view/`, request_payload, {
        headers: headers,
      })
      .then((response) => {
        //  console.log("response: ", response);
        //  console.log("data: ", response.data);
        next();
        // alert("Reponse: " + response.data.message);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          handleError(error, setToken);
        } else {
          setErrorMessage(error.response.data.message);
          // alert(error.response.data.message);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Header activeTab="buy" isLoading={isLoading} />
      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span
              className="back-btn"
              onClick={activeStep ? prev : history.goBack}
            >
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <span className="info-h">
              <i
                data-tip
                data-for="registerTip"
                className="fas fa-question-circle"
              ></i>
              <ReactTooltip id="registerTip" place="top" effect="solid">
                {staticData?.buy_page_conversion_tip &&
                  staticData.buy_page_conversion_tip}
              </ReactTooltip>
            </span>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={` ${
                  showMenu ? "menu-opened manu-overlay bg-overlay-anim" : ""
                }`}
                onClick={() => setShowMenu(!showMenu)}
              ></div>
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          //  console.log(
                          //     "chal rha hai",
                          //     localStorage.getItem("is_mobile_verified")
                          //   );
                          if (
                            localStorage.getItem("is_mobile_verified") ===
                            "true"
                          ) {
                            //  console.log("+++++++");
                            history.push("/wallet");
                          } else {
                            //  console.log("------");
                            setshowPhoneVerifiedRequiredModal(true);
                          }
                        }}
                        className="active"
                      >
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup">SIGN UP</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <DisclaimerModal
        nextScreen={nextScreen}
        show={disclaimerModalKey}
        setShow={setDisclaimerModalKey}
        title="Disclaimer"
      />

      <InfoModal
        show={showLoginRequiredModal}
        setShow={setShowLoginRequiredModal}
        title="Login Required"
        body='To proceed please <a href="/login">Login</a> or <a href="/signup">Signup</a>.'
      />
      <InfoModal
        show={showPhoneVerifiedRequiredModal}
        setShow={setshowPhoneVerifiedRequiredModal}
        title="First verify your phone number"
        body='To proceed please verify your phone number in your <a href="/myprofile">Profile</a>.'
      />

      <InfoModal
        show={internationalUserModal}
        setShow={setInternationalUserModal}
        title="Sorry"
        body="The ICO to South Africans under guarantee has come to an end. <br/><br/> The Cowrie is now listed on decentralized exchanges such as pancakeswap.finance, where it can be purchased by anyone, but not under guarantee. <br/><br/> It will soon be listed on centralized exchanges. Please ensure that you receive advice from a qualified and regulated financial advisor before making a purchase."
      />
      <section className="sec buy-sec top-margin">
        <div className="container">
          {activeStep === 0 && (
            <ConversionScreen
              next={next}
              token={token}
              setToken={setToken}
              amount={amount}
              setAmount={setAmount}
              cowrieToken={cowrieToken}
              setCowrieToken={setCowrieToken}
              setShowLoginRequiredModal={setShowLoginRequiredModal}
              setshowPhoneVerifiedRequiredModal={
                setshowPhoneVerifiedRequiredModal
              }
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              staticData={staticData}
              setIsLoading={setIsLoading}
              userCountryName={userCountryName}
              setInternationalUserModal={setInternationalUserModal}
              setDisclaimerModalKey={setDisclaimerModalKey}
            />
          )}
          {activeStep === 1 && (
            <PaymentMethodScreen
              next={next}
              token={token}
              prev={prev}
              amount={amount}
              setAmount={setAmount}
              cowrieToken={cowrieToken}
              setCowrieToken={setCowrieToken}
              setAmountWithFee={setAmountWithFee}
              setPaymentMethod={setPaymentMethod}
              paymentMethod={paymentMethod}
              staticData={staticData}
              feePercent={feePercent}
            />
          )}
          {activeStep === 2 && (
            <PaymentDetailScreen
              next={next}
              token={token}
              setToken={setToken}
              prev={prev}
              amount={amount}
              setAmount={setAmount}
              cowrieToken={cowrieToken}
              setCowrieToken={setCowrieToken}
              amountWithFee={amountWithFee}
              paymentMethod={paymentMethod}
              conversionPrice={conversionPrice}
              feePercent={feePercent}
              staticData={staticData}
              setIsLoading={setIsLoading}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 3 && (
            <PaymentGatewayScreen
              next={next}
              token={token}
              prev={prev}
              amount={amount}
              setAmount={setAmount}
              cowrieToken={cowrieToken}
              setCowrieToken={setCowrieToken}
              amountWithFee={amountWithFee}
              setToken={setToken}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              feePercent={feePercent}
              paymentMethod={paymentMethod}
              setActiveStep={setActiveStep}
            />
          )}
          {activeStep === 4 && (
            <AfterPaymentScreen
              next={next}
              token={token}
              prev={prev}
              amount={amount}
              setAmount={setAmount}
              cowrieToken={cowrieToken}
              setCowrieToken={setCowrieToken}
              amountWithFee={amountWithFee}
            />
          )}
          {activeStep === 5 && (
            <CowrieAllotedConfirmationScreen
              prev={prev}
              amount={amount}
              setAmount={setAmount}
              cowrieToken={cowrieToken}
              setCowrieToken={setCowrieToken}
              amountWithFee={amountWithFee}
            />
          )}
          {activeStep === 6 && (
            <BankTransferPayment
              next={next}
              token={token}
              prev={prev}
              amount={amount}
              setAmount={setAmount}
              cowrieToken={cowrieToken}
              setCowrieToken={setCowrieToken}
              amountWithFee={amountWithFee}
              setToken={setToken}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              feePercent={feePercent}
              paymentMethod={paymentMethod}
              setActiveStep={setActiveStep}
            />
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

function ConversionScreen(props) {
  const mailBody =
    "Hi%2C%0A%0AI%20want%20to%20purchase%20COWRIE%20for%20more%20that%20R50%20000%2C%20please%20can%20you%20let%20me%20know%20what%20you%20need%20from%20me%3F%20%0A%0AKind%20regards%2C%20%0A%0AExcited%20member%20of%20the%20COWRIE%20Community.";

  const nextScreen = (e) => {
    console.log("caling next ");
    e.preventDefault();
    if (props.amount < 18.0) {
      props.setErrorMessage("Amount cannot be less than R 18");
      return;
    } else if (props.amount > 50000.0) {
      props.setErrorMessage("Amount cannot be more than R 50000");
      return;
    }
    if (props.token) {
      if (localStorage.getItem("is_mobile_verified") === "true") {
        props.setDisclaimerModalKey(true);
        console.log("caling next ");
        // }
      } else {
        props.setshowPhoneVerifiedRequiredModal(true);
      }
    } else {
      props.setShowLoginRequiredModal(true);
    }
  };
  return (
    <div className="setting-box">
      {/* <div className="top-img">
        <img src="assets/images/icon-buy-1.svg" alt="" />
      </div> */}
      <div className="setting-list">
        <div className="setting-head">
          {props.prev && (
            <span
              className="back-btn"
              onClick={() => {
                props.prev();
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </span>
          )}
          <h3>How much COWRIE do you want to buy?</h3>
          <a href="#" className="info-h desk-d">
            <i
              data-tip
              data-for="conversion-tip"
              className="fas fa-question-circle"
            ></i>
            <ReactTooltip id="conversion-tip" place="bottom" effect="solid">
              {props.staticData?.buy_page_conversion_tip}
            </ReactTooltip>
          </a>
        </div>
        <div className="buy-col">
          <div className="buy-con">
            <div className="buy-l">
              <label>CURRENCY</label>
              <input
                type="text"
                placeholder="ZAR"
                value={props.currency}
                onChange={(e) => props.setCurrency(e.target.value)}
                disabled
              />
            </div>
            <div className="buy-r count__itemOp">
              <label>AMOUNT</label>
              <span>R</span>
              <input
                type="text"
                placeholder="xx,xxx.xx"
                value={props.amount}
                onChange={(e) =>
                  e.target.value
                    ? props.setAmount(
                        parseFloat(parseFloat(e.target.value).toFixed(2))
                      )
                    : props.setAmount(0)
                }
                style={{ paddingLeft: "50px" }}
              />
            </div>
          </div>
        </div>

        <div className="buy-price">
          <span className="sign">≈</span>{" "}
          <span className="rate">
            {parseFloat(props.cowrieToken).toFixed(2)}
          </span>{" "}
          <span className="con-logo">
            <img src="assets/images/footer-logo.png" alt="" />{" "}
            <img src="assets/images/logo.png" alt="" />
          </span>
          <p className="buy-min-max">MINIMUM BUY R18 | MAXIMUM BUY R50,000 </p>
          <p className="f-error">{props.errorMessage}</p>
        </div>
        <div className="buy-btn">
          <a
            href={`mailto:cowriefica@schindlers.co.za?subject=I want to purchase COWRIE for more that R50 000&body=${mailBody}`}
            className="custom-btn-buy-more btn-full"
          >
            BUY MORE THAN R 50K
          </a>
          {/* <a
            onClick={() => {
              if (props.token) {
                props.setInternationalUserModal(true);
              } else {
                props.setShowLoginRequiredModal(true);
              }
            }}
            href="#"
            className="custom-btn-buy-more btn-full"
          >
            BUY MORE THAN R 50K
          </a> */}

          <a
            href="#"
            className="custom-btn btn-full"
            onClick={(e) => {
              nextScreen(e);
            }}
          >
            Next
          </a>
          {/* <a
            href="#"
            className="custom-btn btn-full"
            onClick={() => {
              if (props.token) {
                props.setInternationalUserModal(true);
              } else {
                props.setShowLoginRequiredModal(true);
              }
            }}
          >
            Next
          </a> */}
        </div>

        <p className="tc">
          By buying COWRIE I accept all terms and conditions of sale.
        </p>
      </div>
    </div>
  );
}

function PaymentMethodScreen(props) {
  return (
    <div className="setting-box">
      {/* <div className="top-img">
        <img src="assets/images/img-icon.png" alt="" />
      </div> */}
      <div className="setting-list">
        <div className="setting-head">
          <span
            className="back-btn"
            onClick={() => {
              props.prev();
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </span>
          <h3>How do you prefer to pay?</h3>
          <a href="#" className="info-h desk-d">
            <i
              data-tip
              data-for="pay-method-tip"
              className="fas fa-question-circle"
            ></i>
            <ReactTooltip id="pay-method-tip" place="bottom" effect="solid">
              {props.staticData?.buy_page_pay_method_tip}
            </ReactTooltip>
          </a>
        </div>
        <div className="buy-col">
          <div
            className="radio-buttons"
            onChange={(e) => {
              props.setPaymentMethod(e.target.value);
            }}
          >
            <div className="form-group custom-radio">
              <input
                type="radio"
                id="pay4"
                name="payment_method"
                value="banktransfer"
                checked={props.paymentMethod === "banktransfer" ? true : false}
              />
              <label htmlFor="pay4">
                Manual EFT <span>(Zero Fee)</span>
              </label>
            </div>
            <div className="form-group custom-radio">
              <input
                type="radio"
                id="pay2"
                name="payment_method"
                value="ozow"
                checked={props.paymentMethod === "ozow" ? true : false}
              />
              <label htmlFor="pay2">
                Ozow Direct EFT <span>(3% Fee) </span>
              </label>
            </div>
            <div className="form-group custom-radio">
              <input
                type="radio"
                id="pay1"
                name="payment_method"
                value="paygate"
                checked={props.paymentMethod === "paygate" ? true : false}
              />
              <label htmlFor="pay1">
                Card Payments <span>(3% Fee)</span>
              </label>
            </div>

            <div className="form-group custom-radio">
              <input
                type="radio"
                id="pay3"
                name="payment_method"
                value="1foryou"
                checked={props.paymentMethod === "1foryou" ? true : false}
              />
              <label htmlFor="pay3">
                1Voucher <span>(7% Fee)</span>
              </label>
            </div>
            <div className="form-group custom-radio">
              <input
                type="radio"
                id="pay7"
                name="payment_method"
                value="zar-wallet"
                checked={props.paymentMethod === "zar-wallet" ? true : false}
              />
              <label htmlFor="pay7">
                zar-wallet <span>(0% Fee)</span>
              </label>
            </div>
            <div className="form-group custom-radio">
              <input
                type="radio"
                id="pay5"
                name="payment_method"
                value="qr"
                disabled
              />
              <label htmlFor="pay5" className="text-muted">
                QR (Coming Soon)
              </label>
            </div>
            <div className="form-group custom-radio">
              <input
                type="radio"
                id="pay6"
                name="payment_method"
                value="crypto"
                disabled
              />
              <label htmlFor="pay6" className="text-muted">
                Crypto (Coming Soon)
              </label>
            </div>
          </div>
        </div>

        <div className="buy-btn">
          <a
            href="#"
            className="custom-btn"
            onClick={(e) => {
              if (props.paymentMethod === "1foryou") {
                props.setAmount(
                  parseFloat(
                    parseFloat(
                      props.amount / ((100 + parseInt(props.feePercent)) / 100)
                    ).toFixed(2)
                  )
                );
              }
              e.preventDefault();
              props.next();
            }}
          >
            Next
          </a>
        </div>

        <p className="tc">
          By buying COWRIE I accept all terms and conditions of sale.
        </p>
      </div>
    </div>
  );
}

function PaymentDetailScreen(props) {
  const [payRequestID, setPayRequestId] = useState("");
  const [checksum, setChecksum] = useState("");

  useEffect(() => {
    //  console.log("payRequestID: ", payRequestID);
    if (payRequestID !== "") {
      const form = document.getElementsByName("PaygateForm");
      form[0].submit();
    }
  }, [payRequestID]);

  const nextScreen = (e) => {
    e.preventDefault();
    if (props.paymentMethod === "ozow") {
      props.setIsLoading(true);

      const request_payload = {
        amount: props.amount,
        fees: (props.amount * props.feePercent) / 100,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token.access,
      };

      //  console.log("request_payload: ", request_payload);
      //  console.log("headers: ", headers);

      axios
        .post(
          `${config.URL}/ozow-post-payment-request-view/`,
          request_payload,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //  console.log("response: ", response);
          //  console.log("data: ", response.data);
          window.location.href = response.data.data.url;
          // alert("Reponse: " + response.data.message);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            handleError(error, props.setToken);
          } else {
            props.setErrorMessage(error.response.data.message);
            // alert(error.response.data.message);
          }
        })
        .then(() => {
          props.setIsLoading(false);
        });
    } else if (props.paymentMethod === "paygate") {
      props.setIsLoading(true);

      const request_payload = {
        amount: props.amount,
        fees: (props.amount * props.feePercent) / 100,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token.access,
      };

      //  console.log("request_payload: ", request_payload);
      //  console.log("headers: ", headers);

      axios
        .post(`${config.URL}/paygate-payment-request-view/`, request_payload, {
          headers: headers,
        })
        .then((response) => {
          //  console.log("response: ", response);
          //  console.log("data: ", response.data);
          setPayRequestId(response.data.data["PAY_REQUEST_ID"]);

          setChecksum(response.data.data["CHECKSUM"]);

          //console.log('payRequestId: ', payRequestID);
          //console.log('checksum: ', checksum);

          // alert("Reponse: " + response.data.message);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            handleError(error, props.setToken);
          } else {
            props.setErrorMessage(error.response.data.message);
            // alert(error.response.data.message);
          }
        })
        .then(() => {
          props.setIsLoading(false);
        });
    } else if (props.paymentMethod === "zar-wallet") {
      props.setIsLoading(true);

      const request_payload = {
        amount: props.amount,
        fees: (props.amount * props.feePercent) / 100,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token.access,
      };

      //  console.log("request_payload: ", request_payload);
      //  console.log("headers: ", headers);

      axios
        .post(`${config.URL}/zar-wallet/`, request_payload, {
          headers: headers,
        })
        .then((response) => {
          //  console.log("response: ", response);
          //  console.log("data: ", response.data);
          props.setActiveStep(4);
          setTimeout(() => props.next(), 5000);
          //console.log('payRequestId: ', payRequestID);
          //console.log('checksum: ', checksum);

          // alert("Reponse: " + response.data.message);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            handleError(error, props.setToken);
          } else {
            props.setErrorMessage(error.response.data.message);
            // alert(error.response.data.message);
          }
        })
        .then(() => {
          props.setIsLoading(false);
        });
    } else {
      props.next();
    }
  };
  return (
    <div className="setting-box">
      <form
        action="https://secure.paygate.co.za/payweb3/process.trans"
        method="POST"
        name="PaygateForm"
      >
        <input type="hidden" name="PAY_REQUEST_ID" value={payRequestID} />
        <input type="hidden" name="CHECKSUM" value={checksum} />
        <input type="submit" hidden />
      </form>
      <div className="top-img">
        <img src="assets/images/icon-buy-1.svg" alt="" />
      </div>
      <div className="setting-list">
        <div className="setting-head">
          <span
            className="back-btn"
            onClick={() => {
              if (props.paymentMethod === "1foryou") {
                props.setAmount(
                  parseFloat(props.amount) +
                    (props.amount * props.feePercent) / 100
                );
              }
              props.prev();
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </span>
          <h3>
            Buy{" "}
            {props.paymentMethod === "banktransfer"
              ? props.amountWithFee
              : props.cowrieToken}{" "}
            {props.paymentMethod === "banktransfer" ? "ZAR" : "COWRIE"} at{" "}
            <br />R {props.amountWithFee}
          </h3>
          <a href="#" className="info-h desk-d">
            <i
              data-tip
              data-for="conversion-tip"
              className="fas fa-question-circle"
            ></i>
            <ReactTooltip id="conversion-tip" place="bottom" effect="solid">
              {props.staticData?.buy_page_pay_details_tip}
            </ReactTooltip>
          </a>
        </div>
        <div className="setting-col">
          <div className="col-con">
            <span>YOU RECEIVE</span>
            <h4>
              {props.paymentMethod === "banktransfer" ? "ZAR" : "COWRIE"}{" "}
              {props.paymentMethod === "banktransfer"
                ? props.amountWithFee
                : props.cowrieToken}
            </h4>
          </div>
        </div>

        <div className="setting-col" id="service-charge">
          <div className="col-con">
            <span>THIRD PARTY PAYMENT PROCESSING FEE</span>
            <h4>
              Approx ZAR{" "}
              {parseFloat((props.amount * props.feePercent) / 100).toFixed(2)}
            </h4>
            <span>{props.feePercent}%</span>
          </div>
        </div>

        <div className="setting-col">
          <div className="col-con">
            <span>YOU SPEND</span>
            <h4>ZAR {props.amountWithFee}</h4>
          </div>
        </div>
        <div className="buy-btn">
          <p className="f-error">{props.errorMessage}</p>
          <a href="#" className="custom-btn" onClick={nextScreen}>
            CONFIRM
          </a>
        </div>
      </div>
    </div>
  );
}

function PaymentGatewayScreen(props) {
  return (
    <>
      {props.paymentMethod === "1foryou" && (
        <OneForYouPayment
          next={props.next}
          token={props.token}
          prev={props.prev}
          amount={props.amount}
          setAmount={props.setAmount}
          cowrieToken={props.cowrieToken}
          setCowrieToken={props.setCowrieToken}
          amountWithFee={props.amountWithFee}
          setToken={props.setToken}
          errorMessage={props.errorMessage}
          setErrorMessage={props.setErrorMessage}
          setIsLoading={props.setIsLoading}
          isLoading={props.isLoading}
          feePercent={props.feePercent}
          paymentMethod={props.paymentMethod}
        />
      )}

      {props.paymentMethod === "banktransfer" && (
        <BankTransferPaymentCreatedScreen
          prev={props.prev}
          amount={props.amount}
          setAmount={props.setAmount}
          cowrieToken={props.cowrieToken}
          setCowrieToken={props.setCowrieToken}
          amountWithFee={props.amountWithFee}
          setActiveStep={props.setActiveStep}
        />
      )}
    </>
  );
}

function AfterPaymentScreen(props) {
  return (
    <div className="setting-box">
      <div className="top-img succ-img">
        <img src="assets/images/icon-buy-2success.svg" alt="" />
      </div>
      <div className="setting-list">
        <div className="setting-head buy-done">
          <h3>Purchase Successful</h3>
          {/* <p className="f-error">Any error messages can be displayed here</p> */}
        </div>
        {/* <div className="buy-btn">
          <a
            href="#"
            className="custom-btn"
            onClick={(e) => {
              e.preventDefault();
              props.next();
            }}
          >
            Temporary next btn
          </a>
        </div> */}
      </div>
    </div>
  );
}

function CowrieAllotedConfirmationScreen(props) {
  return (
    <div className="setting-box">
      <div className="top-img succ-img">
        <img src="assets/images/icon-buy-3welcome.svg" alt="" />
      </div>
      <div className="setting-list">
        <div className="setting-head buy-done">
          <h3>You bought COWRIE {props.cowrieToken}</h3>
          <p>
            Welcome to the world of COWRIE! Your COWRIE have been allocated and
            safely locked in your wallet.
          </p>
        </div>

        <div className="buy-btn">
          <Link to="/wallet" className="custom-btn">
            Done
          </Link>
        </div>
      </div>
    </div>
  );
}

function BankTransferPaymentCreatedScreen(props) {
  const [understand, setUnderstand] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("user_id"));

  return (
    <div className="setting-box">
      <div className="top-img succ-img">
        <img src="assets/images/icon-eft-payment.svg" alt="" />
      </div>
      <div className="setting-list">
        <div className="setting-head buy-done">
          <h3>
            VERY IMPORTANT
            <br /> Use your unique reference number: {userId}
            {/* {props.cowrieToken} */}
          </h3>
          <p>
            Make sure to use the unique reference number when making payment or
            we will not be able to track your purchase.
            <br />
            Please note banks take at least 2 business days to process deposits
            (longer over weekends.)
            <br />
            Only contact us if you have not used your unique reference code.
            <br />
            <input
              type="checkbox"
              name="i_understand"
              checked={understand}
              onClick={() => {
                setUnderstand(!understand);
              }}
            />{" "}
            I understand
          </p>
        </div>

        <div className="buy-btn">
          {understand ? (
            <button
              onClick={() => {
                props.setActiveStep(6);
              }}
              className="custom-btn"
            >
              GOT IT
            </button>
          ) : (
            <Link to="#" className="custom-btn" style={{ background: "gray" }}>
              GOT IT
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Buy;
