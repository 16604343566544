import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import useToken from "./useToken";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import handleError from "./handleError";

const Withdraw = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [activeStep, setActiveStep] = useState("WithdrawHome");
  const [userBankDetailList, setUserBankDetailList] = useState([]);
  const [selectedBank, setSelectedBank] = useState({});
  const [amountToWithdraw, setAmountToWithdraw] = useState("");
  // const next = () => setActiveStep((active) => active + 1);
  // const prev = () => setActiveStep((active) => active - 1);

  if (!token) {
    history.push("/login");
  }

  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [availableZar, setAvailableZar] = useState(0.0);
  const [otpSent, setOptSent] = useState(false);
  const [count, setCount] = useState(179);
  //  console.log(count);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/fetch-user-bank-detail/`, { headers })
      .then((response) => {
        //  console.log("response: ", response.data.data);
        setUserBankDetailList(response.data.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/fetch-my-cowrie-token/`, {
        headers: headers,
      })
      .then(({ data }) => {
        //  console.log("data: ", data);
        setAvailableZar(parseFloat(data.data.available_zar));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <Header activeTab="wallet" isLoading={isLoading} />
      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span className="back-btn" onClick={history.goBack}>
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={` ${
                  showMenu ? "menu-opened manu-overlay bg-overlay-anim" : ""
                }`}
                onClick={() => setShowMenu(!showMenu)}
              ></div>
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link to="/wallet" className="active">
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup">SIGN UP</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      {activeStep === "WithdrawHome" && (
        <WithdrawHome
          setActiveStep={setActiveStep}
          userBankDetailList={userBankDetailList}
          amountToWithdraw={amountToWithdraw}
          setAmountToWithdraw={setAmountToWithdraw}
          selectedBank={selectedBank}
          setSelectedBank={setSelectedBank}
          token={token}
          otp={otp}
          setOtp={setOtp}
          availableZar={availableZar}
          otpSent={otpSent}
          setOptSent={setOptSent}
          count={count}
          setCount={setCount}
          history={history}
        />
      )}

      {activeStep === "WithdrawReview" && (
        <WithdrawReview
          setActiveStep={setActiveStep}
          selectedBank={selectedBank}
          amountToWithdraw={amountToWithdraw}
          token={token}
          otp={otp}
        />
      )}

      {activeStep === "WithdrawSuccess" && (
        <WithdrawSuccess setActiveStep={setActiveStep} history={history} />
      )}

      <Footer />
    </div>
  );
};

export default Withdraw;

const WithdrawHome = ({
  setActiveStep,
  userBankDetailList,
  selectedBank,
  setSelectedBank,
  amountToWithdraw,
  setAmountToWithdraw,
  token,
  otp,
  setOtp,
  availableZar,
  otpSent,
  setOptSent,
  count,
  setCount,
  history,
}) => {
  const [nextButton, setNextButton] = useState(false);
  const [error, setError] = useState("");
  const optPlaceholder = otpSent
    ? "Please enter OTP"
    : "First Send OTP and then Enter OTP";

  const handleSubmit = () => {
    //  console.log("amountToWithdraw: ", amountToWithdraw);
    if (!selectedBank.bank_name) {
      setError("Choose a Bank");
      // setNextButton(false)
    } else if (
      !amountToWithdraw ||
      amountToWithdraw < 8.6 ||
      amountToWithdraw > availableZar
    ) {
      setError("Enter a valid Amount");
      // setNextButton(false)
    } else if (!otp || otp.toString().length !== 6) {
      setError("Enter OTP of 6 digits");
      // setNextButton(false)
    } else {
      setActiveStep("WithdrawReview");
    }
  };

  const handleWithdrawAll = () => {
    setAmountToWithdraw(availableZar);
  };

  const sendOTP = () => {
    setOptSent(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/trade/send-zar-withdrawal-otp-view/`, { headers })
      .then((response) => {
        //  console.log("response: ", response);
      })
      .catch((error) => {
        console.log("error: ", error);
      });

    const myInterval = setInterval(() => {
      //  console.log(count);
      setCount(count--);
      if (count === 0) {
        clearInterval(myInterval);
        setCount(179);
        setOptSent(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (!selectedBank.bank_name) {
      // setError("Choose a Bank")
      setNextButton(false);
    } else if (
      !amountToWithdraw ||
      amountToWithdraw < 8.6 ||
      amountToWithdraw > availableZar
    ) {
      // setError("Enter a valid Amount")
      setNextButton(false);
    } else if (!otp || otp.toString().length !== 6) {
      // setError("Enter OTP od 6 digits")
      setNextButton(false);
    } else {
      setError("");
      setNextButton(true);
    }
  }, [selectedBank, amountToWithdraw, otp]);

  const time = (
    <div>
      RESEND OTP
      <br />
      in 00:{" "}
      {count.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}{" "}
      SEC
    </div>
  );
  return (
    <div>
      <div className="mov-h wallet-top" style={{ background: "#262626" }}>
        <div className="container">
          <div className="top-header wallet-header top_walletlayer">
            <div className="wallet-head">
              <h2 className="withdraw_h2">Withdraw Rands</h2>
              <p className="zarAvaible">{availableZar} ZAR available</p>
            </div>
          </div>
        </div>
      </div>
      <section className="sec wallet-sec mset">
        <div className="container">
          <div className="setting-box wallet-head">
            <div className="dest-head">
              <h2>Withdraw Rands</h2>
              <p className="zarAvaible">{availableZar} ZAR available</p>
            </div>
            <div className="element_bank text-left">
              <div className="bank_record">
                <div className="bank_tilte">
                  <h3 className="selectBank">Select your Bank account</h3>
                  <a href="/add-bank-account" className="andNew">
                    ADD NEW
                  </a>
                </div>
                <div className="bankSelect">
                  <div className="bankOUterblock">
                    <form action="#" className="formBank">
                      <select
                        name="banks"
                        id="banks"
                        onChange={(e) => {
                          setSelectedBank(JSON.parse(e.target.value));
                        }}
                      >
                        <option value={JSON.stringify({})}>
                          Choose Any one
                        </option>
                        {userBankDetailList.map((a, i) => {
                          return (
                            <option
                              value={JSON.stringify(a)}
                              key={i}
                              selected={selectedBank?.id === a?.id}
                            >
                              {a.bank_name.toUpperCase() +
                                " " +
                                a.account_number}
                            </option>
                          );
                        })}
                      </select>
                    </form>
                  </div>
                  <div className="bankOUterblock">
                    <label>Amount to Withdraw</label>
                    <div className="amountElements">
                      <div className="amountWourter">
                        <input
                          type="number"
                          className="amount_withdraw"
                          required
                          placeholder="Enter amount"
                          value={amountToWithdraw}
                          onChange={(e) => {
                            setAmountToWithdraw(e.target.value);
                          }}
                        />
                      </div>
                      <div className="allwithdraw">
                        <button
                          className="btnall otp-button"
                          onClick={handleWithdrawAll}
                        >
                          WITHDRAW ALL
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="bankOUterblock">
                    <label>Enter OTP</label>
                    <div className="amountElements">
                      <div className="amountWourter" style={{ flex: "1" }}>
                        <input
                          type="text"
                          className="amount_withdraw"
                          required
                          placeholder={optPlaceholder}
                          // disabled={!otpSent}
                          value={otp}
                          onChange={(e) => {
                            setOtp(parseInt(e.target.value) || "");
                          }}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="allwithdraw">
                        <button
                          className="btnall otp-button"
                          onClick={sendOTP}
                          disabled={otpSent}
                          style={otpSent ? { color: "gray" } : {}}
                        >
                          {otpSent ? time : "SEND OTP"}
                        </button>
                      </div>
                    </div>
                  </div>

                  <p className="value_fee">
                    Fee <span className="Price_amount">R8.50</span>
                  </p>
                  <p className="feild-error-y">{error}</p>

                  <div className="buy-btn unlockLayerBtn btnGotitOuter">
                    <div className="unlockLayerBlock btnGotit">
                      <button
                        className="custom-btn button_custom"
                        onClick={() => {
                          history.push("/wallet");
                        }}
                      >
                        CANCEL
                      </button>
                      <button
                        className="custom-btn button_custom"
                        onClick={handleSubmit}
                        // disabled={!nextButton}
                        style={
                          !nextButton
                            ? { background: "gray", marginLeft: "10px" }
                            : { marginLeft: "10px" }
                        }
                      >
                        NEXT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const WithdrawReview = ({
  setActiveStep,
  selectedBank,
  amountToWithdraw,
  token,
  otp,
}) => {
  const [error, setError] = useState("");
  const [subStep, setSubStep] = useState(0);
  const [tfaCode, setTfaCode] = useState("");

  let isTwoEnable = localStorage.getItem("is_two_factor_enabled");

  const handleSubmit = () => {
    setError("");
    //  console.log({
    //     bank_detail: selectedBank.id,
    //     amount: amountToWithdraw,
    //     currency: "zar",
    //     otp: otp,
    //   });

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };

    let payload = {};

    if (isTwoEnable === "true") {
      payload = {
        bank_detail: selectedBank.id,
        amount: amountToWithdraw,
        currency: "zar",
        otp: otp,
        code: tfaCode,
      };
    } else {
      payload = {
        bank_detail: selectedBank.id,
        amount: amountToWithdraw,
        currency: "zar",
        otp: otp,
      };
    }

    axios
      .post(`${config.URL}/trade/zar-withdrawal-view/`, payload, { headers })
      .then((response) => {
        //  console.log("withdrawal response: ", response);
        setActiveStep("WithdrawSuccess");
      })
      .catch((error) => {
        console.log("error: ", error.response.data.message);
        setError(error.response.data.message);
        if (error.response.data.message === "Otp not Verified.") {
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      });
  };

  return (
    <div>
      {subStep === 0 && (
        <div>
          <div
            className=" mov-h pt-md-0 pt-5"
            style={{ background: "#262626", marginTop: "70px" }}
          >
            <div className="container">
              <div className="top-header wallet-header top_walletlayer set_position">
                <div className="wallet-head">
                  <h2>Withdrawal Review</h2>
                </div>
              </div>
            </div>
          </div>
          <section className="sec wallet-sec buy-sec mset">
            <div className="container set_p0">
              <div className="setting-box p-0 success_element_shadow">
                <div className="wallet-head wallet_wdraw set_dnone">
                  <h2>
                    <span class="back-btn">
                      <i
                        className="fas fa-chevron-left"
                        onClick={() => {
                          setActiveStep("WithdrawHome");
                        }}
                      ></i>
                    </span>
                    Withdrawal Review
                  </h2>
                </div>
                <div className="withdraw_elements">
                  <div className="withdraw_outer">
                    <div className="withdraw_Block set_grey">
                      <h4 className="text_draw">YOU’RE WITHDRAWING</h4>
                      <p className="draw_amount">
                        ZAR <span>{amountToWithdraw}</span>
                      </p>
                    </div>
                    <div className="withdraw_Block ">
                      <h4 className="text_draw">FEE CHARGE</h4>
                      <p className="draw_amount">
                        ZAR <span>8.5</span>
                      </p>
                    </div>
                    <div className="withdraw_Block set_grey">
                      <h4 className="text_draw">YOU RECEIVE</h4>
                      <p className="draw_amount">
                        ZAR <span>{amountToWithdraw - 8.5}</span>
                      </p>
                    </div>
                    <div className="withdraw_Block ">
                      <h4 className="text_draw">
                        YOUR WITHDRAWAL WILL BE PAID INTO
                      </h4>
                      <p className="draw_account">
                        {selectedBank.bank_name} {selectedBank.account_number}
                      </p>
                    </div>
                    <p className="feild-error-y">{error}</p>

                    <div className="buy-btn unlockLayerBtn btnGotitOuter custom_BlockBtnNxt">
                      <div className="unlockLayerBlock btnGotit custom_BlockBlcs">
                        <button
                          className="custom-btn button_custom custom_BlockBtn"
                          onClick={() => {
                            if (isTwoEnable === "true") {
                              setSubStep((p) => p + 1);
                            } else {
                              handleSubmit();
                            }
                          }}
                        >
                          NEXT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      {subStep === 1 && (
        <TwoFAAfterOTP
          error={error}
          handleSubmit={handleSubmit}
          tfaCode={tfaCode}
          setTfaCode={setTfaCode}
        />
      )}
    </div>
  );
};

function TwoFAAfterOTP({ handleSubmit, tfaCode, setTfaCode, error }) {
  const { token, setToken } = useToken();

  return (
    <>
      {/* <Header activeTab="login" /> */}
      <div className="setBody">
        <main className="cowrie_code" id="cowrie_codeId">
          <section className="sec wallet-sec buy-sec cowrie_codeSection">
            <div className="container">
              <div className="cowrie_codeInner_container mx-auto">
                <div className="cowrie_codeInner mx-auto">
                  <div className="cowrie_codeInner_sets ">
                    <div className="cowrie_codeHeader">
                      <h3 className="wsuccess">Two Factor Authentication</h3>
                    </div>

                    <div className="code_lines">
                      <p className="withdLIne ">
                        Enter 6 digit code from the Google Authenticator app on
                        your phone.
                      </p>
                    </div>
                    <div className="code_lines">
                      <input
                        type="text"
                        placeholder="Code"
                        maxLength={6}
                        onChange={(e) => {
                          setTfaCode(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className=""
                      style={{ padding: "18px", textAlign: "start" }}
                    >
                      <p
                        className="feild-error-y text-center"
                        style={{ textAlign: "start", fontSize: "16px" }}
                      >
                        {error}
                      </p>
                    </div>

                    {tfaCode.length === 6 ? (
                      <div className="float-left">
                        <button
                          className="custom-btn button_custom btn-sm"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <div className="float-left">
                        <button className="custom-btn button_custom bg-secondary">
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                  {/* <div className="cowrie_codeInner_sets">
                    {twoFactorOTP.length === 6 ? (
                      <div className="cowrie_codeInner_sets__button">
                        <button
                          className="custom-btn button_custom"
                          onClick={() => {
                            test2FAotp();
                          }}
                        >
                          Disable 2FA
                        </button>
                      </div>
                    ) : (
                      <div className="cowrie_codeInner_sets__button">
                        <button className="custom-btn button_custom bg-secondary">
                          Disable 2FA
                        </button>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

const WithdrawSuccess = ({ setActiveStep, history }) => {
  return (
    <div>
      <section className="sec wallet-sec buy-sec pt-5 mset">
        <div className="container">
          <div className="setting-box">
            <div className="success_element">
              <div className="successOuter">
                <div className="successImage">
                  <img src="assets/images/icon-buy-2success.svg" alt="img" />
                </div>
                <div className="successTitle pt-4">
                  <h3 className="wsuccess">Withdraw Success</h3>
                  <p className="withdLIne ">
                    Your withdrawal has been issued successfully.
                  </p>
                  <p className="withdLIne">
                    Depending on your bank. it may take up to 48 hours for the
                    funds to reflect in your account.
                  </p>
                </div>
                <div className="buy-btn unlockLayerBtn">
                  <div className="unlockLayerBlock wth_btns">
                    <div className="HomeBtn">
                      <button
                        className="custom-btn button_custom Hbtn"
                        onClick={() => {
                          history.push("/home");
                        }}
                      >
                        HOME
                      </button>
                    </div>
                    <div className="HomeBtn">
                      <button
                        className="custom-btn button_custom Hbtn"
                        onClick={() => {
                          history.push("/wallet");
                        }}
                      >
                        WALLET
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
