import { useState, useEffect } from "react";
import config from "../config";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import handleError from "./handleError";
import useToken from "./useToken";

const OneForYouPayment = (props) => {
  const [voucherPin, setVoucherPin] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [staticData, setStaticData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { token, setToken } = useToken();

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/setting/`)
      .then(({ data }) => setStaticData(data))
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  const validateContactNumber = (contact_number) => {
    if (/^-?\d+$/.test(contact_number) && contact_number.length === 10) {
      setContactNumber(contact_number);
      props.setErrorMessage("");
    } else {
      setContactNumber(contact_number);
      props.setErrorMessage("Please enter valid contact number.");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    setTimeout(() => {
      props.setIsLoading(false);
    }, 30000);
    if (!voucherPin) {
      props.setErrorMessage("Please enter voucher pin");
      props.setIsLoading(false);
      return;
    } else if (!contactNumber) {
      props.setErrorMessage("Please enter contact number");
      props.setIsLoading(false);
      return;
    }

    const request_payload = {
      voucher_pin: voucherPin.replaceAll(" ", ""),
      contact_number: "27" + String(parseInt(contactNumber)),
      amount: props.amount,
      fees: parseFloat(
        parseFloat((props.amount * props.feePercent) / 100).toFixed(2)
      ),
      // fees: props.amount * props.feePercent / 100
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + props.token.access,
    };

    //  console.log("request_payload: ", request_payload);
    //  console.log("headers: ", headers);

    let response = await axios
      .post(`${config.URL}/redeem-1foryou-voucher/`, request_payload, {
        headers: headers,
      })
      .then((response) => {
        //  console.log("response: ", response);
        //  console.log("data: ", response.data);
        // alert("Reponse: " + response.data.message);
        props.next();
        setTimeout(() => props.next(), 5000);
      })
      .catch(function (error) {
        handleError(error, setToken, props);
      })
      .then(() => {
        props.setIsLoading(false);
      });
  };
  return (
    <div className="setting-box">
      <div className="top-img succ-img">
        <img src="assets/images/icon-14u-redeem.gif" alt="" />
      </div>
      <div className="setting-list">
        <div className="setting-head">
          <span
            className="back-btn"
            onClick={() => {
              props.prev();
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </span>
          <h3>1FORYOU Voucher Redeem</h3>
          <p className="f-error">{props.errorMessage}</p>
          <a href="#" className="info-h desk-d">
            <i
              data-tip
              data-for="conversion-tip"
              className="fas fa-question-circle"
            ></i>
            <ReactTooltip id="conversion-tip" place="bottom" effect="solid">
              {staticData?.login_page_tip}
            </ReactTooltip>
          </a>
        </div>
        <form
          name="1foryouPaymentForm"
          id="1foryouPaymentForm"
          method="POST"
          onSubmit={onSubmit}
        >
          <div className="buy-col">
            <div className="buy-con flex-wrap">
              <div className="set-user">
                <label>Voucher Pin</label>
                <input
                  type="text"
                  placeholder="xxxxxxxxxxxx"
                  value={voucherPin}
                  onChange={(e) => setVoucherPin(e.target.value)}
                />
              </div>
              <div className="set-user">
                <label>Contact Number</label>
                <input
                  type="text"
                  placeholder="xxxxxxxxxx"
                  value={contactNumber}
                  onChange={(e) => validateContactNumber(e.target.value)}
                  maxLength="10"
                />
              </div>
            </div>
          </div>

          <div className="buy-btn">
            <button className="custom-btn" disabled={props.isLoading}>
              Pay
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OneForYouPayment;
