import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import config from "../config";
import useToken from "./useToken";
import QRCode from "qrcode";

export default function TwoFA() {
  const [isLoading, setIsLoading] = useState(false);
  const { token, setToken } = useToken();
  const [qrCode, setQrCode] = useState("");
  const history = useHistory();
  const [src, setSrc] = useState("");
  const [error, setError] = useState("");
  const [twoFactorOTP, setTwoFactorOTP] = useState("");
  const [twoFactorString, setTwoFactorString] = useState("");
  const [buttonCopied, setbuttonCopied] = useState("");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/totp/create/`, { headers })
      .then(({ data }) => {
        console.log("qr data", data);
        setTwoFactorString(data.split("=")[1].split("&")[0]);
        setQrCode(data);
      })
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    QRCode.toDataURL(qrCode).then((data) => {
      setSrc(data);
      console.log("data", data);
    });
  }, [qrCode]);

  // totp/two-factor-verify/

  const test2FAotp = () => {
    axios
      .post(
        `${config.URL}/totp/two-factor-verify/${twoFactorOTP}`,
        {},
        {
          headers,
        }
      )
      .then(({ data }) => {
        console.log("after otp is verified", data);
        setQrCode(data);
        localStorage.setItem("is_two_factor_enabled", data);
        history.push("/myprofile");
      })
      .catch(function (error) {
        console.log("error", error.response.status);
        if (error.response.status === 400) {
          setError(error.response.data.message);
        }
      });
    // .then(() => setIsLoading(false));
  };

  return (
    <>
      <Header activeTab="myprofile" />
      <div className="setBody mb-5">
        <main className="cowrie_code" id="cowrie_codeId">
          <section className="sec wallet-sec buy-sec cowrie_codeSection">
            <div className="container cowrie_codeInner_container">
              <div className="cowrie_codeInner">
                <div className="cowrie_codeInner_sets">
                  <div className="cowrie_codeHeader">
                    <h3 className="wsuccess">Two Factor Authentication</h3>
                  </div>
                  <div className="code_gent_layer">
                    <img src={src} alt="bar-code" />
                  </div>
                  <div className="code_lines">
                    <p className="withdLIne ">
                      <br />
                      In order to use 2FA, scan the QR code to the right into
                      the Google Authenticator app on your phone.
                      <br />
                      <br />
                      <b>OR</b>
                      <br />
                      <br />
                      Copy the following "Setup Key" below and use it as your
                      setup key in your Google Authentication app to get your 6
                      digit code.
                    </p>
                    <div className="code_lines text-center">
                      <p className="withdLIne text-danger">{twoFactorString}</p>
                      <button
                        className="btn-sm btn bnt-secondary"
                        onClick={() => {
                          navigator.clipboard.writeText(twoFactorString);
                          setbuttonCopied("Copied");
                          setTimeout(() => {
                            setbuttonCopied("");
                          }, 1000);
                        }}
                      >
                        Copy Setup Key
                      </button>
                      <div>
                        <p className="text-success mt-3">{buttonCopied}</p>
                      </div>
                    </div>
                  </div>
                  <div className="code_lines mt-5">
                    <input
                      type="text"
                      placeholder="6 Digit Code"
                      maxLength={6}
                      onChange={(e) => {
                        setTwoFactorOTP(e.target.value);
                      }}
                    />
                  </div>
                  <div className="my-2 text-center">
                    <p className="text-warning"> {error} </p>
                  </div>
                </div>
                <div className="cowrie_codeInner_sets ml-2">
                  {twoFactorOTP.length === 6 ? (
                    <div className="cowrie_codeInner_sets__button">
                      <button
                        className="custom-btn button_custom"
                        onClick={() => {
                          test2FAotp();
                        }}
                      >
                        Enable 2FA
                      </button>
                    </div>
                  ) : (
                    <div className="cowrie_codeInner_sets__button">
                      <button className="custom-btn button_custom bg-secondary">
                        Enable 2FA
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
}
