import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import QRCode from "qrcode";
import axios from "axios";
import config from "../config";
import useToken from "./useToken";

import Header from "./Header";
import Footer from "./Footer";

export default function CowrieDeposite() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [src, setSrc] = useState("");
  const { token, setToken } = useToken();
  const [buttonCopied, setbuttonCopied] = useState("");
  const [availableCowrie, setAvailableCowrie] = useState(0.0);
  const [livePrice, setlivePrice] = useState(0.0);
  const [walletButton, setwalletButton] = useState(1);
  const [userWalletAddress, setuserWalletAddress] = useState([]);
  const [iswarningModal, setIswarningModal] = useState(true);
  const [isETHwarningModal, setETHIswarningModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  if (!token) {
    history.push("/login");
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/fetch-my-cowrie-token/`, {
        headers: headers,
      })
      .then(({ data }) => {
        console.log("data.data.available_zar: ", data.data);
        setAvailableCowrie(parseFloat(data.data.available_cowrie));
      })
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .post(`${config.URL}/get-wallet-address/`, {}, { headers: headers })
      .then(({ data }) => {
        console.log("qr data", data.data.wallet_address);
        setuserWalletAddress([
          {
            bsc: data.data.bsc_wallet_address,
            eth: data.data.eth_wallet_address,
          },
        ]);
        setQrCode(data.data.bsc_wallet_address);
        setIsLoader(false);
      })
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    QRCode.toDataURL(qrCode).then((data) => {
      setSrc(data);
      console.log("data", data);
    });
  }, [qrCode]);

  return (
    <>
      <Header activeTab="wallet" />

      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span
              className="back-btn"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <span className="info-h">
              <i
                data-tip
                data-for="registerTip"
                className="fas fa-question-circle"
              ></i>
              {/* <ReactTooltip id="registerTip" place="top" effect="solid">
                {staticData?.buy_page_conversion_tip &&
                  staticData.buy_page_conversion_tip}
              </ReactTooltip> */}
            </span>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={` ${
                  showMenu ? "menu-opened manu-overlay bg-overlay-anim" : ""
                }`}
                onClick={() => setShowMenu(!showMenu)}
              ></div>
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          //  console.log(
                          //     "chal rha hai",
                          //     localStorage.getItem("is_mobile_verified")
                          //   );
                          if (
                            localStorage.getItem("is_mobile_verified") ===
                            "true"
                          ) {
                            //  console.log("+++++++");
                            history.push("/wallet");
                          } else {
                            //  console.log("------");
                            // setshowPhoneVerifiedRequiredModal(true);
                          }
                        }}
                        className="active"
                      >
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup">SIGN UP</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <WarningModal
        show={iswarningModal}
        setShow={setIswarningModal}
        title={"BSC"}
        network={"BEP20"}
      />

      <WarningModal
        show={isETHwarningModal}
        setShow={setETHIswarningModal}
        title={"ETH"}
        network={"ERC20"}
      />

      <div class="container">
        <div class="setting-box ">
          <div class="setting-list deposite-card extra-margin">
            <div className="setting-head set_flexcenter">
              <Link
                className="history_icon btn btn-light border"
                to="/cowrie-deposit-history"
                style={{ textAlign: "right" }}
              >
                <i class="fa-solid fa-history" style={{ color: "#000000" }}></i>
              </Link>
              <h3>COWRIE DEPOSIT</h3>
              <span className="back-btn" onClick={history.goBack}>
                <i className="fas fa-chevron-left"></i>
              </span>
            </div>

            {/* <h3 className="send-Heading-Black text-center mb-5">
              COWRIE DEPOSIT
              <i class="fas fa-history" style="color: #000000;"></i>
            </h3> */}
            <div className=" mb-5">
              <div className="setting-list mt-2">
                <div className="wallet-row wallet_layer">
                  <div className="wallet-icon mt-1">
                    <img src="assets/images/s-logo.png" alt="" />
                  </div>

                  <div className="wallet-con">
                    <h3>
                      COWRIE{" "}
                      {parseFloat(
                        parseFloat(availableCowrie).toFixed(2)
                      ).toLocaleString()}
                      {console.log}
                    </h3>

                    <span style={{ fontWeight: "bold", paddingLeft: "31px" }}>
                      {" "}
                      &asymp; ${(availableCowrie * livePrice).toFixed(3)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <button
                  className={
                    walletButton === 1
                      ? "btn btn-sm btn-outline-warning active"
                      : "btn btn-sm btn-outline-warning "
                  }
                  onClick={() => {
                    setIswarningModal(true);
                    setwalletButton(1);
                    setQrCode(userWalletAddress[0].bsc);
                  }}
                >
                  BEP20 (BSC)
                </button>

                <button
                  className={
                    walletButton === 2
                      ? "btn btn-sm btn-outline-warning active ml-3"
                      : "btn btn-sm btn-outline-warning ml-3"
                  }
                  onClick={() => {
                    setETHIswarningModal(true);
                    setwalletButton(2);
                    setQrCode(userWalletAddress[0].eth);
                  }}
                >
                  ERC20 (ETH)
                </button>
              </div>

              {qrCode ? (
                <div className="text-center qr_sec">
                  <div className="code_gent_layer m-3">
                    <img src={src} alt="bar-code" />
                  </div>

                  <div className="">
                    <h4 className="withdLIneTwo">Deposit Fee : 0% </h4>
                  </div>

                  <div className="d-flex justify-content-center">
                    <div className="text-left mb-3 ">
                      <p className="m-0 text-secondary"> Wallet address</p>
                      <div className="">
                        <p className="m-0 copyOuter">{qrCode}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn bnt-outline-light"
                      onClick={() => {
                        navigator.clipboard.writeText(qrCode);
                        setbuttonCopied("Wallet address Copied");
                        setTimeout(() => {
                          setbuttonCopied("");
                        }, 1000);
                      }}
                    >
                      copy <i class="fa fa-copy p-1"></i>
                    </button>
                    <div style={{ minHeight: "50px" }}>
                      <p className="text-warning pt-1">{buttonCopied}</p>
                    </div>
                  </div>
                </div>
              ) : isLoader ? (
                ""
              ) : (
                <div className="text-center qr_sec">
                  <div className="code_gent_layer m-3 mt-5">
                    <h5 className="text-danger">
                      Due to Technical Issue your wallet Address is not
                      generated please contact support at support@mycowrie.org
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
}

function WarningModal({ show, setShow, title, network }) {
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal-header modal-headerBlock">
        <h5 className="modal-title modal-headerTitle" id="exampleModalLabel">
          <b>Warning</b>
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modalBody">
        <p>
          Deposit COWRIE only on{" "}
          <b>
            {" "}
            {title} Smart Chain ({network}){" "}
          </b>{" "}
          or you'll lose your funds.
        </p>
      </div>
      {/* <div className="modal-footer set_btnBottom text-center">
        <button type="button" className="custom-btn" data-dismiss="modal">Close</button>
      </div> */}

      {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>*/}
      <Modal.Footer class="modal-footer justify-content-start">
        <Button data-dismiss="modal" variant="success" onClick={handleClose}>
          I understand
        </Button>
        <Button variant="secondary" onClick={history.goBack}>
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
