import { makeLbankSpotApiRequest } from "./helpers.js";
import { subscribeOnStream, unsubscribeFromStream } from "./streaming.js";

const lastBarsCache = new Map();
const configurationData = {
  supported_resolutions: ["1D", "1m", "5m", "1W", "1M"],
  exchanges: [
    {
      value: "Bitfinex",
      name: "Bitfinex",
      desc: "Bitfinex",
    },
    {
      // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
      value: "Kraken",

      // filter name
      name: "Kraken",

      // full exchange name displayed in the filter popup
      desc: "Kraken bitcoin exchange",
    },
  ],
  symbols_types: [
    {
      name: "crypto",

      // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
      value: "crypto",
    },
    // ...
  ],
};
async function getAllSymbols() {
  const data = await makeLbankSpotApiRequest("?endpoint=/v2/currencyPairs.do");
  console.log("data", data);
  // const data = await makeApiRequest('data/v3/all/exchanges');
  let allSymbols = [];

  // for (const exchange of configurationData.exchanges) {
  //   const pairs = data.Data[exchange.value].pairs;
  //   description: "BFX/USD"
  //   exchange: "Bitfinex"
  //   full_name: "Bitfinex:BFX/USD"
  //   symbol: "BFX/USD"
  //   type: "crypto"
  //   for (const leftPairPart of Object.keys(pairs)) {
  //         const symbols = pairs[leftPairPart].map(rightPairPart => {
  //               const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
  //               return {
  //                     symbol: symbol.short,
  //                     full_name: symbol.full,
  //                     description: symbol.short,
  //                     exchange: exchange.value,
  //                     type: 'crypto',
  //                 };
  //             });
  //             allSymbols = [...allSymbols, ...symbols];
  //         }
  //       }

  const pairs = data.data.data;
  const symbols = pairs.map((a) => {
    return {
      symbol:
        a.split("_")[0].toUpperCase() + "/" + a.split("_")[1].toUpperCase(),
      full_name:
        "Lbank:" +
        a.split("_")[0].toUpperCase() +
        "/" +
        a.split("_")[1].toUpperCase(),
      description:
        a.split("_")[0].toUpperCase() + "/" + a.split("_")[1].toUpperCase(),
      exchange: "Lbank",
      type: "crypto",
    };
  });
  allSymbols = [...allSymbols, ...symbols];
//  console.log("allSymbols: ", allSymbols);
  return allSymbols;
}

const functions = {
  onReady: (callback) => {
  //  console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
  //  console.log("[searchSymbols]: Method call");
    //   const symbols = await getAllSymbols();
    // const newSymbols = symbols.filter(symbol => {
    //     const isExchangeValid = exchange === '' || symbol.exchange === exchange;
    //     const isFullSymbolContainsInput = symbol.full_name
    //         .toLowerCase()
    //         .indexOf(userInput.toLowerCase()) !== -1;
    //     return isExchangeValid && isFullSymbolContainsInput;
    // });
    // onResultReadyCallback(newSymbols);
  },
  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
  //  console.log("[resolveSymbol]: Method call", symbolName);
    const symbols = await getAllSymbols();
    const symbolItem = symbols.find(
      ({ full_name }) => full_name === symbolName
    );
    if (!symbolItem) {
    //  console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 100,
      has_intraday: false,
      has_no_volume: true,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: "streaming",
    };

  //  console.log("[resolveSymbol]: Symbol resolved", symbolName);
    onSymbolResolvedCallback(symbolInfo);
  },
  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { from, to, firstDataRequest } = periodParams;
    try {
      const urlParameters = {
        symbol:
          symbolInfo.name.split("/")[0].toLowerCase() +
          "_" +
          symbolInfo.name.split("/")[1].toLowerCase(), //"BTCUSD_210625",
        type: "day1",
        time: from,
        size: 1500,
      };

      const query = Object.keys(urlParameters)
        .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
        .join("&");
    //  console.log("query: ", query);

      const data = await makeLbankSpotApiRequest(
        `?endpoint=/v2/kline.do&${query}`
      );
      if (data?.data?.length === 0) {
        // "noData" should be set if there is no data in the requested period.
      //  console.log("idhar aa rha hai");
        onHistoryCallback([], { noData: true });
        return;
      }
      let bars = [];
      data.data.data.forEach((bar) => {
        if (bar[0] >= from && bar[0] < to) {
          bars = [
            ...bars,
            {
              time: bar[0] * 1000,
              open: bar[1],
              high: bar[2],
              low: bar[3],
              close: bar[4],
            },
          ];
        }
      });
    //  console.log("bars: ", bars);

      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }
      // debugger
    //  console.log(`[getBars]: returned ${bars.length} bar(s)`);
      onHistoryCallback(bars, { noData: false });
    } catch (error) {
    //  console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
  //  console.log(
  //     "[subscribeBars]: Method call with subscribeUID:",
  //     subscribeUID
  //   );
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name)
    );
  },
  unsubscribeBars: (subscriberUID) => {
  //  console.log(
  //     "[unsubscribeBars]: Method call with subscriberUID:",
  //     subscriberUID
  //   );
    unsubscribeFromStream(subscriberUID);
  },
};
export default functions;
