import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function DisclaimerModal({ show, setShow, title, nextScreen }) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal-header modal-headerBlock">
        <h5 className="modal-title modal-headerTitle" id="exampleModalLabel">
          {title}
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modalBody">
        <p>
          By purchasing Cowrie tokens through this platform, you acknowledge
          that you are buying outside of the ICO (Initial Coin Offering) and the
          OVEX IEO (Initial Exchange Offering) money back guarantees.
        </p>
        <p>
          Please note that all purchases made through this platform are
          considered normal market buys at market price and are outside the
          scope of any guarantees provided by the ICO or IEO.
        </p>
        <p>
          Therefore, we strongly recommend that you do your own research and
          understand the risks associated with investing in cryptocurrencies
          before making any purchase.
        </p>
        <p>
          By proceeding with your purchase, you agree that you have read and
          understood this disclaimer and that you assume all risks associated
          with your investment.
        </p>
      </div>
      {/* <div className="modal-footer set_btnBottom text-center">
        <button type="button" className="custom-btn" data-dismiss="modal">Close</button>
      </div> */}

      {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>*/}
      <Modal.Footer class="modal-footer justify-content-start">
        <Button data-dismiss="modal" variant="success" onClick={nextScreen}>
          Agree
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DisclaimerModal;
