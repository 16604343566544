let URL = "";
let HOME_PAGE_URL = "";
let RECAPTCHA_SITE_KEY = "";

// console.log("process.env.REACT_APP_ENV: ", process.env.REACT_APP_ENV);
if (process.env.REACT_APP_ENV === "production") {
  URL = "https://backend.mycowrie.org";
  // HOME_PAGE_URL = "https://community.mycowrie.org/";
  RECAPTCHA_SITE_KEY = "6LflNckjAAAAAMyzKfer5Q8czDsLTcznSkQr1aww";

  // URL = "http://41.76.209.116:8000";
  HOME_PAGE_URL = "https://community.mycowrie.org/";

  // HOME_PAGE_URL = "/wallet";
} else if (process.env.REACT_APP_ENV === "development") {
  URL = "https://staging-backend.mycowrie.org";
  HOME_PAGE_URL = "/home";
  RECAPTCHA_SITE_KEY = "6LfyXsYjAAAAANWls-9QZD1CEQCAV9Aa6oFWOk6j";
} else {
  URL = "http://localhost:8000";
  // URL = "http://192.168.1.63:8080";
  // URL = "http://192.168.2.21:8000";
  HOME_PAGE_URL = "/home";
  RECAPTCHA_SITE_KEY = "6Lci1MUjAAAAAKf11ZmM8x7VaqrioOLmelHXz_Kf";
}

const config = {
  URL,
  HOME_PAGE_URL,
  RECAPTCHA_SITE_KEY,
};

export default config;
