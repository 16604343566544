import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import useToken from "./useToken";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import handleError from "./handleError";

const CowrieDepositHistory = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/cowrie-deposit-payment-transaction/`, {
        headers: headers,
      })
      .then(({ data }) => {
        // console.log("data 456", data);
        setTransactions(data);
      })
      .catch(function (error) {
        handleError(error, setToken);
      });
  }, []);

  if (!token) {
    history.push("/login");
  }

  const [isLoading, setIsLoading] = useState(false);
  const first_name = localStorage.getItem("first_name");
  //  console.log("first_name: ", first_name);

  return (
    <div>
      <Header activeTab="wallet" isLoading={isLoading} />
      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span className="back-btn" onClick={history.goBack}>
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link to="/wallet" className="active">
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup">SIGN UP</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <div className="mov-h wallet-top">
        <div className="container">
          <div className="top-header wallet-header">
            <div className="wallet-head trans-head">
              <span className="back-btn" onClick={history.goBack}>
                <i className="fas fa-chevron-left"></i>
              </span>
              <h2>Cowrie Deposit History </h2>
              <p>Hello {first_name}</p>

              <div className="tabitems">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Deposit
                    </a>
                  </li>
                </ul>
                <div class="tab-content setoverflow" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <td align="center" valign="middle">
                            <strong>Txid</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Transaction Status</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Amount</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Created Date</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Confirmation</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Is Cowrie Transfered</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((data, index) => (
                          <tr key={index}>
                            <td align="center" valign="middle">
                              {data.txid}
                            </td>
                            <td align="center" valign="middle">
                              {data.transaction_status}
                            </td>
                            <td align="center" valign="middle">
                              {data.amount}
                            </td>
                            <td align="center" valign="middle">
                              {new Date(data.created_date).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                            <td align="center" valign="middle">
                              {data.confirmations}
                            </td>
                            <td align="center" valign="middle">
                              {data.is_cowrie_transfered ? (
                                <i className="fas fa-check"></i>
                              ) : (
                                <i className="fas fa-times"></i>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="sec wallet-sec">
        <div className="container">
          <div className="setting-box dest-head" style={{ maxWidth: "750px" }}>
            <div className="setting-list">
              <div className="setting-head ">
                <span className="back-btn" onClick={history.goBack}>
                  <i className="fas fa-chevron-left"></i>
                </span>
                <h2>Cowrie Deposit History </h2>
                <p>Hello {first_name} </p>

                <div className="tabitems">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="home-tab1"
                        data-toggle="tab"
                        href="#home1"
                        role="tab"
                        aria-controls="home1"
                        aria-selected="true"
                      >
                        Deposit
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content setoverflow" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home1"
                      role="tabpanel"
                      aria-labelledby="home-tab1"
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <td align="center" valign="middle">
                              <strong>Txid</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Transaction Status</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Amount</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Created Date</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Confirmation</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Network</strong>
                            </td>
                            <td align="center" valign="middle">
                              <strong>Is Cowrie Transfered</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions.map((data, index) => (
                            <tr key={index}>
                              <td align="center" valign="middle">
                                {data.txid}
                              </td>
                              <td align="center" valign="middle">
                                {data.transaction_status}
                              </td>
                              <td align="center" valign="middle">
                                {data.amount}
                              </td>
                              <td align="center" valign="middle">
                                {new Date(data.created_date).toLocaleDateString(
                                  "en-us",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                              <td align="center" valign="middle">
                                {data.confirmations}
                              </td>
                              <td align="center" valign="middle">
                                {data.network}
                              </td>
                              <td align="center" valign="middle">
                                {data.is_cowrie_transfered ? (
                                  <i className="fas fa-check"></i>
                                ) : (
                                  <i className="fas fa-times"></i>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CowrieDepositHistory;
