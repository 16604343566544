import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import config from "../config";
import useToken from "./useToken";
import QRCode from "qrcode";

export default function TwoFactorDisable() {
  const [isLoading, setIsLoading] = useState(false);
  const { token, setToken } = useToken();
  const [qrCode, setQrCode] = useState("");
  const history = useHistory();

  const [src, setSrc] = useState("");
  const [twoFactorOTP, setTwoFactorOTP] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/totp/create/`, { headers })
      .then(({ data }) => {
        console.log(data);
        setQrCode(data);
      })
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    QRCode.toDataURL(qrCode).then((data) => {
      setSrc(data);
      console.log("data", data);
    });
  }, [qrCode]);

  // totp/two-factor-verify/

  const test2FAotp = () => {
    axios
      .post(
        `${config.URL}/totp/two-factor-disabled/${twoFactorOTP}`,
        { password },
        {
          headers,
        }
      )
      .then(({ data }) => {
        console.log("after otp is verified", data);
        localStorage.setItem("is_two_factor_enabled", data);
        history.push("/myprofile");
      })
      .catch(function (error) {
        console.log("error", error.response.status);
        if (error.response.status === 400) {
          setErrorMessage(error.response.data.message);
        }
      });
    // .then(() => setIsLoading(false));
  };

  return (
    <>
      <Header activeTab="myprofile" />
      <div className="setBody">
        <main className="cowrie_code" id="cowrie_codeId">
          <section className="sec wallet-sec buy-sec cowrie_codeSection">
            <div className="container cowrie_codeInner_container">
              <div className="cowrie_codeInner">
                <div className="cowrie_codeInner_sets">
                  <div className="cowrie_codeHeader">
                    <h3 className="wsuccess">
                      Two Factor Authentication Disable
                    </h3>
                  </div>
                  <div className="cowrie_codeHeader">
                    <p>
                      {" "}
                      Enter Your Password and Two Factor Authentication code
                      from the Google Authenticator app on your phone.{" "}
                    </p>
                  </div>
                  {/* <div className="code_gent_layer">
                    <img src={src} alt="bar-code" />
                  </div>
                  <div className="code_lines">
                    <p className="withdLIne ">
                      In order to use 2FA, scan the QR code to the right into
                      the Google Authenticator app on your phone.
                    </p>
                  </div> */}

                  <div className="code_lines my-1">
                    <input
                      type="password"
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  <div className="code_lines my-1">
                    <input
                      type="text"
                      placeholder="Code"
                      maxLength={6}
                      onChange={(e) => {
                        setTwoFactorOTP(e.target.value);
                      }}
                    />
                  </div>
                  <div className="m-1 text-center">
                    <p className="mx-auto text-warning">{errorMessage}</p>
                  </div>
                  <div className="cowrie_codeInner_sets mt-3">
                    {twoFactorOTP.length === 6 ? (
                      <div className="cowrie_codeInner_sets__button">
                        <button
                          className="custom-btn button_custom"
                          onClick={() => {
                            test2FAotp();
                          }}
                        >
                          Disable 2FA
                        </button>
                      </div>
                    ) : (
                      <div className="cowrie_codeInner_sets__button">
                        <button className="custom-btn button_custom bg-secondary">
                          Disable 2FA
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
}
