import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import config from "../config";
import useToken from "./useToken";
import axios from "axios";
import InfoModal from "./InfoModal";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

const ForgotPassword = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);

  const next = () => setActiveStep((active) => active + 1);
  const prev = () => setActiveStep((active) => active - 1);

  if (token) {
    history.push("/");
  }

  const [phone, setPhone] = useState("");
  const [dialCode, setDialCode] = useState("224");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPasword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [staticData, setStaticData] = useState(null);
  const [countryInfo, setCountryInfo] = useState([]);
  const [otpSentUsing, setOtpSentUsing] = useState("");
  let selectOptions = [];
  const [userCountryCode, setUserCountryCode] = useState("224");

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/setting/`)
      .then(({ data }) => setStaticData(data))
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    console.log("running");
    axios
      .get(`${config.URL}/fetch-country-dail-code/`)
      .then(({ data }) => {
        console.log("data.data", data.data);
        setCountryInfo(data.data);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  selectOptions = countryInfo.map((data, index) => {
    return { label: data.phone, value: data.id };
  });

  useEffect(() => {
    selectOptions = countryInfo.map((data, index) => {
      return { label: data.phone, value: data.id };
    });
  }, [countryInfo]);

  useEffect(() => {
    setTimeout(() => setErrorMessage(""), 5000);
  }, [errorMessage]);

  const forgotSubmit = (e) => {
    let request_payload = {};
    console.log("reset submit", email, phone, dialCode);
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    if (email.length > 0 && phone.length > 0) {
      console.log("skdjlflajsldkfj");
      setErrorMessage("Please Either use Mail or Mobile number");
      setIsLoading(false);
      console.log("email part");
      return;
    } else if (email.includes("@") || email.length > 0) {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!email || regex.test(email) === false) {
        setErrorMessage("Please enter a valid email");
        setIsLoading(false);
        console.log("email part");
        return;
      } else {
        // api for email
        request_payload = { email: email };
        setOtpSentUsing("email");
        console.log("req pay ", request_payload);
      }
    } else {
      if (dialCode === "") {
        console.log("else - if dialcode part");
        setErrorMessage("Please Select Country code");
        setIsLoading(false);
        return;
      } else if (phone.length < 5) {
        console.log("else - if phone part");
        setErrorMessage("Please enter a valid mobile number");
        setIsLoading(false);
        return;
      } else if (!/^-?\d+$/.test(phone)) {
        setErrorMessage("Please enter a valid mobile number");
        setIsLoading(false);
        return;
      } else {
        // api for phone
        request_payload = { phone: phone, dial_code: dialCode };
        setOtpSentUsing("phone");
        console.log("req pay ", request_payload);
      }
    }

    const headers = {
      "Content-Type": "application/json",
    };
    //  console.log("request_payload: ", request_payload);
    //  console.log("headers: ", headers);

    axios
      .post(`${config.URL}/forgot-password/`, request_payload, {
        headers: headers,
      })
      .then((response) => {
        //  console.log("response: ", response);
        //  console.log("data: ", response.data);
        next();
      })
      .catch(function (error) {
        //  console.log("error response: ", error.response);
        setErrorMessage(error.response.data.message);
      })
      .then(() => setIsLoading(false));
  };

  const resetPasswordSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);

    if (!otp || otp.length < 6) {
      setErrorMessage("Please valid OTP.");
      setIsLoading(false);
      return;
    } else if (!newPassword) {
      setErrorMessage("Please enter valid new password.");
      setIsLoading(false);
      return;
    } else if (confirmPassword != newPassword) {
      setErrorMessage("New password & confirm password does not match.");
      setIsLoading(false);
      return;
    }

    let request_payload = {};
    if (otpSentUsing === "email") {
      request_payload = {
        email: email,
        otp: otp,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      };
    } else if (otpSentUsing === "phone") {
      request_payload = {
        phone: phone,
        dial_code: dialCode,
        otp: otp,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      };
    }

    const headers = {
      "Content-Type": "application/json",
    };
    //  console.log("request_payload: ", request_payload);
    //  console.log("headers: ", headers);
    console.log("request_payload", request_payload);
    axios
      .post(`${config.URL}/reset-password/`, request_payload, {
        headers: headers,
      })
      .then((response) => {
        //  console.log("response: ", response);
        //  console.log("data: ", response.data);
        prev();
        setShowResetSuccess(true);
      })
      .catch(function (error) {
        console.log("error response: ", error.response);
        setErrorMessage(error.response.data.message);
      })
      .then(() => setIsLoading(false));
  };

  const filterOption = (option, inputValue) =>
    (option.label.toString().match(inputValue) || []).length > 0;

  return (
    <>
      <InfoModal
        show={showResetSuccess}
        setShow={setShowResetSuccess}
        title="Reset successfully"
        body='Please <a href="/login">Login</a> with new password.'
      />
      <Header activeTab="login" isLoading={isLoading} />
      <section className="sec buy-sec login-sec">
        <div className="container">
          <div className="setting-box">
            <div className="top-img desk-o">
              <img src="assets/images/icon-forgot-pw.svg" alt="" />
            </div>
            <div className="setting-list">
              <div className="setting-head">
                {/* <span className="back-btn">
                  <i className="fas fa-chevron-left"></i>
                </span> */}
                <h3>Forgot Password</h3>
                <h6 className="mt-2">
                  {" "}
                  Please use one option either Email or Mobile Number{" "}
                </h6>
                {activeStep === 1 && (
                  <span>
                    We’ve sent an OTP to your mobile number
                    <br />
                    please enter it below to continue.
                  </span>
                )}
                <Link
                  to="#"
                  className="info-h desk-d"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Tooltip on top"
                >
                  <i
                    data-tip
                    data-for="forgot-tip"
                    className="fas fa-question-circle"
                  ></i>
                  <ReactTooltip id="forgot-tip" place="bottom" effect="solid">
                    {staticData?.forgot_page_tip && staticData.forgot_page_tip}
                  </ReactTooltip>
                </Link>
              </div>

              <div className="top-img mob-o">
                <img src="assets/images/icon-forgot-pw.svg" alt="" />
              </div>

              {activeStep === 0 && (
                <InitialForm
                  phone={phone}
                  setPhone={setPhone}
                  countryInfo={countryInfo}
                  errorMessage={errorMessage}
                  forgotSubmit={forgotSubmit}
                  setEmail={setEmail}
                  email={email}
                  setDialCode={setDialCode}
                  dialCode={dialCode}
                  setUserCountryCode={setUserCountryCode}
                  selectOptions={selectOptions}
                  filterOption={filterOption}
                />
              )}
              {activeStep === 1 && (
                <ResetPasswordForm
                  selectOptions={selectOptions}
                  phone={phone}
                  otp={otp}
                  setOtp={setOtp}
                  newPassword={newPassword}
                  setNewPassword={setNewPassword}
                  confirmPassword={confirmPassword}
                  setConfirmPassword={setConfirmPassword}
                  errorMessage={errorMessage}
                  resetPasswordSubmit={resetPasswordSubmit}
                  showPassword={showPassword}
                  setShowPasword={setShowPasword}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

function InitialForm(props) {
  const selectOptions = props.countryInfo.map((data, index) => {
    return { label: data.phone, value: data.id };
  });

  return (
    <form
      name="forgotform"
      id="forgotform"
      method="POST"
      onSubmit={props.forgotSubmit}
    >
      <div className="buy-col">
        <div className="buy-con flex-wrap">
          <div className="buy-user">
            <label>Email</label>
            <span className="login-icon-mail">
              <img src="assets/images/mail-icon.png" alt="" />
            </span>
            <input
              type="text"
              placeholder="xxxx@xxx"
              value={props.email}
              onChange={(e) => props.setEmail(e.target.value)}
              // maxLength="10"
            />
          </div>
        </div>
        <div className="">
          <h5 className="center_or">OR</h5>
        </div>

        <div className="buy-con flex-wrap buy__col">
          <div className="buy-user">
            <div className="dropdown_layer">
              {/* <div className="dropdown_values set__hsa">
                <label className="topItemvalue login_count">COUNTRY CODE</label>
                <select
                  name="datans"
                  id="dataid"
                  onChange={(e) => {
                    props.setDialCode(e.target.value);
                  }}
                >
                  <option value="">select</option>
                  {props.countryInfo.map((data, index) => {
                    return (
                      //<option key={index} value={[data.phone, data.name]}
                      <option key={index} value={data.id}>
                        + {data.phone}
                      </option>
                    );
                  })}
                </select>
              </div> */}
              <div className="dropdown_values set__hsa">
                <label className="topItemvalue login_count">COUNTRY CODE</label>
                {/* {console.log(selectOptions)} */}
                <div className="count__itemOp">
                  <Select
                    className="count__item"
                    options={props.selectOptions}
                    placeholder="Select"
                    onChange={(opt) => {
                      console.log("value", opt.value);
                      props.setDialCode(opt.value);
                    }}
                    defaultValue={
                      {
                        label: "27",
                        value: "224",
                      } || "Select"
                    }
                    filterOption={props.filterOption}
                  />
                </div>

                {/* <select
                            name="datans"
                            id="dataid"
                            onChange={(e) => {
                              setUserCountryCode(e.target.value);
                            }}
                          >
                            <option value="">select</option>
                            {countryInfo.map((data, index) => {
                              return (
                                //<option key={index} value={[data.phone, data.name]}
                                <option key={index} value={data.id}>
                                  + {data.phone}
                                </option>
                              );
                            })}
                          </select> */}
              </div>
              <div className="login__outer">
                <label className="mob_numb">Mobile Number</label>
                <span className="login-icon-l">
                  <img src="assets/images/mob-icon.png" alt="" />
                </span>
                <input
                  type="text"
                  placeholder="081 123 4567"
                  value={props.phone}
                  onChange={(e) => props.setPhone(e.target.value)}
                  maxLength="10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-col">
        {/* <p className="log-for">
            <Link to="/forgot-password">FORGOT PASSWORD</Link>
          </p> */}
        <p className="log-error">{props.errorMessage}</p>

        <div className="buy-btn">
          {/* <Link
            to="#"
            className="custom-btn btn-full"
            onClick={props.forgotSubmit}
          >
            Next
          </Link> */}
          <button type="submit" className="custom-btn btn-full">
            Next
          </button>
        </div>
        {/* <p className="log-text">
            Not a member yet? <Link to="/signup">Sign Up</Link>
          </p> */}
      </div>
    </form>
  );
}

function ResetPasswordForm(props) {
  return (
    <form
      name="resetpasswordform"
      id="resetpasswordform"
      method="POST"
      onSubmit={props.resetPasswordSubmit}
    >
      <div className="buy-col">
        <div className="buy-con flex-wrap">
          <div className="buy-user">
            <label>OTP</label>
            <span className="login-icon-l">
              <img src="assets/images/mob-icon.png" alt="" />
            </span>
            <input
              type="text"
              placeholder="000000"
              value={props.otp}
              onChange={(e) => props.setOtp(e.target.value)}
              maxLength="8"
            />
          </div>

          <div className="buy-pass">
            <label>New Password</label>
            <span className="login-icon-l">
              <img src="assets/images/lock-icon.png" alt="" />
            </span>
            {/* <input
                type="password"
                placeholder="081 123 4567"
                value={props.newPassowrd}
                onChange={(e) => props.setNewPassword(e.target.value)}
                maxLength="10"
              /> */}
            <input
              type={props.showPassword ? "text" : "password"}
              placeholder="Password"
              value={props.newPassowrd}
              onChange={(e) => props.setNewPassword(e.target.value)}
            />
            <span
              className="login-icon-r"
              onClick={() => {
                props.setShowPasword(!props.showPassword);
              }}
            >
              <img src="assets/images/eye-icon.png" alt="" />
            </span>
          </div>

          <div className="buy-pass">
            <label>Confirm Password</label>
            <span className="login-icon-l">
              <img src="assets/images/lock-icon.png" alt="" />
            </span>
            <input
              type="password"
              placeholder="Confirm Password"
              value={props.confirmPassword}
              onChange={(e) => props.setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="login-col">
        {/* <p className="log-for">
            <Link to="/forgot-password">FORGOT PASSWORD</Link>
          </p> */}
        <p className="log-error">{props.errorMessage}</p>

        <div className="buy-btn">
          {/* <Link
            to="#"
            className="custom-btn btn-full"
            onClick={props.resetPasswordSubmit}
          >
            Next
          </Link> */}
          <button type="submit" className="custom-btn btn-full">
            Next
          </button>
        </div>
        {/* <p className="log-text">
            Not a member yet? <Link to="/signup">Sign Up</Link>
          </p> */}
      </div>
    </form>
  );
}

export default ForgotPassword;
