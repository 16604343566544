import { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import useToken from "./useToken";
import axios from "axios";
import config from "../config";

const Profile = ({}) => {
  const { token, setToken } = useToken();
  const history = useHistory();
  if (!token) {
    history.push("/login");
  }

  const [firstName, setFirstName] = useState(
    localStorage.getItem("first_name")
  );
  const [lastName, setLastName] = useState(localStorage.getItem("last_name"));
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [userId, setUserId] = useState(localStorage.getItem("user_id"));
  const [kingdom, setKingdom] = useState(localStorage.getItem("kingdom"));
  const [country, setCountry] = useState(localStorage.getItem("country"));
  const [phone, setPhone] = useState(localStorage.getItem("phone"));
  const [isMobileVerified, setIsMobileVerified] = useState(
    localStorage.getItem("is_mobile_verified")
  );
  const [isEmailVerified, setIsEmailVerified] = useState(
    localStorage.getItem("is_email_verified")
  );
  const [referCode, setReferCode] = useState(
    localStorage.getItem("refer_code")
  );
  const [reffrealCount, setReffrealCount] = useState(
    localStorage.getItem("reffreal_count")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [twoFactorAuth, setTwoFactor] = useState(
    localStorage.getItem("is_two_factor_enabled")
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleVerify = (carrier) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/get-${carrier}-otp/`, { headers })
      .then(({ data }) => {
        setIsLoading(false);
        history.push(`/verify-otp/${carrier}`);
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Header activeTab="myprofile" isLoading={isLoading} />

      <section className="sec setting-sec">
        <div className="container">
          <div className="setting-box">
            <div className="top-img">
              <img src="assets/images/icon-myprofile-settings.svg" alt="" />
            </div>
            <div className="setting-list">
              <div className="setting-head">
                <span className="back-btn" onClick={history.goBack}>
                  <i className="fas fa-chevron-left"></i>
                </span>
                <h3>My Profile</h3>
              </div>
              <div className="setting-col">
                <div className="col-icon">
                  <img src="assets/images/icon-settings1.svg" alt="" />
                </div>
                <div className="col-con">
                  <h4>Profile ID</h4>
                  <span>{userId}</span>
                </div>
              </div>

              {country === "South Africa" ? (
                <div className="setting-col">
                  <div className="col-icon">
                    <img src="assets/images/icon-settings2.svg" alt="" />
                  </div>
                  <div className="col-con">
                    <h4>Nominated Community</h4>
                    <span>{kingdom}</span>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="setting-col">
                <div className="col-icon">
                  <img src="assets/images/icon-settings3.svg" alt="" />
                </div>
                <div className="col-con">
                  <h4>Name</h4>
                  <span>
                    {firstName} {lastName}
                  </span>
                </div>
              </div>

              <div className="setting-col">
                <div className="col-icon">
                  <img src="assets/images/icon-settings4.svg" alt="" />
                </div>
                <div className="col-con">
                  <h4>Email</h4>
                  <span>{email}</span>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      right: "0",
                      top: "15px",
                    }}
                  >
                    {email !== "null" && (
                      <>
                        {isEmailVerified === "true" ? (
                          <Link
                            className="verify"
                            style={{ position: "initial" }}
                          >
                            VERIFIED
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            onClick={() => handleVerify("email")}
                            className="verify"
                            style={{ position: "initial" }}
                          >
                            VERIFY
                          </Link>
                        )}
                      </>
                    )}
                    <Link
                      to="/update-email"
                      className="verify"
                      style={{ position: "initial", marginLeft: "5px" }}
                    >
                      EDIT
                    </Link>
                  </div>
                </div>
              </div>

              <div className="setting-col">
                <div className="col-icon">
                  <img src="assets/images/icon-settings-phone.svg" alt="" />
                </div>
                <div className="col-con">
                  <h4>Mobile Number</h4>
                  <span>{phone}</span>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      right: "0",
                      top: "15px",
                    }}
                  >
                    {isMobileVerified === "true" ? (
                      <Link
                        className="verify"
                        to="#"
                        style={{ position: "initial" }}
                      >
                        VERIFIED
                      </Link>
                    ) : (
                      <Link
                        to="#"
                        onClick={() => handleVerify("mobile")}
                        className="verify"
                        style={{ position: "initial" }}
                      >
                        VERIFY
                      </Link>
                    )}
                    <Link
                      to="/update-info"
                      className="verify"
                      style={{ position: "initial", marginLeft: "5px" }}
                    >
                      EDIT
                    </Link>
                  </div>
                </div>
              </div>

              <div className="setting-col">
                <div className="col-icon">
                  <img src="assets/images/icon-settings5.svg" alt="" />
                </div>
                <div className="col-con">
                  <h4>Country</h4>
                  <span>{country}</span>
                </div>
              </div>

              <div className="setting-col">
                <div className="col-icon">
                  <img src="assets/images/icon-settings6.svg" alt="" />
                </div>
                <div className="col-con">
                  <h4>Verification Level</h4>
                  <span>Level 1</span>
                  {/* <Link to="/full-verification" className="verify">VERIFY</Link> */}
                  <Link to="#" className="verify">
                    VERIFY
                  </Link>
                </div>
              </div>

              <div className="setting-col">
                <div className="col-icon">
                  <img src="assets/images/icon-settings-phone.svg" alt="" />
                </div>
                <div className="col-con">
                  <h4>Two Factor Authentication</h4>
                  <span>Google Authenticator App</span>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      right: "0",
                      top: "15px",
                    }}
                  >
                    {/* {isMobileVerified === "true" ? (
                      <Link
                        className="verify"
                        to="#"
                        style={{ position: "initial" }}
                      >
                        VERIFIED
                      </Link>
                    ) : (
                      <Link
                        to="#"
                        onClick={() => handleVerify("mobile")}
                        className="verify"
                        style={{ position: "initial" }}
                      >
                        VERIFY
                      </Link>
                    )} */}
                    {twoFactorAuth === "true" ? (
                      <Link
                        to="/two-fa-disable"
                        className="verify"
                        style={{ position: "initial", marginLeft: "5px" }}
                      >
                        DISABLE
                      </Link>
                    ) : (
                      <Link
                        to="/two-fa"
                        className="verify"
                        style={{ position: "initial", marginLeft: "5px" }}
                      >
                        Enable
                      </Link>
                    )}
                  </div>
                </div>
              </div>

              <div className="setting-col">
                <div className="col-icon">
                  <img src="assets/images/icon-settings7.svg" alt="" />
                </div>
                <div className="col-con">
                  <h4>My Referral Link</h4>
                  <span>{referCode} - Earn referral rewards by sharing</span>
                  <Link to="/share-referral" className="verify">
                    VIEW
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Profile;
