import { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import config from "../config";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import useToken from "./useToken";
import handleError from "./handleError";
const first_name = localStorage.getItem("first_name");

const Staking = ({}) => {
  const { token, setToken } = useToken();
  const history = useHistory();

  if (!token) {
    history.push("/login");
  }
  // const [state, setState] = useState({
  //   isOpen: false
  // });

  const [state, setState] = useState(0);
  const [poolData, setPoolData] = useState([]);
  const [minStake, setMinStake] = useState([]);
  const [maxStake, setMaxStake] = useState([]);
  const [select, setSelect] = useState(null);
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [rate, setRate] = useState(0);
  const [days, setDays] = useState(0);
  const [stakingFee, setStakingFee] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [availableCowrie, setAvailableCowrie] = useState(0);
  const [error, setError] = useState("");
  const [errorAPI, setErrorAPI] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [showMenu, setShowMenu] = useState(false);

  console.log("amount", amount);
  // const openModal = () => this.setState({ isOpen: true });
  // const closeModal = () => this.setState({ isOpen: false });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${config.URL}/trade/staking/`, {
        headers: headers,
      })
      .then(({ data }) => {
        setPoolData(data.data);
        setMinStake(data.minStake);
        setMaxStake(data.maxStake);
        setStakingFee(data.fee);
        console.log("data: ", data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/fetch-my-cowrie-token/`, {
        headers: headers,
      })
      .then(({ data }) => {
        // console.log(data)
        setAvailableCowrie(parseFloat(data.data.available_cowrie));
      })
      .catch(function (error) {
        handleError(error, setToken);
      });
    // .then(() => setIsLoading(false));
  }, []);

  const poolApiPost = () => {
    setIsLoading(true);
    axios
      .post(
        `${config.URL}/trade/staking/`,
        {
          amount: amount - stakingFee,
          pool_index: select,
          earned_cowrie: total,
          rate: rate,
          days: days,
          fee: stakingFee,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setState(1);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          setErrorAPI(err.response.data.message);
          setIsLoading(false);
        }
      });
  };

  function convertSecToDays(Days) {
    return Math.floor(Days / 86400);
  }

  useEffect(() => {
    // console.log("amount - stakingFee", amount - stakingFee);
    if (amount > availableCowrie) {
      setError("Your Staked Amount Is More Than Available COWRIE.");
      setButtonDisable(true);
    }
    // if (amount < minStake) {
    //   setError("Please Enter Amount must be more than Minimum Staked Amount.");
    //   setButtonDisable(true);
    // }
    if (amount > maxStake) {
      setError("Please Enter Amount must be less than Maximum Staked Amount.");
      setButtonDisable(true);
    }
    if (amount < minStake + stakingFee) {
      setError(
        "Please Enter Amount greater than Minimum staking cowrie and fees."
      );
      setButtonDisable(true);
    }
    if (
      amount <= availableCowrie &&
      amount > 0 &&
      amount >= minStake &&
      amount <= maxStake &&
      amount > minStake + stakingFee
    ) {
      setError("");
      if (select != null) {
        setButtonDisable(false);
      }
    }
  }, [amount, select]);

  useEffect(() => {
    if (select === 0) {
      setTotal(
        (((amount - stakingFee) * poolData?.pool1?.monthly_rate) / 10 ** 18) *
          poolData?.pool1?.month
      );
    }
    if (select === 1) {
      setTotal(
        (((amount - stakingFee) * poolData?.pool2?.monthly_rate) / 10 ** 18) *
          poolData?.pool2?.month
      );
    }

    if (select === 2) {
      setTotal(
        (((amount - stakingFee) * poolData?.pool3?.monthly_rate) / 10 ** 18) *
          poolData?.pool3?.month
      );
    }
  }, [amount, select]);

  return (
    <>
      <Header isLoading={isLoading} activeTab="wallet" />

      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span
              className="back-btn"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <span className="info-h">
              <i
                data-tip
                data-for="registerTip"
                className="fas fa-question-circle"
              ></i>
              {/* <ReactTooltip id="registerTip" place="top" effect="solid">
                {staticData?.buy_page_conversion_tip &&
                  staticData.buy_page_conversion_tip}
              </ReactTooltip> */}
            </span>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={` ${
                  showMenu ? "menu-opened manu-overlay bg-overlay-anim" : ""
                }`}
                onClick={() => setShowMenu(!showMenu)}
              ></div>
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          //  console.log(
                          //     "chal rha hai",
                          //     localStorage.getItem("is_mobile_verified")
                          //   );
                          if (
                            localStorage.getItem("is_mobile_verified") ===
                            "true"
                          ) {
                            //  console.log("+++++++");
                            history.push("/wallet");
                          } else {
                            //  console.log("------");
                            // setshowPhoneVerifiedRequiredModal(true);
                          }
                        }}
                        className="active"
                      >
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup">SIGN UP</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      {state === 0 ? (
        <div className="setting-box mt-5">
          {/* <div className="top-img">
        <img src="assets/images/icon-buy-1.svg" alt="" />
      </div> */}
          <div className="setting-list">
            <div className="setting-head">
              <span className="back-btn" onClick={history.goBack}>
                <i className="fas fa-chevron-left"></i>
              </span>
              <h3>Staking COWRIE</h3>
              <div className="setting-head set_flexcenter hbtns">
                <Link
                  className="history_icon btn btn-light border"
                  to="/staked-tokens"
                  style={{ textAlign: "right" }}
                >
                  <i
                    class="fa-solid fa-history"
                    style={{ color: "#000000" }}
                  ></i>
                </Link>
              </div>
              <a href="#" className="info-h desk-d">
                <i
                  data-tip
                  data-for="conversion-tip"
                  className="fas fa-question-circle"
                ></i>
                <ReactTooltip id="conversion-tip" place="bottom" effect="solid">
                  {/* {props.staticData?.buy_page_conversion_tip} */}
                </ReactTooltip>
              </a>
              <div className="buy-price">
                <p className="buy-min-max">
                  MINIMUM STAKING COWRIE {minStake} | MAXIMUM STAKING COWRIE{" "}
                  {maxStake}
                </p>
                <p className="f-error">{}</p>
              </div>
            </div>
            <div className="buy-col">
              <p className="buy-min-max mr-4 text-warning">
                {" "}
                Available COWRIE {availableCowrie}{" "}
              </p>
              <div className="buy-con">
                <div className="buy-r count__itemOp staking_dark">
                  <label>AMOUNT</label>
                  <input
                    type="text"
                    placeholder="Enter staking amount in cowrie"
                    value={amount}
                    onChange={(e) => {
                      e.target.value
                        ? setAmount(
                            parseFloat(parseFloat(e.target.value).toFixed(2))
                          )
                        : setAmount(0);
                    }}
                  />
                  <button
                    className="custom-btn-sm btnCenterbtn"
                    onClick={() => {
                      setAmount(availableCowrie);
                    }}
                  >
                    MAX
                  </button>
                </div>
              </div>
              <p className="buy-min-max mr-4 text-success">
                {" "}
                Fee {stakingFee} COWRIE Approx
              </p>
              <p className="error-text text-danger text-center">
                {error.length > 0 && error}
              </p>
            </div>

            <p className="ml-4 font-weight-bold">Choose Plan</p>
            <div class="container pool_container">
              <div class="row">
                <div
                  class="col-sm"
                  onClick={() => {
                    console.log("click");
                    setSelect(0);
                    // stakingFee;
                    setErrorAPI("");
                    // setTotal(
                    //   ((amount - stakingFee) * poolData?.pool1?.monthly_rate) / 10 ** 18
                    // );
                    // setTotal((amount * poolData?.pool1?.monthly_rate) / 10 ** 18);
                    setRate(poolData?.pool1?.monthly_rate / 10 ** 16);
                    setDays(convertSecToDays(poolData?.pool1?.duration));
                    //
                  }}
                >
                  <div
                    class={
                      select === 0
                        ? "card my-3 p-0 border border-warning bg-warning poolshadow"
                        : "card my-3 p-0 poolshadow"
                    }
                  >
                    <div class="card-body p-1">
                      <h5 class="card-title mr-2" style={{ display: "inline" }}>
                        Days
                      </h5>
                      <span
                        class="card-subtitle mb-2 text-success"
                        style={{ fontSize: "18px" }}
                      >
                        {convertSecToDays(poolData?.pool1?.duration)}
                      </span>
                      <br />
                      <h5 class="card-title mr-2" style={{ display: "inline" }}>
                        Rate
                      </h5>
                      <span
                        class="card-text text-success"
                        style={{ fontSize: "18px" }}
                      >
                        {poolData?.pool1?.monthly_rate / 10 ** 16} %
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-sm"
                  onClick={() => {
                    console.log("click");
                    setSelect(1);
                    setErrorAPI("");
                    // setTotal(
                    //   ((amount - stakingFee) * poolData?.pool2?.monthly_rate) / 10 ** 18
                    // );
                    // setTotal(amount * (poolData?.pool2?.monthly_rate / 10 ** 16));
                    setRate(poolData?.pool2?.monthly_rate / 10 ** 16);
                    setDays(convertSecToDays(poolData?.pool2?.duration));
                  }}
                >
                  <div
                    class={
                      select === 1
                        ? "card my-3 p-0 border border-warning bg-warning poolshadow"
                        : "card my-3 p-0 poolshadow"
                    }
                  >
                    <div class="card-body p-1">
                      <h5 class="card-title mr-2" style={{ display: "inline" }}>
                        Days
                      </h5>
                      <span
                        class="card-subtitle mb-2 text-success"
                        style={{ fontSize: "18px" }}
                      >
                        {convertSecToDays(poolData?.pool2?.duration)}
                      </span>
                      <br />
                      <h5 class="card-title mr-2" style={{ display: "inline" }}>
                        Rate
                      </h5>
                      <span
                        class="card-text text-success"
                        style={{ fontSize: "18px" }}
                      >
                        {poolData?.pool2?.monthly_rate / 10 ** 16} %
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="col-sm"
                  onClick={() => {
                    console.log("click");
                    setSelect(2);
                    setErrorAPI("");
                    // setTotal(
                    //   ((amount - stakingFee) * poolData?.pool3?.monthly_rate) / 10 ** 18
                    // );
                    // setTotal(amount * (poolData?.pool3?.monthly_rate / 10 ** 16));
                    setRate(poolData?.pool3?.monthly_rate / 10 ** 16);
                    setDays(convertSecToDays(poolData?.pool3?.duration));
                  }}
                >
                  <div
                    class={
                      select === 2
                        ? "card my-3 p-0 border border-warning bg-warning poolshadow"
                        : "card my-3 p-0 poolshadow"
                    }
                  >
                    <div class="card-body p-1">
                      <h5 class="card-title mr-2" style={{ display: "inline" }}>
                        Days
                      </h5>
                      <span
                        class="card-subtitle mb-2 text-success"
                        style={{ fontSize: "18px" }}
                      >
                        {convertSecToDays(poolData?.pool3?.duration)}
                      </span>
                      <br />

                      <h5 class="card-title mr-2" style={{ display: "inline" }}>
                        Rate
                      </h5>
                      <span
                        class="card-text text-success"
                        style={{ fontSize: "18px" }}
                      >
                        {poolData?.pool3?.monthly_rate / 10 ** 16} %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <p className="error-text text-danger text-center">
                {errorAPI.length > 0 && errorAPI}
              </p>
            </div>

            <div className=" my-5 mx-4">
              <div className="setting-list mt-2">
                <div className="wallet-row wallet_layer">
                  <div className="wallet-con">
                    {/* <span style={{ fontWeight: "bold", paddingLeft: "31px" }}>

                </span> */}

                    <h3>
                      You Will Earn{" "}
                      <span className="earn_elements">
                        {" "}
                        COWRIE {total <= 0 ? "0" : parseFloat(total).toFixed(3)}
                      </span>
                    </h3>
                  </div>
                  <div className="wallet-icon mt-1">
                    <img src="assets/images/s-logo.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            {buttonDisable == true ? (
              <div className="buy-btn">
                <button className="custom-btn btn-full">Next</button>
              </div>
            ) : (
              <div className="buy-btn">
                <button
                  className="custom-btn btn-full"
                  onClick={(e) => {
                    poolApiPost();
                  }}
                >
                  Next
                </button>
              </div>
            )}
            <p className="tc">
              By staking COWRIE I accept all terms and conditions of staking.
            </p>
            <p className="buy-min-max ml-4 mt-4">Not Enough COWRIE</p>
            <div className="buy-btn">
              <Link
                to="/cowrie-deposite"
                className="custom-btn-buy-more text-white btn_darkStake"
              >
                Deposit
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <SendComplete
          amount={amount - stakingFee}
          stakingFee={stakingFee}
          first_name={first_name}
        />
      )}
      <Footer />
    </>
  );
};

export default Staking;

function SendComplete({ amount, stakingFee, first_name }) {
  return (
    <>
      <section className="sec buy-sec set-complete-sec">
        <div className="container">
          <div className="setting-box">
            <div className="setting-list">
              <div className="sent-c-col">
                <div className="send-complete-img">
                  <img src="assets/images/send-complete.png" alt="" />
                </div>
                <div className="buy-col text-center">
                  <h3>Staking InProgress</h3>

                  {/* {reciverName === "" ? (
                    <div>
                      <p>
                        You have successfully sent
                        {totalCowrieAmount}
                        approx COWRIE to Staking.
                      </p>
                      <p>
                        Please allow up to 20 minutes for the transaction to
                        reflect in {username}’s wallet.
                      </p>
                    </div>
                  ) : ( */}
                  <div>
                    <p>
                      You have successfully sent {amount} approx COWRIE to
                      Staking and fees charge is {stakingFee}.
                    </p>
                    <p>
                      Please allow up to 20 minutes for the transaction to
                      reflect in wallet.
                    </p>
                  </div>
                  {/* )} */}
                </div>
                <div className="login-col">
                  <div className="btn-row">
                    <div className="buy-btn">
                      <Link to="/">
                        <span className="custom-btn btn-full">HOME</span>
                      </Link>
                    </div>
                    <div className="buy-btn">
                      <Link to="/staked-tokens">
                        <span className="custom-btn btn-full">
                          My Staked Tokens
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
