import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import config from "../config";
import useToken from "./useToken";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import InfoModal from "./InfoModal";
import Select from "react-select";
import TwoFactorVerify from "./TwoFactorVerify";

const Login = () => {
  const { token, setToken } = useToken();
  const [countryInfo, setCountryInfo] = useState([]);
  const [userCountryCode, setUserCountryCode] = useState("224");
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [showLoginRequiredModal, setShowLoginRequiredModal] = useState(false);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPasword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [staticData, setStaticData] = useState(null);
  let selectOptions = [];

  useEffect(() => {
    //  console.log('Boolean(localStorage.getItem(401)): ',typeof localStorage.getItem('401'));
    if (localStorage.getItem("401") === "true") {
      setShowLoginRequiredModal(true);
      localStorage.setItem("401", false);
      setTimeout(() => {
        setShowLoginRequiredModal(false);
      }, 5000);
    }
  }, []);

  if (token) {
    if (!localStorage.getItem("location")) {
      history.push("/");
      // console.log(
      //   "===========================",
      //   !localStorage.getItem("location"),
      //   localStorage.getItem("location")
      // );
      // debugger;
    } else {
      history.push("/wallet");
    }
  }

  useEffect(() => {
    axios
      .get(`${config.URL}/fetch-country-dail-code/`)
      .then(({ data }) => {
        console.log("country code", data.data);
        setCountryInfo(data.data);
      })
      .catch(console.log);
  }, []);

  selectOptions = countryInfo.map((data, index) => {
    return { label: data.phone, value: data.id };
  });

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/setting/`)
      .then(({ data }) => setStaticData(data))
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => setErrorMessage(""), 5000);
  }, [errorMessage]);

  const loginSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);

    if (!/^-?\d+$/.test(phone) && phone.length < 5) {
      setErrorMessage("Please enter a valid mobile number");
      setIsLoading(false);
      return;
    } else if (!password) {
      setErrorMessage("Please enter password");
      setIsLoading(false);
      return;
    }

    const request_payload = {
      phone: phone,
      password: password,
      dial_code: userCountryCode,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    console.log("request_payload: ", request_payload);
    //  console.log("headers: ", headers);

    let loginResponse = await axios
      .post(`${config.URL}/login/`, request_payload, {
        headers: headers,
      })
      .then((response) => {
        console.log("response: ", response);
        console.log(
          "response: 2fa ",
          response.data.data["is_two_factor_enabled"]
        );

        //  console.log("data: ", response.data);
        if (response.data.data["is_two_factor_enabled"] === true) {
          console.log(
            "twofactortest kye me hu",
            typeof response.data.data["is_two_factor_enabled"]
          );
          localStorage.setItem(
            "is_two_factor_enabled",
            response.data.data["is_two_factor_enabled"]
          );
          // history.push("/two-fa-verify");
          // localStorage.setItem("first_name", response.data.data["first_name"]);
          // localStorage.setItem("last_name", response.data.data["last_name"]);
          // localStorage.setItem("email", response.data.data["email"]);
          // localStorage.setItem("user_id", response.data.data["user_id"]);
          // localStorage.setItem("kingdom", response.data.data["kingdom"]);
          // localStorage.setItem("country", response.data.data["country"]);
          localStorage.setItem("phone", response.data.data["phone"]);
          // localStorage.setItem("refer_code", response.data.data["refer_code"]);
          // localStorage.setItem(
          //   "refered_code",
          //   response.data.data["refered_code"]
          // );
          // localStorage.setItem(
          //   "is_mobile_verified",
          //   response.data.data["is_phone_verified"]
          // );
          // localStorage.setItem(
          //   "is_email_verified",
          //   response.data.data["is_email_verified"]
          // );
          // localStorage.setItem(
          //   "reffreal_count",
          //   response.data.data["reffreal_count"]
          // );
          console.log("active step ", activeStep);
          setActiveStep((pre) => pre + 1);
        } else {
          console.log("else block");
          localStorage.setItem(
            "is_two_factor_enabled",
            response.data.data["is_two_factor_enabled"]
          );
          localStorage.setItem("first_name", response.data.data["first_name"]);
          localStorage.setItem("last_name", response.data.data["last_name"]);
          localStorage.setItem("email", response.data.data["email"]);
          localStorage.setItem("user_id", response.data.data["user_id"]);
          localStorage.setItem("kingdom", response.data.data["kingdom"]);
          localStorage.setItem("country", response.data.data["country"]);
          localStorage.setItem("phone", response.data.data["phone"]);
          localStorage.setItem("refer_code", response.data.data["refer_code"]);
          localStorage.setItem(
            "refered_code",
            response.data.data["refered_code"]
          );
          localStorage.setItem(
            "is_mobile_verified",
            response.data.data["is_phone_verified"]
          );
          localStorage.setItem(
            "is_email_verified",
            response.data.data["is_email_verified"]
          );
          localStorage.setItem(
            "reffreal_count",
            response.data.data["reffreal_count"]
          );
          setToken({
            access: response.data.data["access"],
            refresh: response.data.data["refresh"],
          });
          history.push("/myprofile");
        }
      })
      .catch(function (error) {
        console.log("error response: ", error.response);
        setErrorMessage(error.response.data.message);
      })
      .then(() => setIsLoading(false));
  };

  const filterOption = (option, inputValue) =>
    (option.label.toString().match(inputValue) || []).length > 0;

  return (
    <>
      <Header activeTab="login" isLoading={isLoading} />
      <InfoModal
        show={showLoginRequiredModal}
        setShow={setShowLoginRequiredModal}
        title="Login Required"
        body="Your access token is expired so your are automatically redirected to Login."
      />

      {activeStep === 0 && (
        <section className="sec buy-sec login-sec">
          <div className="container">
            <div className="setting-box">
              <div className="top-img desk-o">
                <img src="assets/images/icon-login.svg" alt="" />
              </div>
              <div className="setting-list">
                <div className="setting-head">
                  {/* <span className="back-btn">
                  <i className="fas fa-chevron-left"></i>
                </span> */}
                  <h3>
                    Welcome Back!<span>Sign into your wallet below.</span>
                  </h3>
                  <Link
                    to="#"
                    className="info-h desk-d"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Tooltip on top"
                  >
                    <i
                      data-tip
                      data-for="login-tip"
                      className="fas fa-question-circle"
                    ></i>
                    <ReactTooltip id="login-tip" place="bottom" effect="solid">
                      {staticData?.login_page_tip && staticData.login_page_tip}
                    </ReactTooltip>
                  </Link>
                </div>

                <div className="top-img mob-o">
                  <img src="assets/images/icon-login.svg" alt="" />
                </div>
                <form
                  name="loginform"
                  id="loginform"
                  method="POST"
                  onSubmit={loginSubmit}
                >
                  <div className="buy-col">
                    <div className="buy-con flex-wrap buy__col">
                      <div className="buy-user">
                        <div className="dropdown_layer">
                          <div className="dropdown_values set__hsa">
                            <label className="topItemvalue login_count">
                              COUNTRY CODE
                            </label>
                            {/* {console.log(selectOptions)} */}
                            <div className="count__itemOp">
                              <Select
                                className="count__item"
                                options={selectOptions}
                                placeholder="Select"
                                onChange={(opt) => {
                                  setUserCountryCode(opt.value);
                                }}
                                defaultValue={
                                  {
                                    label: "27",
                                    value: "224",
                                  } || "Select"
                                }
                                filterOption={filterOption}
                              />
                            </div>

                            {/* <select
                            name="datans"
                            id="dataid"
                            onChange={(e) => {
                              setUserCountryCode(e.target.value);
                            }}
                          >
                            <option value="">select</option>
                            {countryInfo.map((data, index) => {
                              return (
                                //<option key={index} value={[data.phone, data.name]}
                                <option key={index} value={data.id}>
                                  + {data.phone}
                                </option>
                              );
                            })}
                          </select> */}
                          </div>
                          <div className="login__outer">
                            <label className="mob_numb">Mobile Number</label>
                            <span className="login-icon-l">
                              <img src="assets/images/mob-icon.png" alt="" />
                            </span>
                            <input
                              type="text"
                              placeholder="081 123 4567"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              maxLength="14"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <p className="feild-error-y">
                      Oops, there seems to be a number missing
                    </p> */}
                      <div className="buy-pass">
                        <label className="pass_out">Password</label>
                        <span className="login-icon-l">
                          <img src="assets/images/lock-icon.png" alt="" />
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="login-icon-r"
                          onClick={() => {
                            setShowPasword(!showPassword);
                          }}
                        >
                          <img src="assets/images/eye-icon.png" alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="login-col">
                    <p className="log-for">
                      <Link to="/forgot-password">FORGOT PASSWORD</Link>
                    </p>
                    <p className="log-error">{errorMessage.split(":")[1]}</p>

                    <div className="buy-btn">
                      {/* <Link
                      to="#"
                      className="custom-btn btn-full"
                      onClick={loginSubmit}
                    >
                      Next
                    </Link> */}
                      <button
                        // type="submit"
                        type="button"
                        onClick={loginSubmit}
                        className="custom-btn btn-full"
                      >
                        Next
                      </button>
                    </div>
                    <p className="log-text">
                      Not a member yet? <Link to="/signup">Sign Up</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}

      {activeStep === 1 && <TwoFactorVerify />}

      {/* <div className="modeltheme-modal modeltheme-show">
        <div className="modeltheme-content" id="login-modal-content">
          <div className="logmdl-head">
            <h3 className="relative"> Login to Your Account</h3>
          </div>
          <div className="modal-content row">
            <div className="col-md-12">
              <form
                name="loginform"
                id="loginform"
                method="POST"
                onSubmit={onSubmit}
              >
                <p className="login-username">
                  <label htmlFor="user_login">Email Address</label>
                  <input
                    type="text"
                    name="email"
                    id="user_login"
                    className="input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    size="20"
                    required=""
                  />
                </p>
                <p className="login-password ">
                  <label htmlFor="user_pass">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="user_pass"
                    className="input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="20"
                    required=""
                  />
                </p>
                <p className="login-remember">
                  <label>
                    <input
                      name="rememberme"
                      type="checkbox"
                      id="rememberme"
                      value="forever"
                    />{" "}
                    Remember Me
                  </label>
                </p>
                <p className="login-remember">
                  <label>
                    <Link to="#">Forgot Password?</Link>
                  </label>
                </p>
                <p className="login-submit">
                  <input
                    type="submit"
                    name="submit"
                    id="submit"
                    className="button button-primary"
                    value="Log In"
                  />
                  <input
                    type="hidden"
                    name="redirect_to"
                    value="https://cryptic.modeltheme.com/"
                  />
                </p>
              </form>
              <p className="um-notice err text-center">
                <Link to="/signup">Register</Link>
              </p>
              <p className="um-notice err text-center">
                <Link to="https://cowriecoin.org/">RETURN TO HOMEPAGE</Link>
              </p>
            </div>
          </div>
        </div>
        <div className="modeltheme-content" id="signup-modal-content">
          <h3 className="relative"> Personal Details </h3>
          <div className="modal-content row">
            <div className="col-md-12"> </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default Login;
