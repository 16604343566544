import { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import useToken from "./useToken";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import config from "../config";
import axios from "axios";
import ReactTooltip from "react-tooltip";

const PaymentSuccess = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  if (!token) {
    history.push("/login");
  }

  const [isLoading, setIsLoading] = useState(false);
  const [staticData, setStaticData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(()=>{
      setIsLoading(false)
    }, 30000)
    axios
      .get(`${config.URL}/setting/`)
      .then(({ data }) => setStaticData(data))
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Header activeTab="signup" />
      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span className="back-btn">
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>
            <span className="info-h">
              <i
                data-tip
                data-for="signup-success-tip"
                className="fas fa-question-circle"
              ></i>
              <ReactTooltip
                id="signup-success-tip"
                place="bottom"
                effect="solid"
              >
                {staticData?.signup_success_page_tip}
              </ReactTooltip>
            </span>
          </div>
        </div>
      </header>

      <section className="sec buy-sec login-sec">
        <div className="container">
          <div className="setting-box">
            <div className="top-img desk-o">
              <img src="assets/images/icon-buy-2success.svg" alt="" />
            </div>
            <div className="setting-list">
              <div className="top-img mob-o">
                <img src="assets/images/icon-buy-2success.svg" alt="" />
              </div>

              <div className="buy-col">
                <h4 className="text-center">
                  Your bank account has been added successully.
                </h4>
              </div>
              <div className="login-col">
                <div className="">
                  
                    <Link to="/zar-withdraw" className="custom-btn">
                      CONTINUE TO WITHDRAW
                    </Link>
                    {/* <button className="custom-btn btn-full"
                                onClick={(e) => props.RegisterUser()}
                                >
                                WALLET</button> */}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PaymentSuccess;
