import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import useToken from "./useToken";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import handleError from "./handleError";
import InfoModal from "./InfoModal";

const Wallet = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [staticData, setStaticData] = useState(null);
  const [activeStep, setActiveStep] = useState("WalletHome");
  const [showPhoneVerifiedRequiredModal, setshowPhoneVerifiedRequiredModal] =
    useState(null);

  // const next = () => setActiveStep((active) => active + 1);
  // const prev = () => setActiveStep((active) => active - 1);
  const [conversionPrice, setConversionPrice] = useState(1.0);
  const [availableCowrie, setAvailableCowrie] = useState(0.0);
  const [lockedCowrie, setLockedCowrie] = useState(0.0);
  const [isLoading, setIsLoading] = useState(false);
  const first_name = localStorage.getItem("first_name");
  const [availableZar, setAvailableZar] = useState(0.0);
  const [lockedZar, setLockedZar] = useState(0.0);
  const [isCowrieZero, setisCowrieZero] = useState(false);
  const [livePrice, setlivePrice] = useState(0);
  const [livePriceLoading, setlivePriceLoading] = useState(false);
  const [buyRestriction, setBuyRestriction] = useState(false);
  const [stakedCowrie, setStakedCowrie] = useState(0.0);

  if (!token) {
    history.push("/login");
  }

  //  console.log("first_name: ", first_name);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };
  useEffect(() => {
    if (showPhoneVerifiedRequiredModal === false) {
      setToken("");
    }
  }, [showPhoneVerifiedRequiredModal]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // if (localStorage.getItem("is_mobile_verified") === "false") setToken("");
    axios
      .get(`${config.URL}/fetch-phone-verified/`, { headers })
      .then(({ data }) => {
        // console.log("+++++", data)
      })
      .catch((err) => {
        console.log("err::::::::::::::::::;;; ", err);
        if (err.response.status === 403) {
          setshowPhoneVerifiedRequiredModal(true);
          setTimeout(() => {
            setToken("");
          }, 4000);
        }
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/setting/`)
      .then(({ data }) => setStaticData(data))
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  const fetchTokenPrice = () => {
    axios
      .get(`${config.URL}/fetch-token-price-and-fee/`)
      .then(({ data }) => {
        setConversionPrice(data.data.token_price);
      })
      .catch(console.log);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    fetchTokenPrice();

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/fetch-my-cowrie-token/`, {
        headers: headers,
      })
      .then(({ data }) => {
        console.log("data.data.available_zar: ", data.data);
        setLockedCowrie(parseFloat(data.data.locked_cowrie));
        setAvailableCowrie(parseFloat(data.data.available_cowrie));
        setAvailableZar(parseFloat(data.data.available_zar));
        setLockedZar(parseFloat(data.data.in_progress_zar));
        setStakedCowrie(parseFloat(data.data.in_progress_staked_cowrie));
        // console.log("lockedCowrie", lockedCowrie);
        // console.log("availableCowrie", availableCowrie);
        // console.log("livePrice", livePrice);
        // console.log(
        //   "total",
        //   parseFloat((lockedCowrie + availableCowrie) * livePrice).toFixed(2)
        // );
        if (lockedCowrie > 0) {
          setisCowrieZero(false);
        } else {
          setisCowrieZero(true);
        }
      })
      .catch(function (error) {
        handleError(error, setToken);
      })
      .then(() => setIsLoading(false));
  }, [activeStep]);

  useEffect(() => {
    axios
      .get(`${config.URL}/trade/fetch-live-price-pancake-swap/`, {
        headers: headers,
      })
      .then(({ data }) => {
        console.log("data: ", data.data);
        setlivePrice(data.data);
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
  }, []);

  const fetchLivePriceFrom = () => {
    axios
      .get(`${config.URL}/trade/fetch-live-price-pancake-swap/`, {
        headers: headers,
      })
      .then(({ data }) => {
        // console.log("data: ", data.data);
        setlivePriceLoading(false);
        setlivePrice(data.data);
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
  };

  return (
    <div>
      <Header activeTab="wallet" isLoading={isLoading} />
      <InfoModal
        show={showPhoneVerifiedRequiredModal}
        setShow={setshowPhoneVerifiedRequiredModal}
        title="Session Expired"
        body="Your session has expired, please login again to continue."
      />
      <InfoModal
        show={buyRestriction}
        setShow={setBuyRestriction}
        title="Sorry"
        body="The ICO to South Africans under guarantee has come to an end. <br/><br/> The Cowrie is now listed on decentralized exchanges such as pancakeswap.finance, where it can be purchased by anyone, but not under guarantee. <br/><br/> It will soon be listed on centralized exchanges. Please ensure that you receive advice from a qualified and regulated financial advisor before making a purchase."
      />
      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span className="back-btn" onClick={history.goBack}>
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={` ${
                  showMenu ? "menu-opened manu-overlay bg-overlay-anim" : ""
                }`}
                onClick={() => setShowMenu(!showMenu)}
              ></div>
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link to="/wallet" className="active">
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup">SIGN UP</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <div className="mov-h wallet-top" style={{ background: "#262626" }}>
        <div className="container">
          <div className="top-header wallet-header">
            <div className="wallet-head">
              <h2>Wallet</h2>
              <p>Hello {first_name}</p>

              <span> Your combined wallet value</span>
              <h2>
                ${" "}
                {parseFloat(
                  parseFloat(
                    (lockedCowrie + availableCowrie + stakedCowrie) * livePrice
                  ).toFixed(2)
                ).toLocaleString()}
              </h2>
            </div>
            <div className="wallet-tab-list">
              <ul>
                <li
                  onClick={() => {
                    setBuyRestriction(true);
                  }}
                >
                  <Link to="/buy">
                    <img src="assets/images/icon-wallet-buy.svg" alt="" />
                    <span className="wallet-buy-btn">Buy</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname:
                        "https://pancakeswap.finance/info/pairs/0x4db82153a8faf5cf6ca342cde0ff119b1ca02a04",
                    }}
                    target="_blank"
                  >
                    <img src="assets/images/icon-wallet-sell.svg" alt="" />
                    <span className="wallet-buy-btn">Sell</span>
                  </Link>
                </li>
                <li>
                  <Link to="/send-cowrie">
                    <img src="assets/images/icon-wallet-send.svg" alt="" />
                    <span className="wallet-buy-btn">Send</span>
                  </Link>
                </li>
                <li>
                  <Link to="/cowrie-deposite">
                    <img src="assets/images/icon-wallet-receive.svg" alt="" />
                    <span className="wallet-buy-btn">Receive</span>
                  </Link>
                </li>
                <li>
                  <Link to="/staking">
                    <img src="assets/images/icon-wallet-play.svg" alt="" />
                    <span>Staking</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="sec wallet-sec mset">
        <div className="container">
          <div className="setting-box">
            <div className="wallet-head dest-head">
              <h2>Wallet</h2>
              <p>Hello {first_name}</p>

              <span>Your combined wallet value</span>
              <h2>
                ${" "}
                {parseFloat(
                  parseFloat(
                    (lockedCowrie + availableCowrie + stakedCowrie) * livePrice
                  ).toFixed(2)
                ).toLocaleString()}
              </h2>
            </div>
            <div className="wallet-tab-list dest-head">
              <ul>
                <li
                  onClick={() => {
                    setBuyRestriction(true);
                  }}
                >
                  <Link to="/buy">
                    <img src="assets/images/icon-wallet-buy.svg" alt="" />
                    <span className="wallet-buy-btn">Buy</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname:
                        "https://pancakeswap.finance/info/pairs/0x4db82153a8faf5cf6ca342cde0ff119b1ca02a04",
                    }}
                    target="_blank"
                  >
                    <img src="assets/images/icon-wallet-sell.svg" alt="" />
                    <span className="wallet-buy-btn">Sell</span>
                  </Link>
                </li>
                <li>
                  <Link to="/send-cowrie">
                    <img src="assets/images/icon-wallet-send.svg" alt="" />
                    <span className="wallet-buy-btn">Send</span>
                  </Link>
                </li>
                <li>
                  <Link to="/cowrie-deposite">
                    <img src="assets/images/icon-wallet-receive.svg" alt="" />
                    <span className="wallet-buy-btn">Receive</span>
                  </Link>
                </li>
                <li>
                  <Link to="/staking">
                    <img src="assets/images/icon-wallet-play.svg" alt="" />
                    <span className="wallet-buy-btn">Staking</span>
                  </Link>
                </li>
              </ul>
            </div>
            {/* ---------------------------------------------------------------------------------------- */}
            {/* <div className="setting-list">
              <div className="wallet-row">
                <div className="wallet-icon">
                  <img src="assets/images/s-logo.png" alt="" />
                </div>
                <div className="wallet-con">
                  <h3>
                    COWRIE {parseFloat(availableCowrie).toFixed(2)}
                  </h3>
                  <p>
                    ZAR {parseFloat((lockedCowrie+availableCowrie) * conversionPrice).toFixed(2)}
                  </p>
                  <span>
                    <i className="fas fa-lock"></i>LOCKED COWRIE{" "}
                    {parseFloat(lockedCowrie).toFixed(2)}
                  </span>
                  <div class="wallet_unlock">
                    <div class="target_unlock">
                      <a class="unlockAtarget" href="#">UNLOCK</a>
                    </div>
                    <div class="target_unlockRefund">
                      <a class="unlockRefund" href="#">VIP REFUND</a>
                    </div>
                  </div>
                </div>
                <Link to="/transaction-history" style={{ color: "inherit" }}>
                  <div className="wallet-arrow">
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </Link>
              </div>
            </div> */}

            {activeStep === "WalletHome" && (
              <WalletHome
                lockedCowrie={lockedCowrie}
                stakedCowrie={stakedCowrie}
                conversionPrice={conversionPrice}
                setActiveStep={setActiveStep}
                history={history}
                availableCowrie={availableCowrie}
                lockedZar={lockedZar}
                availableZar={availableZar}
                livePrice={livePrice}
                fetchLivePriceFrom={fetchLivePriceFrom}
                setlivePrice={setlivePrice}
                livePriceLoading={livePriceLoading}
                setlivePriceLoading={setlivePriceLoading}
              />
            )}

            {activeStep === "ConfirmUnlockCowrie" && (
              <ConfirmUnlockCowrie
                setActiveStep={setActiveStep}
                token={token}
                isCowrieZero={isCowrieZero}
              />
            )}

            {activeStep === "SuccessfullyUnlockedCowrie" && (
              <SuccessfullyUnlockedCowrie setActiveStep={setActiveStep} />
            )}

            {activeStep === "ConfirmRefund" && (
              <ConfirmRefund
                setActiveStep={setActiveStep}
                token={token}
                isCowrieZero={isCowrieZero}
              />
            )}

            {activeStep === "SuccessfullyRequestedRefund" && (
              <SuccessfullyRequestedRefund setActiveStep={setActiveStep} />
            )}
            {/* -------------------------------------------- */}
            {/* <div className="setting-list">
              <div className="wallet-row">
                <div className="wallet-icon-img">
                  <img src="assets/images/img-icon-b.png" alt="" />
                </div>
                <div className="wallet-con-det">
                  <p>Thank you for participating in the COWRIE VIP Event.</p>

                  <p>Your COWRIE are deployed and safe in your wallet.</p>

                  <p>
                    Your staking rewards will commence on 24 September 2022.
                  </p>
                </div>
              </div>
            </div> */}

            {/* <div className="setting-list">
              <div className="wallet-row custom-banner">
                <div
                  className="top-img custom-banner"
                  style={{ margin: "auto" }}
                >
                  <Link to="/share-referral">
                    {staticData?.wallet_page_img1 ? (
                      <img
                        src={config.URL + staticData.wallet_page_img1}
                        alt=""
                      />
                    ) : (
                      <img src="assets/images/img-icon-b.png" alt="" />
                    )}
                  </Link>
                </div>
              </div>
            </div>

            <div className="setting-list">
              <div className="wallet-row custom-banner">
                <div
                  className="top-img custom-banner"
                  style={{ margin: "auto" }}
                >
                  <Link to="/share-referral">
                    {staticData?.wallet_page_referral_img ? (
                      <img
                        src={config.URL + staticData.wallet_page_referral_img}
                        alt=""
                      />
                    ) : (
                      <img src="assets/images/img-icon-b.png" alt="" />
                    )}
                  </Link>
                </div>
              </div>
            </div> */}

            <div className="setting-list">
              <div className="wallet-row col-row">
                <div className="wallet-col">
                  <a
                    href="https://community.mycowrie.org/the-cowrie-care-program/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/images/wallet-img1.png" alt="" />
                  </a>
                </div>
                <div className="wallet-col">
                  <a
                    href="https://community.mycowrie.org/the-cowrie-care-program/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/images/wallet-img2.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Wallet;

const WalletHome = ({
  lockedCowrie,
  stakedCowrie,
  conversionPrice,
  setActiveStep,
  history,
  availableCowrie,
  lockedZar,
  availableZar,
  fetchLivePriceFrom,
  livePrice,
  setlivePrice,
  livePriceLoading,
  setlivePriceLoading,
}) => {
  return (
    <div>
      <div className="setting-list">
        <div className="wallet-row wallet_layer">
          <div className="wallet-icon">
            <img src="assets/images/s-logo.png" alt="" />
          </div>{" "}
          <div className="wallet-con w-100">
            <h3>COWRIE/USDT</h3>
            <p style={{ fontSize: "20px", paddingLeft: "35px" }}>
              {livePrice.toFixed(3)}
            </p>
            <span style={{ fontWeight: "bold", paddingLeft: "31px" }}>
              {" "}
              &asymp; ${livePrice.toFixed(3)}
            </span>

            <div className="wallet_unlock">
              <div className="target_unlockRefund">
                <span
                  className="unlockRefund p-1"
                  onClick={() => {
                    setlivePrice(0);
                    setlivePriceLoading(true);
                    fetchLivePriceFrom();
                  }}
                >
                  {livePriceLoading
                    ? "Loading Live Price"
                    : "Refresh Live Price"}{" "}
                  <i className="fa fa-refresh"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="setting-list">
        <div className="wallet-row wallet_layer">
          <div className="wallet-icon">
            <img src="assets/images/s-logo.png" alt="" />
          </div>
          <div className="wallet-con">
            <h3>
              COWRIE{" "}
              {parseFloat(
                parseFloat(availableCowrie).toFixed(2)
              ).toLocaleString()}
              {/* {parseFloat(lockedCowrie).toFixed(2)} */}
            </h3>
            {/* <p>
              ZAR{" "}
              {parseFloat(
                (lockedCowrie + availableCowrie) * conversionPrice
              ).toFixed(2)}
            </p> */}
            <span>
              <i className="fas fa-lock"></i>LOCKED COWRIE{" "}
              {parseFloat(lockedCowrie).toFixed(2)}
            </span>
            <br />
            <span>STAKED COWRIE {parseFloat(stakedCowrie).toFixed(2)}</span>

            <div className="wallet_unlock">
              <div className="target_unlock">
                <span
                  className="unlockAtarget"
                  onClick={() => {
                    setActiveStep("ConfirmUnlockCowrie");
                  }}
                >
                  UNLOCK
                </span>
              </div>
              <div className="target_unlockRefund">
                <span
                  className="unlockRefund"
                  onClick={() => {
                    setActiveStep("ConfirmRefund");
                  }}
                >
                  VIP REFUND
                </span>
              </div>
            </div>
          </div>
          <Link to="/transaction-history" style={{ color: "inherit" }}>
            <div className="wallet-arrow">
              <i className="fas fa-chevron-right"></i>
            </div>
          </Link>
        </div>
      </div>

      <div className="setting-list">
        <div className="wallet-row wallet_layer">
          <div className="wallet-icon">
            <img src="assets/images/f-icon2.png" alt="" />
          </div>
          <div className="wallet-con">
            <h3>
              ZAR {availableZar}
              {/* {parseFloat(lockedCowrie).toFixed(2)} */}
            </h3>
            <span>
              <i className="fas fa-lock"></i>LOCKED ZAR {lockedZar}
            </span>
            <div className="wallet_unlock">
              <div className="target_unlockRefund">
                <span
                  className="unlockRefund"
                  onClick={() => {
                    history.push("/zar-withdraw");
                  }}
                >
                  WITHDRAW FUNDS
                </span>
              </div>
            </div>
          </div>
          <Link to="/transaction-history" style={{ color: "inherit" }}>
            <div className="wallet-arrow">
              <i className="fas fa-chevron-right"></i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const ConfirmUnlockCowrie = ({ setActiveStep, token, isCowrieZero }) => {
  const [accepted, setAccepted] = useState(false);
  const [key_ZeroCowrie, setkey_ZeroCowrie] = useState(false);

  const handleSubmit = () => {
    if (isCowrieZero) {
      setkey_ZeroCowrie(true);
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/unlock-cowrie/`, {
        headers: headers,
      })
      .then(({ data }) => {
        //  console.log("data: ", data);
        setActiveStep("SuccessfullyUnlockedCowrie");
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
  };
  return (
    <div className="setting-list setting_listElements">
      <div className="setting-head">
        <span className="back-btn d-none">
          <i className="fas fa-chevron-left"></i>
        </span>
        <h3>Are you sure you want to unlock your COWRIE ?</h3>
      </div>

      {key_ZeroCowrie ? (
        <InfoModal
          show={key_ZeroCowrie}
          setShow={setkey_ZeroCowrie}
          title="Locked COWRIE Empty"
          body="You do not have any locked COWRIE."
        />
      ) : (
        ""
      )}

      <div className="update-col">
        <div className="cowrie_unlocktitle">
          <p className="cowrie_unlocktrade">
            When you unlock your COWRIE to trade on any exchange or use it to
            purchase products or services, your money-back guarantee will fall
            away.
          </p>
        </div>
      </div>
      <div className="checkBlockBox">
        <div className="new ">
          <form className="formGroupBlock">
            <div className="form-group blockGroup">
              <div className="checkOuter">
                <input
                  type="checkbox"
                  id="accept"
                  checked={accepted}
                  onClick={() => {
                    setAccepted(!accepted);
                  }}
                />
                <label htmlFor="accept" className="checkAccept"></label>
              </div>
              <div className="checkTitleBLock">
                <p className="checkline">
                  I accept that the money-back guarantee fails away when I
                  unlock my COWRIE
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="buy-btn unlockLayerBtn">
        <div className="unlockLayerBlock">
          <div className="">
            <button
              className="custom-btn button_custom"
              disabled={!accepted}
              onClick={handleSubmit}
              style={!accepted ? { background: "gray" } : {}}
            >
              CONTINUE
            </button>
          </div>
          <div className="">
            <button
              className="custom-btn button_custom"
              onClick={() => {
                setActiveStep("WalletHome");
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuccessfullyUnlockedCowrie = ({ setActiveStep }) => {
  return (
    <div className="setting-list setting_listElements">
      <div className="setting-head">
        <span className="back-btn d-none">
          <i className="fas fa-chevron-left"></i>
        </span>
        <h3>
          Your COWRIE has been unlocked successfully and is now available in
          your COWRIE wallet balance
        </h3>
      </div>
      <div className="buy-btn unlockLayerBtn btnGotitOuter">
        <div className="unlockLayerBlock btnGotit">
          <button
            className="custom-btn button_custom"
            onClick={() => {
              setActiveStep("WalletHome");
            }}
          >
            GOT IT
          </button>
        </div>
      </div>
    </div>
  );
};

const ConfirmRefund = ({ setActiveStep, token, isCowrieZero }) => {
  const [key_ZeroCowrie, setkey_ZeroCowrie] = useState(false);
  const handleSubmit = () => {
    if (isCowrieZero) {
      setkey_ZeroCowrie(true);
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/refund-request/`, {
        headers: headers,
      })
      .then(({ data }) => {
        //  console.log("data: ", data);
        setActiveStep("SuccessfullyRequestedRefund");
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
  };
  return (
    <div className="setting-list setting_listElements">
      <div className="setting-head">
        <span className="back-btn d-none">
          <i className="fas fa-chevron-left"></i>
        </span>
        <h3>Are you sure you want a refund on your VIP SALE COWRIE?</h3>
      </div>
      {key_ZeroCowrie ? (
        <InfoModal
          show={key_ZeroCowrie}
          setShow={setkey_ZeroCowrie}
          title="Locked COWRIE Empty"
          body="You do not have any locked COWRIE."
        />
      ) : (
        ""
      )}
      <div className="buy-btn unlockLayerBtn mt-4">
        <div className="unlockLayerBlock EsetErs">
          <div className="Esetbtn">
            <button
              className="custom-btn button_custom Eset"
              onClick={handleSubmit}
            >
              YES I'M SURE
            </button>
          </div>
          <div className="Esetbtn">
            <button
              className="custom-btn button_custom Eset"
              onClick={() => {
                setActiveStep("WalletHome");
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuccessfullyRequestedRefund = ({ setActiveStep }) => {
  return (
    <div className="setting-list setting_listElements">
      <div className="setting-head">
        <span className="back-btn d-none">
          <i className="fas fa-chevron-left"></i>
        </span>
        <h3>Refund request successful.</h3>
      </div>
      <div className="update-col refundsuccess">
        <p>
          Your funds are now available in your ZAR wallet. To withdraw, click on
          WITHDRAW FUNDS via your ZAR wallet and set up your payment
          information.{" "}
        </p>
      </div>
      <div className="buy-btn unlockLayerBtn btnGotitOuter">
        <div className="unlockLayerBlock btnGotit">
          <button
            href="#"
            className="custom-btn button_custom"
            onClick={() => {
              setActiveStep("WalletHome");
            }}
          >
            GOT IT
          </button>
        </div>
      </div>
    </div>
  );
};
