import { useState, useEffect } from "react";
import { useHistory, Link} from 'react-router-dom';
import useToken from './useToken';
import Header from './Header';
import Footer from './Footer';

const UpdateInfo = () => {
    const { token, setToken } = useToken();
    const history = useHistory()
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <Header activeTab="myprofile" />

            <section className="sec setting-sec">
                <div className="container">
                    <div className="setting-box">

                        <div className="setting-list">
                            <div className="setting-head">
                                <span className="back-btn">
                                    <Link to="/myprofile"><i className="fas fa-chevron-left"></i></Link>
                                </span>
                            <h3>Update My Information</h3>
                            </div>
                            <div className="update-col">
                            <p>To change any of your personal information please contact the support team by clicking the button below.</p>

                            <p>For security reasons the support team will ask you a few security questions before updating your account to verify you are you.</p>
                            </div>
                            <div className="buy-btn">
                                        <a href="mailto:support@mycowrie.org" className="custom-btn">SEND CHANGE REQUEST</a>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            <Footer/>

        </>
    );
}

export default UpdateInfo;
