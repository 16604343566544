import { useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import useToken from "./useToken";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import config from "../config";
import InfoModal from "./InfoModal";

const VerifyOTP = () => {
  const { carrier } = useParams();
  const { token, setToken } = useToken();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  // const [count, setCount] = useState(179);
  // const [otpSent, setOptSent] = useState(true);

  //console.log('Link :',`${config.URL}/verify-${carrier}-otp/`);
  //console.log('carrier: ', carrier);
  //console.log('`is_${carrier}_verified`: ', `is_${carrier}_verified`);
  const [isOtpVerified, setIsOtpVerified] = useState(null)
  // const myInterval = setInterval(() => {
  //  console.log(count);
  //   setCount(count--);
  //   if (count === 0) {
  //     clearInterval(myInterval);
  //     setCount(179);
  //     setOptSent(false);
  //   }
  // }, 1000);
  useEffect(()=>{
    if(isOtpVerified===false){
      history.push('/myprofile')
    }
  },[isOtpVerified])

  if (!token) {
    history.push("/login");
  }
  else if(carrier!=='mobile' && carrier!=='email'){
    history.push("/myprofile");
  }
  else if(carrier==='email' && localStorage.getItem('email')==='null'){
    history.push("/myprofile");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsOtpVerified(null)
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };

  useEffect(() => {
    if (otp.length === 6) {
      setError("");
    }
  }, [otp]);

  const handleVerifyOtp = () => {
    if (otp.length === 6) {
      setIsLoading(true);
      setTimeout(()=>{
        setIsLoading(false)
      }, 30000)
      axios
      .post(`${config.URL}/verify-${carrier}-otp/`, { otp: otp }, { headers })
      .then((response) => {
      //  console.log("response: ", response);
        localStorage.setItem(`is_${carrier}_verified`, true);
        setIsLoading(false);
        setIsOtpVerified(true)
        setTimeout(()=>{
          history.push('/myprofile')
        },4000)
      })
      .catch((error) => {
          setIsLoading(false);
          setError("Invalid OTP");
        //  console.log("error: ", error);
        });
    } else {
      setError("Enter a valid 6-digit OTP");
    }
  };

  // const time = (
  //   <div>
  //     RESEND OTP
  //     <br />
  //     in 00:{" "}
  //     {count.toLocaleString("en-US", {
  //       minimumIntegerDigits: 2,
  //       useGrouping: false,
  //     })}{" "}
  //     SEC
  //   </div>
  // );
  return (
    <>
      <Header activeTab='myprofile' isLoading={isLoading} />
      <InfoModal
            show={isOtpVerified}
            setShow={setIsOtpVerified}
            title="OTP Verified"
            body={`Your ${carrier==='email'?'Email':'Mobile Number'} has been successfully verified.`}
        />
      <section className='sec setting-sec'>
        <div className='container'>
          <div className='setting-box'>
            <div className='setting-list'>
              <div className='setting-head'>
                <span className='back-btn'>
                  <Link to='/myprofile'>
                    <i className='fas fa-chevron-left'></i>
                  </Link>
                </span>
                <h3>Verify OTP</h3>
              </div>
              <div className='verify-col'>
                {carrier === 'mobile' ?
                  <p>Enter the OTP sent to your Mobile Number.</p>:
                  <p>Enter the OTP sent to your Email.</p>
                }
                <div className='otp-con flex-wrap'>
                  <div className='set-otp'>
                    <label>OTP</label>
                    <input
                      type='number'
                      placeholder='Enter OTP'
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      max='10'
                    />
                  </div>
                </div>
                <p className='feild-error-y'>{error}</p>

                {/* <button
                  className='btnall otp-button'
                  // onClick={sendOTP}
                  disabled={otpSent}
                  style={otpSent ? { color: "gray" } : {}}
                  >
                  {otpSent ? time : "RESEND OTP"}
                </button> */}
              </div>
              <div className='buy-btn'>
                <button
                  className='custom-btn'
                  onClick={handleVerifyOtp}
                  style={otp.length !== 6 ? { background: "gray" } : {}}
                >
                  Verify OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default VerifyOTP;
