import { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import useToken from "./useToken";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import config from "../config";
import InfoModal from "./InfoModal";

const OTPNotRecived = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [phoneVarified, setphoneVarified] = useState(
    localStorage.getItem("is_mobile_verified")
  );

  console.log("phoneVarified", phoneVarified);
  // const [count, setCount] = useState(179);
  // const [otpSent, setOptSent] = useState(true);

  //console.log('Link :',`${config.URL}/verify-${carrier}-otp/`);
  //console.log('carrier: ', carrier);
  //console.log('`is_${carrier}_verified`: ', `is_${carrier}_verified`);
  const [isOtpVerified, setIsOtpVerified] = useState(null);
  // const myInterval = setInterval(() => {
  //  console.log(count);
  //   setCount(count--);
  //   if (count === 0) {
  //     clearInterval(myInterval);
  //     setCount(179);
  //     setOptSent(false);
  //   }
  // }, 1000);
  // useEffect(()=>{
  //   if(isOtpVerified===false){
  //     history.push('/myprofile')
  //   }
  // },[isOtpVerified])

  // if (!token) {
  //   history.push("/login");
  // }

  if (!token) {
    history.push("/login");
  }

  if (phoneVarified === "true") {
    // console.log("running myprofile", typeof phoneVarified);
    history.push("/myprofile");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsOtpVerified(null);
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };

  useEffect(() => {
    if (otp.length === 6) {
      setError("");
    }
  }, [otp]);

  // const handleVerify = () => {
  //   // setIsLoading(true);
  //   axios
  //     .get(`${config.URL}/get-${carrier}-otp/`, { headers })
  //     .then(({ data }) => {
  //       console.log("data: ", data);
  //       // setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("error: ", error);
  //       // setIsLoading(false);
  //     });
  // };

  // const handleVerifyOtp = () => {
  //   if (otp.length === 6) {
  //     // setIsLoading(true);
  //     axios
  //       .post(`${config.URL}/verify-${carrier}-otp/`, { otp: otp }, { headers })
  //       .then((response) => {
  //         //  console.log("response: ", response);
  //         localStorage.setItem(`is_${carrier}_verified`, true);
  //         // setIsLoading(false);
  //         setIsOtpVerified(true);
  //         setSignupOTP(false);
  //         setTimeout(() => {
  //           history.push("/signup-success");
  //         }, 4000);
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //         setError("Invalid OTP");
  //         console.log("error: ", error);
  //       });
  //   } else {
  //     setError("Enter a valid 6-digit OTP");
  //   }
  // };

  // const time = (
  //   <div>
  //     RESEND OTP
  //     <br />
  //     in 00:{" "}
  //     {count.toLocaleString("en-US", {
  //       minimumIntegerDigits: 2,
  //       useGrouping: false,
  //     })}{" "} signupOTP=false
  //     SEC
  //   </div>
  // );
  return (
    <>
      <Header activeTab="signup" />

      <section className="sec setting-sec">
        <div className="container">
          <div className="setting-box">
            <div className="setting-list">
              <div className="setting-head">
                <span className="back-btn">
                  {/* <Link to='/myprofile'>
                    <i className='fas fa-chevron-left'></i>
                  </Link> */}
                </span>
                <h3>OTP NOT SEND TO YOUR NUMBER</h3>
                <p className="text-danger pt-3">
                  your phone number is not valid, please verify and change your
                  phone number in your <Link to={"/myprofile"}> Profile </Link>{" "}
                  Section
                </p>
              </div>

              <div className="buy-btn">
                <button
                  className="custom-btn mr-3 mb-2"
                  onClick={() => {
                    history.push("/myprofile");
                  }}
                >
                  Profile
                </button>
              </div>
              <div className="buy-btn"></div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default OTPNotRecived;
