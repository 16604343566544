import { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import useToken from "./useToken";
import Header from "./Header";
import Footer from "./Footer";
import config from "../config";
import axios from "axios";
import InfoModal from "./InfoModal";

const UpdateEmail = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const [error, setError] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [isLoading, setIsLoading] = useState(false);
  const [emailToUpdate, setEmailToUpdate] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState(null);
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [validEmail, setValidEmail] = useState(false);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };

  if (!token) {
    history.push("/login");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (updatedEmail === false) {
      handleVerify();
    }
  }, [updatedEmail]);

  useEffect(() => {
    if (regex.test(emailToUpdate)) {
      setError("");
      setValidEmail(true);
    } else {
      setValidEmail(false);
      if (!emailToUpdate) {
        setError("Please input an email.");
      } else if (regex.test(emailToUpdate) === false) {
        setError("Email is not valid.");
      }
    }
  }, [emailToUpdate]);

  const handleVerify = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/get-email-otp/`, { headers })
      .then(({ data }) => {
        //  console.log("data: ", data);
        setIsLoading(false);
        history.push(`/verify-otp/email`);
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsLoading(false);
      });
  };

  const handleEmail = () => {
    if (!emailToUpdate || regex.test(emailToUpdate) === false) {
      setError("Email is not valid.");
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 30000);
      axios
        .post(
          `${config.URL}/email-update/`,
          { email: emailToUpdate },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setIsLoading(false);
          //  console.log("response: ", response);
          localStorage.setItem("email", emailToUpdate);
          localStorage.setItem("is_email_verified", false);
          setUpdatedEmail(true);
          setTimeout(() => {
            handleVerify();
          }, 4000);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error: ", error);
        });
    }
  };

  return (
    <>
      <Header activeTab="myprofile" isLoading={isLoading} />
      <InfoModal
        show={updatedEmail}
        setShow={setUpdatedEmail}
        title="OTP Verified"
        body={`Your Email is ${
          email !== "null" ? "Updat" : "Add"
        }ed successfully now redirecting to verify it.`}
      />
      <section className="sec setting-sec">
        <div className="container">
          <div className="setting-box">
            <div className="setting-list">
              <div className="setting-head">
                <span className="back-btn">
                  <Link to="/myprofile">
                    <i className="fas fa-chevron-left"></i>
                  </Link>
                </span>
                <h3>{email !== "null" ? "Update" : "Add"} Email</h3>
              </div>
              <div className="verify-col">
                <p>
                  Enter your Email address to{" "}
                  {email !== "null" ? "Update" : "Add"}
                </p>
                <div className="otp-con flex-wrap">
                  <div className="set-otp">
                    <label>Email</label>
                    <input
                      type="text"
                      placeholder="name@example.com"
                      value={emailToUpdate}
                      onChange={(e) => setEmailToUpdate(e.target.value)}
                    />
                  </div>
                </div>
                <p className="feild-error-y">{error}</p>
              </div>
              <div className="buy-btn">
                <button
                  className="custom-btn"
                  onClick={handleEmail}
                  style={!validEmail ? { background: "gray" } : {}}
                >
                  {email !== "null" ? "Update" : "Add"} & Verify Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default UpdateEmail;
