import { useState } from "react";
import { useHistory, Link} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const FullVerification = ({token}) => {
    const history = useHistory()
    return (
        <>
            <Header activeTab="setting" />

            <section className="sec setting-sec">
                <div className="container">
                    <div className="setting-box">
                                
                        <div className="setting-list">
                            <div className="setting-head">
                                <span className="back-btn">
                                    <Link to="/myprofile"><i className="fas fa-chevron-left"></i></Link>
                                </span> 
                                <h3>Let’s make sure you are you</h3>
                            </div>
                            <div className="mid-img-box"><img src="assets/images/img-icon.png" alt="" /></div>
                            
                            <div className="buy-btn">
                                <Link to="/update-info" className="custom-btn">START</Link>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </section>
        
            <Footer/> 
            
        </>
    );
}

export default FullVerification;
