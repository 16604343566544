import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Modal } from "react-bootstrap";
import axios from "axios";
import config from "../config";
import useToken from "./useToken";
import handleError from "./handleError";
import { Link, useHistory } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import Web3 from "web3";
import InfoModal from "./InfoModal";
import OtpInput from "react-otp-input";

export default function SendCowrie() {
  const [isLoading, setIsLoading] = useState(false);
  const [availableCowrie, setAvailableCowrie] = useState(0.0);
  const { token, setToken } = useToken();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const next = () => setActiveStep((active) => active + 1);
  const prev = () => setActiveStep((active) => active - 1);
  const [qrModal, setQrModal] = useState(false);
  const [data, setData] = useState("No result");
  const [walletAddressBook, setWalletAddressBook] = useState([]);
  const [cowrieAddress, setCowrieAddress] = useState("");
  const [cowrieNewAddress, setCowrieNewAddress] = useState("");
  const [reciverName, setReciverName] = useState("");
  const [newName, setNewName] = useState("");
  const [totalCowrieAmount, setTotalCowrieAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [networkChain, setNetworkChain] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [otpSent, setOptSent] = useState(false);
  const [count, setCount] = useState(179);
  const [cowrieWFee, setCowrieWFee] = useState("");
  const [cowrieWFeePrice, setCowrieWFeePrice] = useState("");
  const [cowrieTokenPrice, setCowrieTokenPrice] = useState("");
  const [oneDollarToZar, setOneDollarToZar] = useState(0.1);
  const [tokenPriceCowrieFee, setTokenPriceCowrieFee] = useState("");
  const [emailSaved, setEmailSaved] = useState("");
  const [editUser, setEditUser] = useState("");
  const [pancakeCowrielivePrice, setPancakeCowrielivePrice] = useState(0.1);
  const [feesCowrieWithdrawal, setFeesCowrieWithdrawal] = useState(0.1);

  if (!token) {
    history.push("/login");
  }

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/fetch-my-cowrie-token/`, {
        headers: headers,
      })
      .then(({ data }) => {
        // console.log(data)
        setAvailableCowrie(parseFloat(data.data.available_cowrie));
      })
      .catch(function (error) {
        handleError(error, setToken);
      })
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .get(`${config.URL}/trade/fetch-all-wallet-address-book/`, {
        headers: headers,
      })
      .then(({ data }) => {
        console.log("data wallet address", data);
        setWalletAddressBook(data.data);
      })
      .catch(function (error) {
        console.log("error", error);
      })
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    //console.log('config.URL: ', config.URL);
    //console.log('headers: ', headers);
    axios
      .get(`${config.URL}/fetch-token-price-and-fee/`, {
        headers: headers,
      })
      .then(({ data }) => {
        // console.log("data related to cowrie withdrawal", data["cowrie_withdrawal_fee"]);
        setCowrieWFeePrice(data.data.cowrie_withdrawal_fee);
        setCowrieTokenPrice(data.data.token_price);
      })
      .catch(function (error) {
        handleError(error, setToken);
      });
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };
  useEffect(() => {
    axios
      .get(`${config.URL}/trade/fetch-live-price-pancake-swap/`, {
        headers: headers,
      })
      .then(({ data }) => {
        console.log("data: ", data.data);
        setPancakeCowrielivePrice(data.data);
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${config.URL}/fetch-token-price-and-fee/`, {
        headers: headers,
      })
      .then(({ data }) => {
        console.log("dollar data: ", data.data);
        setOneDollarToZar(data.data.dollar_price_in_zar);
      })
      .catch(function (error) {
        console.log("error: ", error);
      });
  }, []);

  return (
    <>
      <Header activeTab="wallet" isLoading={isLoading} />
      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span
              className="back-btn"
              onClick={() => {
                if (activeStep) {
                  if (activeStep === 4) {
                    setActiveStep(0);
                  } else if (activeStep === 5) {
                    setActiveStep(4);
                  } else {
                    prev();
                  }
                } else {
                  activeStep ? prev() : history.goBack();
                }
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <span className="info-h">
              <i
                data-tip
                data-for="registerTip"
                className="fas fa-question-circle"
              ></i>
              {/* <ReactTooltip id="registerTip" place="top" effect="solid">
                {staticData?.buy_page_conversion_tip &&
                  staticData.buy_page_conversion_tip}
              </ReactTooltip> */}
            </span>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={` ${
                  showMenu ? "menu-opened manu-overlay bg-overlay-anim" : ""
                }`}
                onClick={() => setShowMenu(!showMenu)}
              ></div>
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          //  console.log(
                          //     "chal rha hai",
                          //     localStorage.getItem("is_mobile_verified")
                          //   );
                          if (
                            localStorage.getItem("is_mobile_verified") ===
                            "true"
                          ) {
                            //  console.log("+++++++");
                            history.push("/wallet");
                          } else {
                            //  console.log("------");
                            // setshowPhoneVerifiedRequiredModal(true);
                          }
                        }}
                        className="active"
                      >
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup">SIGN UP</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <EditAddressModal
        show={showEditModal}
        setShow={setShowEditModal}
        name={editUser}
        setQrModal={setQrModal}
        walletAddressBook={walletAddressBook}
        editUser={editUser}
        setEditUser={setEditUser}
        setWalletAddressBook={setWalletAddressBook}
        token={token}
      />
      <QRModal
        show={qrModal}
        setShow={setQrModal}
        data={data}
        setData={setData}
        setCowrieAddress={setCowrieAddress}
        activeStep={activeStep}
        setCowrieNewAddress={setCowrieNewAddress}
        setReciverName={setReciverName}
        showEditModal={showEditModal}
        setEditUser={setEditUser}
      />
      {activeStep === 0 && (
        <ReceiverDetailsComponent
          setCowrieWFee={setCowrieWFee}
          availableCowrie={availableCowrie}
          next={next}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setQrModal={setQrModal}
          cowrieAddress={cowrieAddress}
          totalCowrieAmount={totalCowrieAmount}
          setCowrieAddress={setCowrieAddress}
          setTotalCowrieAmount={setTotalCowrieAmount}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setNetworkChain={setNetworkChain}
          networkChain={networkChain}
          cowrieWFee={cowrieWFee}
          cowrieWFeePrice={cowrieWFeePrice}
          cowrieTokenPrice={cowrieTokenPrice}
          setTokenPriceCowrieFee={setTokenPriceCowrieFee}
          oneDollarToZar={oneDollarToZar}
          pancakeCowrielivePrice={pancakeCowrielivePrice}
          setFeesCowrieWithdrawal={setFeesCowrieWithdrawal}
        />
      )}
      {activeStep === 1 && (
        <ReceiverDetailsReview
          next={next}
          prev={prev}
          cowrieWFee={cowrieWFee}
          cowrieAddress={cowrieAddress}
          totalCowrieAmount={totalCowrieAmount}
          reciverName={reciverName}
          token={token}
          cowrieTokenPrice={cowrieTokenPrice}
          tokenPriceCowrieFee={tokenPriceCowrieFee}
          setEmailSaved={setEmailSaved}
          feesCowrieWithdrawal={feesCowrieWithdrawal}
        />
      )}
      {activeStep === 2 && (
        <OTPConfermation
          next={next}
          prev={prev}
          token={token}
          cowrieAddress={cowrieAddress}
          totalCowrieAmount={totalCowrieAmount}
          setCount={setCount}
          count={count}
          otpSent={otpSent}
          setOptSent={setOptSent}
          networkChain={networkChain}
          emailSaved={emailSaved}
          oneDollarToZar={oneDollarToZar}
          pancakeCowrielivePrice={pancakeCowrielivePrice}
        />
      )}
      {activeStep === 3 && (
        <SendComplete
          next={next}
          prev={prev}
          cowrieAddress={cowrieAddress}
          totalCowrieAmount={totalCowrieAmount}
          reciverName={reciverName}
          cowrieWFee={cowrieWFee}
          tokenPriceCowrieFee={tokenPriceCowrieFee}
          feesCowrieWithdrawal={feesCowrieWithdrawal}
        />
      )}
      {activeStep === 4 && (
        <AddressBook
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          walletAddressBook={walletAddressBook}
          setCowrieAddress={setCowrieAddress}
          setTotalCowrieAmount={setTotalCowrieAmount}
          setReciverName={setReciverName}
          showEditModal={showEditModal}
          setEditUser={setEditUser}
          setShowEditModal={setShowEditModal}
          editUser={editUser}
          token={token}
          setWalletAddressBook={setWalletAddressBook}
        />
      )}
      {activeStep === 5 && (
        <AddNewAddress
          setActiveStep={setActiveStep}
          setQrModal={setQrModal}
          cowrieNewAddress={cowrieNewAddress}
          setCowrieNewAddress={setCowrieNewAddress}
          newName={newName}
          setNewName={setNewName}
          token={token}
          setWalletAddressBook={setWalletAddressBook}
        />
      )}
      <Footer />
    </>
  );
}

function ReceiverDetailsComponent({
  availableCowrie,
  next,
  activeStep,
  setActiveStep,
  setQrModal,
  cowrieAddress,
  totalCowrieAmount,
  setCowrieAddress,
  setTotalCowrieAmount,
  errorMessage,
  setErrorMessage,
  setNetworkChain,
  networkChain,
  cowrieWFee,
  setCowrieWFee,
  cowrieTokenPrice,
  cowrieWFeePrice,
  setTokenPriceCowrieFee,
  oneDollarToZar,
  pancakeCowrielivePrice,
  setFeesCowrieWithdrawal,
}) {
  // WAValidator.validate()
  // const handleInput = ()=>{
  //   if(WAValidator.validate( cowrieAddress ,'ETH')){

  //   }
  // }

  useEffect(() => {
    try {
      const web3 = new Web3();
      web3.utils.toChecksumAddress(cowrieAddress);
      // console.log("true");
      setErrorMessage(false);
    } catch (e) {
      // console.log("false");
      setErrorMessage(true);
    }
  }, [cowrieAddress]);

  useEffect(() => {
    setTokenPriceCowrieFee(
      parseFloat(
        cowrieWFee /
          (parseFloat(oneDollarToZar).toFixed(2) *
            parseFloat(pancakeCowrielivePrice).toFixed(3))
      ).toFixed(2)
    );
  }, [networkChain]);

  // const isValidAddress = (adr) => {
  //   try {
  //     const web3 = new Web3();
  //     web3.utils.toChecksumAddress(adr);
  //     console.log("true");
  //     setErrorMessage(false);
  //   } catch (e) {
  //     console.log("false");
  //     setErrorMessage(true);
  //   }
  // };

  let NetworkData = [
    { value: "erc20", name: "ERC20" },
    { value: "bep20", name: "BEP20" },
    // { value: "bsc", name: "BEP20" },
  ];
  console.log("cowrieWFeePrice", cowrieWFeePrice);

  return (
    <>
      <section className="sec buy-sec set-addr-sec" style={{ padding: "0" }}>
        <div className="setting-head page-banner-new">
          <h3 className="send-Heading-Black">Send COWRIE</h3>
          <p className="yellow-p-send">
            {" "}
            {parseFloat(availableCowrie).toFixed(2)} COWRIE available
          </p>
        </div>
        <div className="container">
          <div className="setting-box">
            <div className="setting-list">
              <div className="sent-c-col">
                <div className="setting-head page-banner-new-two">
                  {/* <span className="back-btn" onClick={history.goBack}>
                    <i className="fas fa-chevron-left"></i>
                  </span> */}
                  <h3 className="send-Heading-Black">Send COWRIE</h3>
                  <p className="yellow-p-send">
                    {parseFloat(availableCowrie).toFixed(2)} COWRIE available
                  </p>
                </div>
                <div className="buy-con flex-wrap">
                  <div className="set-addr">
                    <label>Network Chain</label>
                    <select
                      className="user-name"
                      onChange={(e) => {
                        setNetworkChain(e.target.value);
                        setCowrieWFee(cowrieWFeePrice[e.target.value]);
                        // setTokenPriceCowrieFee(
                        //   // cowrieWFeePrice[e.target.value] / (oneDollarToZar * pancakeCowrielivePrice)
                        //   parseFloat(cowrieWFee / (parseFloat(oneDollarToZar).toFixed(2) * parseFloat(pancakeCowrielivePrice).toFixed(3))).toFixed(
                        //     2
                        //   )
                        // );
                        // setFeesCowrieWithdrawal()
                      }}
                    >
                      <option value=""> Select Network Chain</option>
                      {NetworkData.map((d, i) => {
                        return (
                          <option
                            value={d.value}
                            selected={networkChain === d?.value}
                          >
                            {d.name}
                          </option>
                        );
                      })}
                      {/* <option value="erc20">ERC20</option> */}
                      {/* <option value="trc20">TRC20</option> 
                      <option value="bep20">BEP20(BSC)</option> 
                      <option value="heco">HECO</option> 
                      <option value="matic">MATIC</option>  */}
                    </select>
                    <span className="select-down">
                      <img src="assets/images/arrow-down.png" alt="" />
                    </span>
                  </div>
                  <div className="set-addr">
                    <label>COWRIE Address</label>
                    <input
                      style={{}}
                      type="text"
                      placeholder="Enter or choose a COWRIE address"
                      value={cowrieAddress}
                      onChange={(e) => {
                        setCowrieAddress(e.target.value);
                      }}
                    />
                    <div className="custom-list">
                      <button
                        onClick={() => {
                          setActiveStep(4);
                        }}
                      >
                        <img src="assets/images/con-list.png" alt="" />
                      </button>
                      <button
                        onClick={() => {
                          setQrModal(true);
                          // console.log("clldkjldksfjlajsdfjldfj");
                        }}
                      >
                        <img src="assets/images/qr.png" alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="set-addr">
                    <label>Amount</label>
                    <input
                      type="text"
                      placeholder="Enter amounts"
                      value={totalCowrieAmount}
                      onChange={(e) => {
                        setTotalCowrieAmount(e.target.value);
                      }}
                    />
                    <button
                      className="custom-btn-sm"
                      onClick={() => {
                        setTotalCowrieAmount(availableCowrie);
                      }}
                    >
                      SEND ALL
                    </button>
                    <p className="min-max">
                      <span>
                        Min <strong>30 COWRIE</strong>
                      </span>{" "}
                      {networkChain === "" ? (
                        ""
                      ) : (
                        <span className="mx-4">
                          Fee{" "}
                          <strong>
                            {parseFloat(
                              cowrieWFee /
                                (parseFloat(oneDollarToZar).toFixed(2) *
                                  parseFloat(pancakeCowrielivePrice).toFixed(3))
                            ).toFixed(2)}{" "}
                            COWRIE Approx
                            {/* parseFloat(*fees*.toFixed(2) */}
                          </strong>
                        </span>
                      )}
                    </p>
                  </div>
                  {/* <p className="approx">Approx. 3,000.00 ZAR</p> */}

                  {errorMessage && (
                    <p className="error-text">
                      Please Enter Valid Wallet Address.
                    </p>
                  )}

                  {networkChain === "" ? (
                    <p className="error-text">Please Select Network Chain</p>
                  ) : (
                    ""
                  )}

                  {totalCowrieAmount <= availableCowrie ? (
                    ""
                  ) : (
                    <p className="error-text">Insufficient COWRIE</p>
                  )}

                  {totalCowrieAmount >= 30 ? (
                    ""
                  ) : (
                    <p className="error-text">
                      You need to send minimum 30 COWRIE
                    </p>
                  )}

                  <div className="set-addr">
                    {totalCowrieAmount >= 30 &&
                    !errorMessage &&
                    !networkChain == "" &&
                    totalCowrieAmount <= availableCowrie &&
                    totalCowrieAmount >= cowrieWFee ? (
                      <button
                        className="custom-btn"
                        onClick={() => {
                          next();
                        }}
                      >
                        PROCEED
                      </button>
                    ) : (
                      <button className="custom-btn bg-secondary">
                        PROCEED
                      </button>
                    )}
                  </div>

                  <div className="set-addr">
                    <p>
                      Important: Only send to a COWRIE address. Withdrawals to
                      any other token or coin address may result in permanent
                      loss of your funds.
                    </p>
                    <p>
                      Once you have submitted your request, we will send a
                      confirmation email with a confirmation link.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function ReceiverDetailsReview({
  next,
  prev,
  cowrieAddress,
  totalCowrieAmount,
  reciverName,
  token,
  cowrieWFee,
  cowrieTokenPrice,
  tokenPriceCowrieFee,
  emailSaved,
  setEmailSaved,
}) {
  console.log("cowrieTokenPrice", cowrieTokenPrice);

  function callOTP() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };

    const phone = localStorage.getItem("phone");
    let em = localStorage.getItem("email");
    setEmailSaved(em);
    axios
      .post(
        `${config.URL}/trade/get-otp-cowrie-withdrawal/`,
        { phone: phone },
        { headers: headers }
      )
      .then(({ data }) => {
        // console.log("data: ", data);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        // setIsLoading(false);
      });
    //get-otp-cowrie-withdrawal/
  }

  return (
    <>
      <section className="sec buy-sec set-review-sec">
        <div className="container">
          <div className="setting-box">
            <div className="setting-list">
              <div className="setting-head">
                <span
                  className="back-btn text-dark"
                  onClick={() => {
                    prev();
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </span>
                <h3 className="center-heading">Sending Review</h3>
              </div>
              <div className="sent-c-col">
                <div className="setting-col s-review-col">
                  <div className="col-con">
                    <span>YOU’RE SENDING</span>
                    <h4>
                      COWRIE{" "}
                      {parseFloat(
                        totalCowrieAmount - tokenPriceCowrieFee
                      ).toFixed(2)}{" "}
                      Approx
                    </h4>
                  </div>
                </div>
                <div className="setting-col s-review-col">
                  <div className="col-con">
                    <span>TO</span>
                    <h4>
                      {reciverName.length === 0 ? cowrieAddress : reciverName}
                    </h4>
                  </div>
                </div>
                <div className="setting-col s-review-col">
                  <div className="col-con">
                    <span>FEE CHARGE</span>
                    <h4>COWRIE {tokenPriceCowrieFee} Approx</h4>
                  </div>
                </div>
                <div className="add-new-addr">
                  <button
                    className="custom-btn"
                    onClick={() => {
                      callOTP();
                      next();
                    }}
                  >
                    CONFIRM
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function OTPConfermation({
  next,
  prev,
  token,
  totalCowrieAmount,
  cowrieAddress,
  networkChain,
  setCount,
  setOptSent,
  count,
  otpSent,
  emailSaved,
}) {
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isEmailNull, setIsEmailNull] = useState(false);
  const [error, setError] = useState(false);
  const [myotp, setMyotp] = useState("");
  const [subActive, setSubActive] = useState(0);

  let isTwoEnable = localStorage.getItem("is_two_factor_enabled");

  const time = (
    <div className="p-0 m-0">
      RESEND OTP in 00:{" "}
      {count.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}{" "}
      SEC
    </div>
  );

  useEffect(() => {
    setOptSent(true);
    const myInterval = setInterval(() => {
      //  console.log(count);
      setCount(count--);
      if (count === 0) {
        clearInterval(myInterval);
        setCount(179);
        setOptSent(false);
      }
    }, 1000);
  }, []);

  function callOTP() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };

    console.log("message email", typeof emailSaved, "tmmmlm", emailSaved);
    if (emailSaved === "null") {
      setIsEmailNull(true);
      setTimeout(() => {
        setIsEmailNull(false);
      }, 4000);
    } else {
      setIsOtpVerified(true);
      setTimeout(() => {
        setIsOtpVerified(false);
      }, 4000);
      const email = localStorage.getItem("email");
      axios
        .post(
          `${config.URL}/trade/get-otp-cowrie-withdrawal/`,
          { email: email },
          { headers: headers }
        )
        .then(({ data }) => {
          // console.log("data: ", data);
          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          setError(true);
          // setIsLoading(false);
        });
    }
    //get-otp-cowrie-withdrawal/
  }

  const sendOTP = () => {
    setOptSent(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };

    const phone = localStorage.getItem("phone");
    setIsOtpVerified(true);
    setTimeout(() => {
      setIsOtpVerified(false);
    }, 4000);
    axios
      .post(
        `${config.URL}/trade/get-otp-cowrie-withdrawal/`,
        { phone: phone },
        { headers: headers }
      )
      .then(({ data }) => {
        // console.log("data: ", data);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        // setIsLoading(false);
      });

    const myInterval = setInterval(() => {
      //  console.log(count);
      setCount(count--);
      if (count === 0) {
        clearInterval(myInterval);
        setCount(179);
        setOptSent(false);
      }
    }, 1000);
  };

  function verify_transaction() {
    // console.log("gghhhjf", Number(enteredOTP.join().replace(/\,/g,"")));

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };

    const transactionData = {
      otp: Number(myotp),
      amount: Number(totalCowrieAmount),
      wallet_address: cowrieAddress,
      network_chain: networkChain,
    };
    // console.log("transactionData", transactionData);

    axios
      .post(`${config.URL}/trade/cowrie-withdrawal-view/`, transactionData, {
        headers: headers,
      })
      .then(({ data }) => {
        // console.log("data: ", data);
        next();
      })
      .catch((error) => {
        console.log("error: ", error);
        // setIsLoading(false);
        if (error.response.data.message === "Otp not Verified.") {
          setError(true);
        }
      });
  }

  // const handleChange = (e) => {
  //   console.log("e", e);
  //   console.log("otp", enteredOTP);

  //   const { maxLength, name } = e.target;
  //   const value = e.target.value;
  //   const [fieldName, fieldIndex] = name.split("-");

  //   let fieldIntIndex = parseInt(fieldIndex, 10);
  //   // Check if no of char in field == maxlength
  //   if (value.length >= maxLength) {
  //     // console.log("value", value);
  //     // It should not be last input field
  //     if (fieldIntIndex < 7) {
  //       setEnteredOTP((old) => {
  //         return [...old, value];
  //       });
  //       // Get the next input field using it's name
  //       const nextfield = document.querySelector(
  //         `input[name=field-${fieldIntIndex + 1}]`
  //       );

  //       // If found, focus the next field
  //       if (nextfield !== null) {
  //         nextfield.focus();
  //       }
  //     }
  //   }
  // };

  return (
    <>
      <InfoModal
        show={isOtpVerified}
        setShow={setIsOtpVerified}
        title="OTP Sent"
        body={`You will soon receive Your OTP`}
      />

      <InfoModal
        show={isEmailNull}
        setShow={isEmailNull}
        title="Email is not registered"
        body={`Please register your email in profile section to get OTP on Email`}
      />

      <div className="setting-head page-banner-new">
        <h3 className="send-Heading-Black" style={{ padding: "30px" }}>
          {subActive === 0 ? "OTP Confirmation" : "Two Factor Authentication"}
        </h3>
      </div>

      {subActive === 0 && (
        <section className="sec buy-sec set-otp-secs">
          <div className="container">
            <div className="setting-box">
              <div className="setting-list">
                <div className="setting-head page-banner-new-two">
                  <span
                    className="back-btn text-dark"
                    onClick={() => {
                      prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <h3 className="center-heading">OTP Confirmation</h3>
                </div>
                <div className="sent-c-col">
                  <div className="buy-con flex-wrap">
                    <div className="set-addr text-center">
                      <p>
                        We have sent a one-time pin (OTP) to your registered
                        Phone Number
                      </p>
                      <p>Please enter it below.</p>
                      <p className="otp-sms">
                        Note: OTP’s may take a few minutes to arrive in your
                        inbox.
                      </p>
                    </div>
                    <div className="set-addr d-flex fill-otp otp_items">
                      <div className="m-auto">
                        <OtpInput
                          style={{ width: "10px" }}
                          value={myotp}
                          onChange={(value) => {
                            // debugger;
                            setMyotp(value);
                          }}
                          numInputs={6}
                          separator={<span className="m-1">{}</span>}
                        />
                      </div>
                    </div>

                    <div className="set-addr text-center mt-3">
                      <p className="text-center">
                        {otpSent ? (
                          <strong className="text-secondary ">
                            Resend OTP
                          </strong>
                        ) : (
                          <strong
                            onClick={() => {
                              sendOTP();
                            }}
                          >
                            Resend OTP
                            <br />
                          </strong>
                        )}
                        {otpSent ? time : ""}
                        <br />
                        OR
                        <br />
                        {/* <button>
                          <strong
                            className="mt-3"
                            onClick={() => {
                              callOTP();
                            }}
                          >
                            Send OTP AS Mail instead
                          </strong>
                        </button> */}
                        <a
                          style={{ color: "blue" }}
                          className="mt-4"
                          onClick={() => {
                            callOTP();
                          }}
                        >
                          Send OTP AS Mail instead
                        </a>
                      </p>
                      {error && <p className="error-text">Invalid OTP</p>}
                    </div>
                    <div className="set-user">
                      {myotp.length === 6 ? (
                        <button
                          className="custom-btn"
                          onClick={() => {
                            console.log(
                              "skjlaskjdflj",
                              isTwoEnable,
                              typeof isTwoEnable
                            );
                            if (isTwoEnable === "true") {
                              setSubActive((pre) => pre + 1);
                            } else {
                              verify_transaction();
                            }
                          }}
                        >
                          NEXT
                        </button>
                      ) : (
                        <button className="custom-btn">NEXT</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {subActive === 1 && (
        <TwoFAAfterOTP
          next={next}
          myotp={myotp}
          totalCowrieAmount={totalCowrieAmount}
          cowrieAddress={cowrieAddress}
          networkChain={networkChain}
        />
      )}
    </>
  );
}

function SendComplete({
  cowrieAddress,
  totalCowrieAmount,
  reciverName,
  cowrieWFee,
  tokenPriceCowrieFee,
}) {
  return (
    <>
      <section className="sec buy-sec set-complete-sec">
        <div className="container">
          <div className="setting-box">
            <div className="setting-list">
              <div className="sent-c-col">
                <div className="send-complete-img">
                  <img src="assets/images/send-complete.png" alt="" />
                </div>
                <div className="buy-col text-center">
                  <h3>Sending Complete</h3>

                  {reciverName === "" ? (
                    <div>
                      <p>
                        You have successfully sent{" "}
                        {parseFloat(
                          totalCowrieAmount - tokenPriceCowrieFee
                        ).toFixed(2)}{" "}
                        approx COWRIE to {cowrieAddress}.
                      </p>
                      <p>
                        Please allow up to 20 minutes for the transaction to
                        reflect in {cowrieAddress}’s wallet.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        You have successfully sent{" "}
                        {parseFloat(
                          totalCowrieAmount - tokenPriceCowrieFee
                        ).toFixed(2)}{" "}
                        approx COWRIE to {reciverName}.
                      </p>
                      <p>
                        Please allow up to 20 minutes for the transaction to
                        reflect in {reciverName}’s wallet.
                      </p>
                    </div>
                  )}
                </div>
                <div className="login-col">
                  <div className="btn-row">
                    <div className="buy-btn">
                      <Link to="/">
                        <span className="custom-btn btn-full">HOME</span>
                      </Link>
                    </div>
                    <div className="buy-btn">
                      <Link to="/wallet">
                        <span className="custom-btn btn-full">WALLET</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function TwoFAAfterOTP({
  next,
  totalCowrieAmount,
  cowrieAddress,
  networkChain,
  myotp,
}) {
  const { token, setToken } = useToken();

  const [errorMessage, setErrorMessage] = useState("");

  const [twoFactorOTP, setTwoFactorOTP] = useState("");

  function verify_transaction() {
    // console.log("gghhhjf", Number(enteredOTP.join().replace(/\,/g,"")));

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };

    const transactionData = {
      otp: Number(myotp),
      code: twoFactorOTP,
      amount: Number(totalCowrieAmount),
      wallet_address: cowrieAddress,
      network_chain: networkChain,
    };
    // console.log("transactionData", transactionData);

    axios
      .post(`${config.URL}/trade/cowrie-withdrawal-view/`, transactionData, {
        headers: headers,
      })
      .then(({ data }) => {
        // console.log("data: ", data);
        next();
      })
      .catch((error) => {
        console.log("error: ", error);
        // setIsLoading(false);
        if (error.response.data.message === "Otp not Verified.") {
          setErrorMessage("You have entered wrong OTP");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        } else if (
          error.response.data.message === "Field Error: Incorrect 2FA Code"
        ) {
          setErrorMessage("You have entered Incorrect 2FA Code");
        }
      });
  }

  return (
    <>
      {/* <Header activeTab="login" /> */}
      <div className="setBody">
        <main className="cowrie_code" id="cowrie_codeId">
          <section className="sec wallet-sec buy-sec cowrie_codeSection">
            <div className="container">
              <div className="cowrie_codeInner_container mx-auto">
                <div className="cowrie_codeInner">
                  <div
                    className="cowrie_codeInner_sets "
                    style={{ textAlign: "center" }}
                  >
                    <div className="cowrie_codeHeader">
                      <h3 className="wsuccess">Two Factor Authentication</h3>
                    </div>

                    <div className="code_lines">
                      <p className="withdLIne ">
                        Enter 6 digit code from the Google Authenticator app on
                        your phone.
                      </p>
                    </div>
                    <div className="code_lines">
                      <input
                        type="text"
                        placeholder="Code"
                        maxLength={6}
                        onChange={(e) => {
                          setTwoFactorOTP(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className=""
                      style={{ padding: "18px", textAlign: "start" }}
                    >
                      <p
                        className="feild-error-y text-center"
                        style={{ textAlign: "start", fontSize: "16px" }}
                      >
                        {errorMessage}
                      </p>
                    </div>

                    {twoFactorOTP.length === 6 ? (
                      <div className="float-left">
                        <button
                          className="custom-btn button_custom btn-sm"
                          onClick={() => {
                            verify_transaction();
                          }}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <div className="float-left">
                        <button className="custom-btn button_custom bg-secondary">
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                  {/* <div className="cowrie_codeInner_sets">
                    {twoFactorOTP.length === 6 ? (
                      <div className="cowrie_codeInner_sets__button">
                        <button
                          className="custom-btn button_custom"
                          onClick={() => {
                            test2FAotp();
                          }}
                        >
                          Disable 2FA
                        </button>
                      </div>
                    ) : (
                      <div className="cowrie_codeInner_sets__button">
                        <button className="custom-btn button_custom bg-secondary">
                          Disable 2FA
                        </button>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

function AddressBook({
  setActiveStep,
  walletAddressBook,
  setCowrieAddress,
  setTotalCowrieAmount,
  setReciverName,
  showEditModal,
  setShowEditModal,
  setEditUser,
  setWalletAddressBook,
  editUser,
  token,
}) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };

  async function deleteItem(id) {
    console.log("running", id, headers);
    await axios
      .delete(`${config.URL}/trade/wallet-address-book-update-delete/${id}/`, {
        headers: headers,
      })
      .then(({ data }) => {
        setWalletAddressBook(walletAddressBook.filter((a) => id !== a.id));
      })
      .catch(console.log);
  }

  return (
    <>
      <div className="setting-head page-banner-new">
        <h3 className="send-Heading-Black" style={{ padding: "30px" }}>
          Wallet Address Book
        </h3>
      </div>
      <section className="sec buy-sec set-addr-sec">
        <div className="container">
          <div className="setting-box">
            <div className="setting-list">
              <div className="setting-head page-banner-new-two">
                <span
                  className="back-btn text-dark"
                  onClick={() => {
                    setActiveStep(0);
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </span>
                <h3 className="center-heading">Wallet Address Book</h3>
              </div>
              <div className="sent-c-col">
                {walletAddressBook?.map((info, i) => {
                  return (
                    <div key={i}>
                      <div className="setting-col flex_control">
                        <div className="col-icon icon_items">
                          <img src="assets/images/w-logo-icon.png" alt="" />
                        </div>
                        <div
                          className="col-con"
                          onClick={() => {
                            setCowrieAddress(info.wallet_address);
                            setReciverName(info.name);
                            setActiveStep(0);
                          }}
                        >
                          <h4>{info.name}</h4>
                          <span>{info.wallet_address}</span>
                        </div>
                        <div className="btnItemMods">
                          <div className="">
                            <button
                              onClick={() => {
                                setEditUser(info);
                                setShowEditModal(true);
                              }}
                              className="btn btn-sm btn-outline-dark"
                            >
                              {/* Edit */}
                              <i className="fa-solid fa-pen-to-square"></i>
                            </button>
                          </div>
                          <div className="">
                            <button
                              className="btn btn-sm btn-outline-dark"
                              onClick={() => {
                                console.log("info . id", info.id);
                                deleteItem(info.id);
                              }}
                            >
                              {/* Delete */}
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="add-new-addr">
                  <button
                    className="custom-btn"
                    onClick={() => {
                      setActiveStep(5);
                    }}
                  >
                    ADD A NEW ADDRESS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function EditAddressModal({
  show,
  setShow,
  walletAddress,
  name,
  setQrModal,
  walletAddressBook,
  setWalletAddressBook,
  editUser,
  setEditUser,
  token,
}) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const currencyOption = [{ name: "Cowrie", value: "cowrie" }];
  const [addressError, setAddressError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    try {
      const web3 = new Web3();
      web3.utils.toChecksumAddress(editUser.wallet_address);
      // console.log("true");
      setAddressError(false);
    } catch (e) {
      // console.log("false");
      setAddressError(true);
    }
  }, [editUser]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };

  async function updateItem(id) {
    if (
      editUser.name === "" ||
      editUser.currency === "" ||
      editUser.wallet_address === ""
    ) {
      setErrorMessage("Above feilds are required, Please fill the details.");
    } else {
      console.log("running", id, headers);
      await axios
        .put(
          `${config.URL}/trade/wallet-address-book-update-delete/${id}/`,
          editUser,
          {
            headers: headers,
          }
        )
        .then(({ data }) => {
          console.log("update api working", data);
          setWalletAddressBook(() => {
            let addBook = walletAddressBook.filter((a) => id !== a.id);
            return [...addBook, editUser];
          });
          setShow(false);
        })
        .catch(console.log);
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal-header modal-headerBlock">
        <h5 className="modal-title modal-headerTitle" id="exampleModalLabel">
          Edit Wallet Address
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modalBody">
        <div className="sent-c-col">
          <div className="buy-con flex-wrap">
            <div className="set-addr">
              <label className="popup__edit">Name</label>
              <input
                type="text"
                className="user-name"
                placeholder="Enter a name for this wallet address"
                value={editUser.name}
                onChange={(e) => {
                  setEditUser((pre) => {
                    return { ...pre, name: e.target.value };
                  });
                }}
              />
            </div>
            <div className="set-addr">
              <label className="popup__edit">Currency</label>
              <select
                className="user-name"
                onChange={(e) => {
                  setEditUser((pre) => {
                    return { ...pre, currency: e.target.value };
                  });
                }}
              >
                <option value="">Select Currency</option>
                {currencyOption.map((d, i) => {
                  return (
                    <option
                      value={d.value}
                      selected={editUser.currency === d?.value}
                    >
                      {d.name}
                    </option>
                  );
                })}
                {/* <option value="1">Ether (ETH)</option>
                      <option value="2">Bitcoin (BTC)</option> */}
              </select>
              <span className="select-down">
                <img
                  src="
                      assets/images/arrow-down.png"
                  alt=""
                />
              </span>
            </div>
            <div className="set-addr">
              <label className="popup__edit">Wallet Address</label>
              <input
                type="text"
                className="user-name"
                placeholder="Enter wallet address"
                value={editUser.wallet_address}
                onChange={(e) => {
                  setEditUser((pre) => {
                    console.log(e.target.value);
                    return { ...pre, wallet_address: e.target.value };
                  });
                }}
              />
              <div className="custom-list">
                <button
                  onClick={() => {
                    setQrModal(true);
                    //console.log("clldkjldksfjlajsdfjldfj");
                  }}
                >
                  <img src="assets/images/qr.png" alt="" />
                </button>
              </div>
            </div>
            {/* console.log("editUser", editUser) */}

            {errorMessage === "" ? (
              ""
            ) : (
              <p className="text-danger  mx-auto my-1">{errorMessage}</p>
            )}

            {addressError === false ? (
              ""
            ) : (
              <p className="text-danger  mx-auto my-1">
                Please Enter A Valid Wallet Address.
              </p>
            )}
            <div className="set-user">
              {addressError === true ? (
                <button className="custom-btn">UPDATE ADDRESS DETAILS</button>
              ) : (
                <button
                  className="custom-btn"
                  onClick={() => {
                    updateItem(editUser.id);
                  }}
                >
                  UPDATE ADDRESS DETAILS
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function AddNewAddress({
  setActiveStep,
  setQrModal,
  cowrieNewAddress,
  setCowrieNewAddress,
  newName,
  setNewName,
  token,
  setWalletAddressBook,
}) {
  const [errorMessage, setErrorMessage] = useState(false);
  const [addressError, setAddressError] = useState(true);
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    try {
      const web3 = new Web3();
      web3.utils.toChecksumAddress(cowrieNewAddress);
      // console.log("true");
      setAddressError(false);
    } catch (e) {
      // console.log("false");
      setAddressError(true);
    }
  }, [cowrieNewAddress]);

  function submitInfo() {
    // console.log("......................");
    if (newName === "" || currency === "" || cowrieNewAddress === "") {
      setErrorMessage("Above feilds are required, Please fill the details.");
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.access,
      };
      // console.log("headers", headers);

      const NewUserData = {
        name: newName,
        currency: currency,
        wallet_address: cowrieNewAddress,
      };
      // console.log("NewUserData", NewUserData);

      axios
        .post(`${config.URL}/trade/wallet-address-book-view/`, NewUserData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response);
          setWalletAddressBook((old) => {
            return [
              ...old,
              { ...NewUserData, id: response.data["Address id"] },
            ];
          });
          setNewName("");
          setCurrency("");
          setCowrieNewAddress("");
          setActiveStep(4);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      <div className="setting-head page-banner-new">
        <h3 className="send-Heading-Black" style={{ padding: "30px" }}>
          Add a New Address
        </h3>
      </div>
      <section className="sec buy-sec set-addr-secs">
        <div className="container">
          <div className="setting-box">
            <div className="setting-list">
              <div className="setting-head page-banner-new-two">
                <span
                  className="back-btn text-dark"
                  onClick={() => {
                    setActiveStep(4);
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </span>
                <h3 className="center-heading">Add a New Address</h3>
              </div>
              <div className="sent-c-col">
                <div className="buy-con flex-wrap">
                  <div className="set-addr">
                    <label>Name</label>
                    <input
                      type="text"
                      className="user-name"
                      placeholder="Enter a name for this wallet address"
                      value={newName}
                      onChange={(e) => {
                        setNewName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="set-addr">
                    <label>Currency</label>
                    <select
                      className="user-name"
                      onChange={(e) => {
                        setCurrency(e.target.value);
                      }}
                    >
                      <option value="">Select Currency</option>
                      <option value="cowrie">Cowrie (COWRIE)</option>
                      {/* <option value="1">Ether (ETH)</option>
                      <option value="2">Bitcoin (BTC)</option> */}
                    </select>
                    <span className="select-down">
                      <img
                        src="
                      assets/images/arrow-down.png"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="set-addr">
                    <label>Wallet Address</label>
                    <input
                      type="text"
                      className="user-name"
                      placeholder="Enter wallet address"
                      value={cowrieNewAddress}
                      onChange={(e) => {
                        if (e.target.value.search("ethereum:") != -1) {
                          var split_value = e.target.value.split(":")[1];
                          setCowrieNewAddress(split_value);
                        } else {
                          // setCowrieNewAddress("split value: ", split_value);
                          setCowrieNewAddress(e.target.value);
                        }
                      }}
                    />
                    <div className="custom-list">
                      <button
                        onClick={() => {
                          setQrModal(true);
                          // console.log("clldkjldksfjlajsdfjldfj");
                        }}
                      >
                        <img src="assets/images/qr.png" alt="" />
                      </button>
                    </div>
                  </div>
                  {errorMessage === "" ? (
                    ""
                  ) : (
                    <p className="text-danger  mx-auto my-1">{errorMessage}</p>
                  )}
                  {addressError === false ? (
                    ""
                  ) : (
                    <p className="text-danger  mx-auto my-1">
                      Please Enter A Valid Wallet Address.
                    </p>
                  )}
                  <div className="set-user">
                    {addressError === true ? (
                      <button className="custom-btn btn-secondary">
                        SAVE ADDRESS
                      </button>
                    ) : (
                      <button
                        className="custom-btn"
                        onClick={() => {
                          submitInfo();
                        }}
                      >
                        SAVE ADDRESS
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const QRModal = ({
  show,
  setShow,
  data,
  setData,
  setCowrieAddress,
  activeStep,
  setCowrieNewAddress,
  setReciverName,
  setNewName,
  showEditModal,
  setEditUser,
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal-header modal-headerBlock">
        <h5 className="modal-title modal-headerTitle" id="exampleModalLabel">
          QR
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modalBody">
        <Qrcode
          setReciverName={setReciverName}
          setData={setData}
          setShow={setShow}
          setCowrieAddress={setCowrieAddress}
          activeStep={activeStep}
          setNewName={setNewName}
          setCowrieNewAddress={setCowrieNewAddress}
          showEditModal={showEditModal}
          setEditUser={setEditUser}
        />
      </div>
    </Modal>
  );
};

function Qrcode({
  // data,
  setData,
  setShow,
  setCowrieAddress,
  activeStep,
  setCowrieNewAddress,
  setReciverName,
  setNewName,
  showEditModal,
  setEditUser,
}) {
  return (
    <>
      <QrReader
        constraints={{
          facingMode: "environment",
        }}
        onResult={(result, error) => {
          if (!!result) {
            // setData(result?.text);
            if (activeStep == 0) {
              // setCowrieAddress(result?.text?.split(",")[1]);
              setCowrieAddress(result?.text);
              setShow(false);
            } else {
              if (showEditModal === true) {
                setEditUser(result?.text);
                setShow(false);
              } else {
                setCowrieNewAddress(result?.text);
                //   setData(result?.text);
                setShow(false);
              }
            }
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: "100%" }}
      />
      {/* <p>{data}</p> */}
    </>
  );
}
