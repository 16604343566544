import logoExchange from "./assest/images/logo-exchange.png";
import picUser from "./assest/images/pic-user.jpg";
import { useCallback, useMemo, useRef, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Link, useLocation, useHistory } from "react-router-dom";
import config from "../../config";
import axios from "axios";
import useToken from "../useToken";
import Jschart from "../jschart/Jschart";
import InfoModal from "../InfoModal";
import Header from "../Header";

const Chart = () => {
  const { token, setToken } = useToken();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const pair = query.get("pair") || "eth_usdt";
  const exchange = query.get("exchange") || "Lbank";
  const socketUrl = "wss://www.lbkex.net/ws/V2/";
  const history = useHistory();

  const [buyPrice, setBuyPrice] = useState({});
  const [buyAmount, setAmount] = useState(0);
  const [buyTotal, setTotal] = useState(0);

  const [sellPrice, setSellPrice] = useState({});
  const [sellAmount, setSellAmount] = useState(0);
  const [sellTotal, setSellTotal] = useState(0);

  const [openOrdersList, setOpenOrdersList] = useState([]);
  const [orderHistoryList, setOrderHistoryList] = useState([]);
  const [orderListTab, setOrderListTab] = useState("openorder");
  const [selectedSymbol, setSelectedSymbol] = useState();

  const [confirmBuy, setConfirmBuy] = useState(false);
  const [confirmBuyError, setConfirmBuyError] = useState(false);
  const [buyErrorMessage, setBuyErrorMessage] = useState("");

  const [confirmSell, setConfirmSell] = useState(false);
  const [confirmSellError, setConfirmSellError] = useState(false);
  const [sellErrorMessage, setSellErrorMessage] = useState("");

  const [zeroError, setZeroError] = useState(false);

  const [asks, setAsks] = useState([]);
  const [bids, setBids] = useState([]);

  const [preValue, setPreValue] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);

  const [orderCounter, setOrderCounter] = useState(1);
  const [orderHistoryCounter, setOrderHistoryCounter] = useState(1);

  const [openOrderTotalCount, setOpenOrderTotalCount] = useState(0);
  const [orderHistoryTotalCount, setOrderHistoryTotalCount] = useState(0);

  const [data, setData] = useState({
    latest: "",
    usd: "",
    change: "",
    high: "",
    vol: "",
    low: "",
  });

  useEffect(() => {
    console.log("useEff for login");
    if (!token) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (buyPrice.buyPriceValue * buyAmount) {
      setTotal(buyPrice.buyPriceValue * buyAmount);
    } else {
      setTotal(0);
    }
  }, [buyPrice, buyAmount]);

  useEffect(() => {
    if (sellPrice.sellPriceValue * sellAmount) {
      setSellTotal(sellPrice.sellPriceValue * sellAmount);
      console.log("sellTotal", sellTotal);
    } else {
      setSellTotal(0);
    }
  }, [sellPrice, sellAmount]);

  // useEffect(() => {});

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useWebSocket(socketUrl);

  const messageHistory = useRef([]);

  messageHistory.current = useMemo(
    () => messageHistory.current.concat(lastJsonMessage ?? []),
    [lastJsonMessage]
  );

  const handleClickSendMessage = useCallback(() => {
    sendJsonMessage({
      action: "subscribe",
      subscribe: "depth",
      depth: "10",
      pair: pair,
    });
  }, [sendJsonMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    // if(connectionStatus==="closed"){
    handleClickSendMessage();
    // }
    //console.log("abhi===", connectionStatus);
  }, [connectionStatus]);

  const [marketList, setMarketList] = useState([]);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token.access,
  };
  useEffect(() => {
    axios
      .post(
        `${config.URL}/trade/fetch-market-list-view/`,
        { symbol: undefined },
        {
          headers: headers,
        }
      )
      .then((response) => {
        //  console.log("marketList response: ", response?.data?.data?.markets);
        setMarketList(response?.data?.data?.markets);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          // alert(error);
          console.log(error);
        }
      })
      .then(() => {});

    var head = document.getElementsByTagName("HEAD")[0];

    // Create new link Element
    var style_link_tag = document.createElement("link");
    style_link_tag.rel = "stylesheet";
    style_link_tag.type = "text/css";
    style_link_tag.href = "/assets/chart-css/style.css";
    // Append link element to HTML head
    head.appendChild(style_link_tag);

    var responsive_link_tag = document.createElement("link");
    responsive_link_tag.rel = "stylesheet";
    responsive_link_tag.type = "text/css";
    responsive_link_tag.href = "/assets/chart-css/responsive.css";
    // Append link element to HTML head
    head.appendChild(responsive_link_tag);
  }, []);

  const handleCancelOrder = (market, lbank_order_id) => {
    //  console.log("lbank_order_id: ", lbank_order_id);
    //  console.log("market: ", market);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    axios
      .post(
        `${config.URL}/trade/lbank-cancel-order-view/`,
        {
          market: market,
          order_id: lbank_order_id,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        //  console.log("handleCancelOrder response: ", response);
        setOpenOrdersList(
          openOrdersList.filter((a) => lbank_order_id !== a.lbank_order_id)
        );
        setOpenOrderTotalCount((last) => {
          return last - 1;
        });
        console.log("openOrdersList after delete one order", openOrdersList);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  useEffect(() => {
    //  console.log("marketList", marketList);
    if (marketList.length !== 0) {
      const mila = marketList.find(({ market }) => market === pair);
      setSelectedSymbol(mila);
      if (mila === undefined) {
        alert("The symbol you entered is not available");
        history.push("/chart");
      }
    }
  }, [marketList]);

  const handleBuy = () => {
    console.log("handlebuy, selltotal", buyTotal);
    if (buyTotal === 0) {
      setZeroError(true);
    } else {
      if (marketList.length !== 0) {
        const buySymbol = marketList.find(({ symbol }) => symbol === pair);
        console.log("buySymbol", buySymbol);
        console.log("this is buy handle ...");
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.access,
        };
        // console.log("headers: ", headers);
        axios
          .post(
            `${config.URL}/trade/lbank-order-view/`,
            {
              type: "buy",
              market: selectedSymbol?.id + "",
              price: buyPrice.buyPriceValue,
              quantity: buyAmount,
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            console.log("response: ", response);
            console.log("this is buy handle ... response");
            setConfirmBuy(true);
          })
          .catch(function (error) {
            console.log(error);
            setBuyErrorMessage(error);
            setConfirmBuyError(true);
          });
      }
    }
  };

  const handleSell = () => {
    if (sellTotal === 0) {
      setZeroError(true);
    } else {
      if (marketList.length !== 0) {
        // const buySymbol = marketList.find(({ symbol }) => symbol === pair);
        // console.log("buySymbol", buySymbol);
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.access,
        };
        // console.log("headers: ", headers);
        axios
          .post(
            `${config.URL}/trade/lbank-order-view/`,
            {
              type: "sell",
              market: selectedSymbol?.id + "",
              price: sellPrice.sellPriceValue,
              quantity: sellAmount,
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            console.log("response: ", response);
            console.log("this is sell handle ... response");
            setConfirmSell(true);
          })
          .catch(function (error) {
            setSellErrorMessage(error);
            setConfirmSellError(true);
          });
      }
    }
  };

  useEffect(() => {
    //  console.log("selectedSymbol: ", selectedSymbol);
    if (selectedSymbol) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.access,
      };
      axios
        .post(
          `${config.URL}/trade/open-order-list-view/?count=3&p=${orderCounter}`,
          { market: selectedSymbol.id },
          { headers }
        )
        .then((response) => {
          console.log("response1111: ", response);
          setOpenOrdersList(response?.data?.results?.data?.Orders);
          setOpenOrderTotalCount(response?.data?.count);
        })
        .catch((error) => {
          console.log("error1111: ", error);
        });
    }
  }, [selectedSymbol]);

  useEffect(() => {
    //  console.log("selectedSymbol: ", selectedSymbol);
    if (selectedSymbol) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.access,
      };
      axios
        .post(
          `${config.URL}/trade/open-order-list-view/?count=3&p=${orderCounter}`,
          { market: selectedSymbol.id },
          { headers }
        )
        .then((response) => {
          console.log("openOrdersList after LOAD MORE PRESSED", openOrdersList);
          console.log("response1111 BUTTON Press: ", response);
          setOpenOrdersList((last) => {
            return [...last, ...response?.data?.results?.data?.Orders];
          });
          setOpenOrderTotalCount(response?.data?.count);
        })
        .catch((error) => {
          console.log("error1111: ", error);
        });
    }
  }, [orderCounter]);
  // orderCounter

  useEffect(() => {
    if (selectedSymbol) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.access,
      };
      axios
        .post(
          `${config.URL}/trade/lbank-all-history-order-view/?count=3&p=${orderHistoryCounter}`,
          {
            market: selectedSymbol.id,
            // current_page: 1,
            // page_length: 20,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("lbank-all-history response: ", response);
          setOrderHistoryList(response?.data?.results?.data?.Orders);
          setOrderHistoryTotalCount(response?.data?.count);
        })
        .catch(function (error) {
          alert(error);
        });
    }
  }, [selectedSymbol]);

  useEffect(() => {
    if (selectedSymbol) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.access,
      };
      axios
        .post(
          `${config.URL}/trade/lbank-all-history-order-view/?count=3&p=${orderHistoryCounter}`,
          {
            market: selectedSymbol.id,
            // current_page: 1,
            // page_length: 20,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("lbank-all-history response: ", response);
          setOrderHistoryList((last) => {
            return [...last, ...response?.data?.results?.data?.Orders];
          });
        })
        .catch(function (error) {
          alert(error);
        });
    }
  }, [orderHistoryCounter]);

  useEffect(() => {
    if (lastJsonMessage?.depth?.asks) {
      setAsks((old) => {
        // console.log("old",old)
        return lastJsonMessage?.depth?.asks;
      });
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (lastJsonMessage?.depth?.bids) {
      setBids(lastJsonMessage?.depth?.bids);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (lastJsonMessage?.tick) {
      setData({
        latest: lastJsonMessage.tick.latest,
        usd: lastJsonMessage.tick.usd,
        change: lastJsonMessage.tick.change,
        high: lastJsonMessage.tick.high,
        vol: lastJsonMessage.tick.vol,
        low: lastJsonMessage.tick.low,
      });
    }
  }, [lastJsonMessage]);

  // const [preValue, setPreValue] = useState("")
  // const [currentValue, setCurrentValue] = useState("")

  useEffect(() => {
    setPreValue(currentValue);
    setCurrentValue(data.latest);
  }, [data]);

  return (
    <div className="body_elements">
      <InfoModal
        show={confirmBuy}
        setShow={setConfirmBuy}
        title="Order created for Buy"
        body="Your order is created"
      />
      <InfoModal
        show={confirmSell}
        setShow={setConfirmSell}
        title="Order created for Sell"
        body="Your order is created"
      />
      <InfoModal
        show={confirmBuyError}
        setShow={setConfirmBuyError}
        title="Error..!"
        body={buyErrorMessage}
      />

      <InfoModal
        show={confirmSellError}
        setShow={setConfirmSellError}
        title="Error..!"
        body={sellErrorMessage}
      />

      <InfoModal
        show={zeroError}
        setShow={setZeroError}
        title="Invalid Request"
        body="You are doing invalid request"
      />

      <Header activeTab="signup" />
      <div className="my-5 py-3"></div>
      <main className="chart_MainBlock mt-5" id="chart_MainBlockID">
        {/*  */}
        {/* <Header activeTab="signup" isLoading={isLoading} /> */}

        {/* <header className="header_elements">
          <div className="page-wrapper ">
            <div className="nav-wrapper">
              <nav className="navbar">
                <div className="nav_barOuter">
                  <div className="nav_logo">
                    <a href="#">
                      <img src={logoExchange} alt="Company Logo" />
                    </a>
                  </div>
                  <div className="nav_menuBlocks menuLinkOuter">
                    <div className="menu-toggle menuLink" id="mobile-menu">
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                    </div>
                    <div className="user_menu">
                      <div className="user_menuInner">
                        <div className="user_name_target">
                          {/* <a href="#">
                            <h3>Wallet</h3>
                          </a> 
                          <Link to="/wallet"> Wallet </Link>
                        </div>
                        /* <div className="user_image">
                          <div className="user_imageElements">
                            <a href="#">
                              <p className="user_alpha displayNone">V</p>
                              <img
                                className="user_imagePic"
                                src={picUser}
                                alt="user-image"
                              />
                            </a>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header> */}

        <section className="stock_details">
          <StockDetails
            pair={pair}
            marketList={marketList}
            buyPrice={buyPrice}
            setBuyPrice={setBuyPrice}
            setSellPrice={setSellPrice}
            sendJsonMessage={sendJsonMessage}
            lastJsonMessage={lastJsonMessage}
            readyState={readyState}
            data={data}
            setData={setData}
            preValue={preValue}
            currentValue={currentValue}
          />
        </section>

        <section className="dash_dataLayer">
          <div className="dashDataOuter theme_container">
            <div className="theme_row">
              <div className="block_listOuter block_listOuterTabs">
                <div className="blockListInner">
                  <div className="tab-container">
                    <div
                      className="tab-menu blockListNav"
                      style={{ display: "none" }}
                    >
                      <div className="menuBLocks">
                        <ul>
                          <li>
                            <a
                              href="#"
                              className="tab-a active-a"
                              data-id="tab1"
                            ></a>
                          </li>
                          <li>
                            <a href="#" className="tab-a" data-id="tab2">
                              {/* <div className="blockBtnFirst svgsecond">
                                                      <?xml version="1.0" encoding="UTF-8"?><svg id="Layer_1"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 22.78 22.78">
                                                          <defs>
                                                              <style>
                                                                  .cls-2 {
                                                                      fill: #00a74a;
                                                                  }

                                                                  .cls-3 {
                                                                      fill: #a2a2a2;
                                                                  }
                                                              </style>
                                                          </defs>
                                                          <g className="cls-1">
                                                              <rect className="cls-2" y="0" width="10.25"
                                                                  height="22.78" />
                                                              <rect className="cls-3" x="12.53" width="10.25"
                                                                  height="5.7" />
                                                              <rect className="cls-3" x="12.53" y="8.54" width="10.25"
                                                                  height="5.7" />
                                                              <rect className="cls-3" x="12.53" y="17.09" width="10.25"
                                                                  height="5.7" />
                                                          </g>
                                                      </svg>
                                                  </div> */}
                            </a>
                          </li>
                          <li>
                            <a href="#" className="tab-a" data-id="tab3">
                              {/* <div className="blockBtnFirst svgthird">
                                                      <?xml version="1.0" encoding="UTF-8"?><svg id="Layer_1"
                                                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                          <defs>
                                                              <style>
                                                                  .cls-2 {
                                                                      fill: #ff161f;
                                                                  }

                                                                  .cls-3 {
                                                                      fill: #a2a2a2;
                                                                  }
                                                              </style>
                                                          </defs>
                                                          <g className="cls-1">
                                                              <rect className="cls-2" y="0" width="9" height="20" />
                                                              <rect className="cls-3" x="11" width="9" height="5" />
                                                              <rect className="cls-3" x="11" y="7.5" width="9"
                                                                  height="5" />
                                                              <rect className="cls-3" x="11" y="15" width="9"
                                                                  height="5" />
                                                          </g>
                                                      </svg>
                                                  </div> */}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="BlockOuterNavlayer">
                        <div className="element_layersNav">
                          <div className="element_layerOusets">
                            <div className="elementNavBlock right_toggle">
                              <h5 className="grey_text font_bold">0.01 </h5>
                              <a className="carret_taget ">
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>
                                <div className="carret_tagetBlock">
                                  <ul className="carret_tagetBlockUl">
                                    <li className="carret_tagetBlockLi value_yellow">
                                      0.01
                                    </li>
                                    <li className="carret_tagetBlockLi">
                                      0.01
                                    </li>
                                    <li className="carret_tagetBlockLi">
                                      0.01
                                    </li>
                                    <li className="carret_tagetBlockLi">
                                      0.01
                                    </li>
                                  </ul>
                                </div>
                              </a>
                            </div>
                            <div className="NavBlockDots">
                              <a className="NavBlockDots_target">
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                ></i>
                                <div className="nav_itemcheck">
                                  <form className="nav__itemBlock">
                                    <div className="form-group nav__itemGroup">
                                      <input type="checkbox" id="html" />
                                      <label htmlFor="html">Display view</label>
                                    </div>
                                  </form>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Outer_Navtarget">
                      <div className="title_topLayer">
                        <div className="table_titles_out">
                          <p>
                            Price{" "}
                            <span>({pair.split("_")[1].toUpperCase()})</span>
                          </p>
                        </div>
                        <div className="table_titles_out wcustom">
                          <p>AMOUNT ({pair.split("_")[0].toUpperCase()})</p>
                        </div>
                        <div className="table_titles_out textBlockCenter">
                          <p>Total</p>
                        </div>
                      </div>
                    </div>
                    {/* <!--end of tab-menu--> */}
                    <div className="tab tab-active" data-id="tab1">
                      <div className="tab_first_elements scrol_firstSets">
                        <div className="table_itemBlocks mt-c0">
                          <div className="tableBlockUouter height_50">
                            <ul className="tableBlockTitle">
                              {asks.map((a, i) => {
                                return (
                                  <li
                                    className="tableBlockTitleList blockHover__target"
                                    key={i}
                                  >
                                    <div className="table_titles titleTargetPrice ">
                                      <span className="value_red">{a[0]}</span>
                                    </div>
                                    <div className="table_titles titleTargetAmount ">
                                      <span>{a[1]}</span>
                                    </div>

                                    <div className="table_titles table_titlesTotal log_data_w25">
                                      <span>
                                        {(Number(a[0]) * Number(a[1])).toFixed(
                                          9
                                        )}
                                      </span>
                                    </div>
                                    <div className="blockHover__target_show"></div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="tableBlockUouter block_itemStock mt-c2 height_50_layer">
                            <div className="title_topLayer">
                              <div className="table_titles_out">
                                {preValue > currentValue ? (
                                  <p className="red_value valueBig">
                                    {currentValue}
                                  </p>
                                ) : (
                                  <p className="green_value valueBig">
                                    {currentValue}
                                  </p>
                                )}
                              </div>
                              <div className="table_titles_out">
                                <p>
                                  <span>$</span>
                                  {data.usd}
                                </p>
                              </div>
                              <div className="table_titles_out textBlockCenter">
                                <p>More</p>
                              </div>
                            </div>
                            <ul className="tableBlockTitle">
                              {bids.map((a, i) => {
                                return (
                                  <li className="tableBlockTitleList" key={i}>
                                    <div className="table_titles target_outblock titleTargetPrice ">
                                      <span className="green_value">
                                        {" "}
                                        {a[0]}
                                      </span>
                                    </div>
                                    <div className="table_titles target_outblock titleTargetAmount ">
                                      <span>{a[1]}</span>
                                    </div>

                                    <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                      <span>
                                        {(Number(a[0]) * Number(a[1])).toFixed(
                                          9
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--end of tab one--> */}

                    <div className="tab " data-id="tab2">
                      <div className="tabBlockSecond scrol_leftLock">
                        <div className="tableBlockUouter block_itemStock">
                          <div className="title_topLayer">
                            <div className="table_titles_out">
                              <p className="green_value valueBig">21209.79</p>
                            </div>
                            <div className="table_titles_out">
                              <p>
                                <span>$</span>21,209.79
                              </p>
                            </div>
                            <div className="table_titles_out textBlockCenter">
                              <p>More</p>
                            </div>
                          </div>
                          <ul className="tableBlockTitle">
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>

                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <!--end of tab two--> */}
                    <div className="tab " data-id="tab3">
                      <div className="tabBlockThird scrol_leftLock">
                        <div className="tableBlockUouter block_itemStock">
                          <div className="title_topLayer">
                            <div className="table_titles_out">
                              <p className="value_red valueBig">21209.79</p>
                            </div>
                            <div className="table_titles_out">
                              <p>
                                <span>$</span>21,209.79
                              </p>
                            </div>
                            <div className="table_titles_out textBlockCenter">
                              <p>More</p>
                            </div>
                          </div>
                          <ul className="tableBlockTitle">
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_w100">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="value_red">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value">21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>

                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w25">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal  log_data_reen_w50">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList log_data_reen_w75">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal ">
                                <span>636.292315245</span>
                              </div>
                            </li>
                            <li className="tableBlockTitleList">
                              <div className="table_titles target_outblock titleTargetPrice ">
                                <span className="green_value"> 21209.79</span>
                              </div>
                              <div className="table_titles target_outblock titleTargetAmount ">
                                <span>0.3000</span>
                              </div>

                              <div className="table_titles target_outblock table_titlesTotal log_data_reen_w10">
                                <span>636.292315245</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <!--end of tab three--> */}
                  </div>
                  {/* <!--end of container--> */}
                </div>
              </div>
              <div className="block_graphData">
                {/* <div className='block_graphNav'>
                  <div className='block_graphNavInner'>
                    <div className='block_graphLeft'>
                      <div className='timeBlock timeSets'>
                        <p>Time</p>
                      </div>
                      <div className='timeBlock minSets'>
                        <p>15m</p>
                      </div>
                      <div className='timeBlock horSets'>
                        <p>1H</p>
                      </div>
                      <div className='timeBlock horSets'>
                        <p>4H</p>
                      </div>
                      <div className='timeBlock daySets'>
                        <p className='value_yellow font_bold'>1D</p>
                      </div>
                      <div className='timeBlock weekSets'>
                        <p>1W</p>
                      </div>
                      <div className='timeBlock carrtSets'>
                        <div className='carratarrow'>
                          <a href='#'>
                            <i
                              className='fa fa-caret-down hoverTarget_carrt'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>
                        <div className='carratarrowHover'>
                          <div className='carrateBlock_Outer'>
                            <p>block reserve</p>
                          </div>
                        </div>
                      </div>
                      <div className='timeBlock changerSet'>
                        <a href='#'>
                          <img
                            className='btn_elements_arrow'
                            src={btn1}
                            alt='btn'
                          />
                        </a>
                      </div>
                    </div>
                    <div className='block_graphRight'>
                      <div className='timeBlockRight'>
                        <p className='value_yellow'>Orignal</p>
                      </div>
                      <div className='timeBlockRight'>
                        <p>Tranding View</p>
                      </div>
                      <div className='timeBlockRight'>
                        <p>Depth</p>
                      </div>
                      <div className='timeBlockRight'>
                        <a href='#'>
                          <img
                            className='btn_elements_arrow'
                            src={icon1}
                            alt='btn'
                          />
                        </a>
                      </div>
                      <div className='timeBlockRight'>
                        <a href='#'>
                          <img
                            className='btn_elements_arrow'
                            src={btn6}
                            alt='btn'
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className='titleGraph'>
                  <div className='titleGraphInner'>
                    <div className='titleFirstGraph'>
                      <div className='titleFirstGraphBlock carrtSets'>
                        <div className='carratarrow '>
                          <a href='#'>
                            <i
                              className='fa fa-caret-down hoverTarget_carrt'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>
                        <div className='textGraphLine'>
                          <p>
                            <span>2022/08/22</span>
                            <span> Open: </span>
                            <span>21516.06</span> <span>High: </span>
                            <span className='value_red'>21516.06</span>
                            <span> Low:</span>
                            <span>21516.06</span>
                            <span className='value_red'> Close:</span>
                            <span className='value_red'>21516.06</span>
                            <span> CHANGE:</span>
                            <span className='value_red'> -1.43%</span>
                            <span>AMPLITUDE:</span>
                            <span className='value_red'>1.70%</span>
                          </p>
                        </div>
                      </div>
                      <div className='titleFirstGraphBlock carrtSets mt-2'>
                        <div className='carratarrow'>
                          <a href='#'>
                            <i
                              className='fa fa-caret-down hoverTarget_carrt'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>
                        <div className='textGraphLine'>
                          <p>
                            <span>MA</span>
                            <span>(7):</span>
                            <span className='value_red'>21516.06</span>
                            <span className='value_yellow'>MA</span>
                            <span className='value_yellow'>(7):</span>
                            <span className='value_yellow'>21516.06</span>
                            <span>MA</span>
                            <span>(7):</span>
                            <span className='value_red'>21516.06</span>
                            <span>MA</span>
                            <span>(7):</span>
                            <span className='value_red'>21516.06</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="graphPreview">
                  {/* <div className='graphPreviewOuter'>
                    <div className='graphPreviewInner'>
                      <p className='green_value text_center'>Block Reserve</p>
                    </div>
                  </div> */}
                  <Jschart pair={pair} exchange={exchange} />
                </div>
                <div className="graphPreview_tabs">
                  <div className="graphPreviewOuter_tabs">
                    <div className="graphPreviewInner_tabs">
                      <div className="tab-teaser">
                        <div className="tab-menu">
                          <ul>
                            <li>
                              <a href="#" className="active" data-rel="tab-1">
                                Limit
                              </a>
                            </li>
                            {/* <!-- <li><a href="#" data-rel="tab-2" className="">Tab 2</a></li>
                                              <li><a href="#" data-rel="tab-3" className="">Tab 3</a></li> --> */}
                          </ul>
                        </div>

                        <div className="tab-main-box">
                          <div
                            className="tab-box"
                            id="tab-1"
                            style={{ display: "block" }}
                          >
                            <div className="tabBoxElement">
                              <div className="tabBoxOuter">
                                <div className="tabBoxRightLock">
                                  <div className="tabBoxTitle">
                                    <h4 className="grey_text">
                                      Available{" "}
                                      <span className="value_white">
                                        20,000,000
                                      </span>
                                      <span className="value_white">
                                        COWRIE
                                      </span>
                                    </h4>
                                  </div>
                                  <div className="tabBoxField fieldPrice">
                                    {/* <form
                                      action='#'
                                      className='from_actionControl'
                                    > */}
                                    <div className="field_layers">
                                      <div className="field_nameBlock grey_text">
                                        <label>Price</label>
                                      </div>
                                      <input
                                        data-bn-type="input"
                                        lang="en"
                                        name="quantity"
                                        className="quantity_input"
                                        min="0.00001000"
                                        step="0.00001000"
                                        type="text"
                                        value={buyPrice.buyPriceValue}
                                        placeholder="20212.00"
                                        onChange={(e) => {
                                          setBuyPrice({
                                            ...buyPrice,
                                            buyPriceValue: e.target.value,
                                          });
                                        }}
                                      />
                                      <div className="field_nameBlock">
                                        <label>COWRIE</label>
                                      </div>
                                    </div>
                                    <div className="field_layers amountField">
                                      <div className="field_nameBlock grey_text">
                                        <label>Amount</label>
                                      </div>
                                      <input
                                        data-bn-type="input"
                                        lang="en"
                                        name="quantity"
                                        className="quantity_input"
                                        min="0.00001000"
                                        step="0.00001000"
                                        type="text"
                                        value={buyAmount}
                                        placeholder="20212.00"
                                        onChange={(e) => {
                                          setAmount(e.target.value);
                                        }}
                                      />
                                      <div className="field_nameBlock vhide">
                                        <label>COWRIE</label>
                                      </div>
                                    </div>

                                    <div className="lineBlockslayer">
                                      <div
                                        tabIndex="-1"
                                        className="percentSlider Field_outlines"
                                      >
                                        <div dir="ltr" className="css-w43vo">
                                          <div className="bn-slider-container css-4cffwv">
                                            <div className="bn-slider-available-bar css-16vu25q">
                                              <div className="bn-slider-progress-bar css-1dve1bu"></div>
                                            </div>
                                            <div className="bn-slider-disabled-bar css-hv4c4w"></div>
                                            <div className="bn-slider-radio-button css-1tadr71"></div>
                                            <label className="bn-slider-radio-tooltip css-1tadr71">
                                              0%
                                            </label>
                                            <div className="bn-slider-stepper bn-slider-split-stepper css-1f06ypn"></div>
                                            <div className="bn-slider-stepper bn-slider-progress-stepper css-1tadr71"></div>
                                            <div className="bn-slider-stepper css-5uyjqk"></div>
                                            <div className="bn-slider-stepper css-g2kqm4"></div>
                                            <div className="bn-slider-stepper css-lgb8z9"></div>
                                            <div className="bn-slider-stepper css-1f06ypn"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="field_layers totalField">
                                      <div className="field_nameBlock grey_text">
                                        <label>Total</label>
                                      </div>
                                      <input
                                        data-bn-type="input"
                                        lang="en"
                                        name="quantity"
                                        className="quantity_input"
                                        min="0.00001000"
                                        step="0.00001000"
                                        type="text"
                                        value={buyTotal}
                                        onChange={() => {}}
                                      />
                                      <div className="field_nameBlock vhide">
                                        <label>COWRIE</label>
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        className="file_button_layer"
                                        onClick={handleBuy}
                                      >
                                        BUY COWRIE
                                      </button>
                                    </div>
                                    {/* </form> */}
                                  </div>
                                </div>
                                <div className="tabBoxRightLock">
                                  <div className="tabBoxTitle">
                                    <h4 className="grey_text">
                                      Available{" "}
                                      <span className="value_white">
                                        20,000,000
                                      </span>
                                      <span className="value_white">
                                        COWRIE
                                      </span>
                                    </h4>
                                  </div>
                                  <div className="tabBoxField fieldPrice">
                                    {/* <form
                                      action="#"
                                      className="from_actionControl"
                                    > */}
                                    <div className="field_layers">
                                      <div className="field_nameBlock grey_text">
                                        <label>Price</label>
                                      </div>
                                      <input
                                        data-bn-type="input"
                                        lang="en"
                                        name="quantity"
                                        className="quantity_input"
                                        min="0.00001000"
                                        step="0.00001000"
                                        type="text"
                                        value={sellPrice.sellPriceValue}
                                        placeholder="20212.00"
                                        onChange={(e) => {
                                          setSellPrice({
                                            ...sellPrice,
                                            sellPriceValue: e.target.value,
                                          });
                                        }}
                                      />
                                      <div className="field_nameBlock">
                                        <label>COWRIE</label>
                                      </div>
                                    </div>
                                    <div className="field_layers amountField">
                                      <div className="field_nameBlock grey_text">
                                        <label>Amount</label>
                                      </div>
                                      <input
                                        data-bn-type="input"
                                        lang="en"
                                        name="quantity"
                                        className="quantity_input"
                                        min="0.00001000"
                                        step="0.00001000"
                                        type="text"
                                        value={sellAmount}
                                        placeholder="20212.00"
                                        onChange={(e) => {
                                          setSellAmount(e.target.value);
                                        }}
                                      />
                                      <div className="field_nameBlock ">
                                        <label>USDT</label>
                                      </div>
                                    </div>

                                    <div className="lineBlockslayer">
                                      <div
                                        tabIndex="-1"
                                        className="percentSlider Field_outlines"
                                      >
                                        <div dir="ltr" className="css-w43vo">
                                          <div className="bn-slider-container css-4cffwv">
                                            <div className="bn-slider-available-bar css-16vu25q">
                                              <div className="bn-slider-progress-bar css-1dve1bu"></div>
                                            </div>
                                            <div className="bn-slider-disabled-bar css-hv4c4w"></div>
                                            <div className="bn-slider-radio-button css-1tadr71"></div>
                                            <label className="bn-slider-radio-tooltip css-1tadr71">
                                              0%
                                            </label>
                                            <div className="bn-slider-stepper bn-slider-split-stepper css-1f06ypn"></div>
                                            <div className="bn-slider-stepper bn-slider-progress-stepper css-1tadr71"></div>
                                            <div className="bn-slider-stepper css-5uyjqk"></div>
                                            <div className="bn-slider-stepper css-g2kqm4"></div>
                                            <div className="bn-slider-stepper css-lgb8z9"></div>
                                            <div className="bn-slider-stepper css-1f06ypn"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="field_layers totalField">
                                      <div className="field_nameBlock grey_text">
                                        <label>Total</label>
                                      </div>
                                      <input
                                        data-bn-type="input"
                                        lang="en"
                                        name="quantity"
                                        className="quantity_input"
                                        min="0.00001000"
                                        step="0.00001000"
                                        type="text"
                                        value={sellTotal}
                                        placeholder="21212.00"
                                        onChange={() => {}}
                                      />
                                      <div className="field_nameBlock ">
                                        <label>USDT</label>
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        className="file_button_layer bgRed"
                                        onClick={handleSell}
                                      >
                                        SELL COWRIE
                                      </button>
                                    </div>
                                    {/* </form> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="block_listOuter_left">
                <div className="blockListInner">
                  <div className="table_itemBlocks ">
                    <div className="tableBlockUouter">
                      <div className="title_topLayer">
                        <div className="table_titles_ups">
                          <div className="arrowPairOuter">
                            <div className="arrowPairs">
                              <p>Pair</p>
                              <div className="arrow_targets">
                                <a className="arrowBlockElements" href="#">
                                  <i
                                    className="arrow_setsCaret fa fa-caret-up "
                                    aria-hidden="true"
                                  ></i>
                                </a>
                                <a className="arrowBlockElements" href="#">
                                  {" "}
                                  <i
                                    className="arrow_setsCaret fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <ul className="tableBlockTitle barscrollsmall">
                        {marketList.map((a, i) => {
                          return (
                            <li className="tableBlockTitleList" key={i}>
                              <div className="table_titles titleTargetPrice ">
                                <div className="starItems">
                                  <div className="star_setouter star_fab">
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <a
                                    className="star_setouter"
                                    href={"/chart?pair=" + a.market}
                                  >
                                    <p>
                                      {a.market.split("_")[0].toUpperCase()}
                                      <span className="grey_text">
                                        /{a.market.split("_")[1].toUpperCase()}
                                      </span>
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="table_itemBlocks tableBlockUouter ">
                    <div className="tabBlockDataLayer">
                      <div className="tab-teaser tabBlockDataLayer_trade ">
                        <div className="tab-menu tabBlockData_trade_list">
                          <ul>
                            <li>
                              <a href="#" className="active" data-rel="tab-11">
                                Market Trades
                              </a>
                            </li>
                            <li>
                              {/* <a href="#" data-rel="tab-22" className="">
                                My Trade
                              </a> */}
                            </li>
                            {/* <!-- <li><a href="#" data-rel="tab-33" className="">Tab 3</a></li> --> */}
                          </ul>
                        </div>
                        <div className="Outer_Navtarget tabBlockDataLayer_tabsinner">
                          <div className="title_topLayer">
                            <div className="table_titles_out">
                              <p>
                                Price{" "}
                                <span>
                                  ({pair.split("_")[1].toUpperCase()})
                                </span>
                              </p>
                            </div>
                            <div className="table_titles_out wcustom">
                              <p>AMOUNT ({pair.split("_")[0].toUpperCase()})</p>
                            </div>
                            <div className="table_titles_out textBlockCenter">
                              <p>Time</p>
                            </div>
                          </div>
                        </div>

                        <div className="tab-main-box">
                          <div
                            className="tab-box"
                            id="tab-11"
                            style={{ display: "block" }}
                          >
                            <MarketTrade
                              pair={pair}
                              sendJsonMessage={sendJsonMessage}
                              lastJsonMessage={lastJsonMessage}
                              readyState={readyState}
                            />
                          </div>

                          <div className="tab-box" id="tab-22">
                            <ul className="tableBlockTitle barscroll">
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="value_red">
                                    {" "}
                                    21209.79xxxxxxxxxx
                                  </span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="green_value"> 21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal  ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="green_value"> 21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="green_value">21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal  ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="green_value">21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal  ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="value_red"> 21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="value_red">21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="value_red">21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="value_red">21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="value_red"> 21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="value_red">21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                              <li className="tableBlockTitleList">
                                <div className="table_titles titleTargetPrice ">
                                  <span className="value_red">21209.79</span>
                                </div>
                                <div className="table_titles titleTargetAmount ">
                                  <span>0.3000</span>
                                </div>

                                <div className="table_titles table_titlesTotal ">
                                  <span>09:48:26</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {/* <!-- <div className="tab-box" id="tab-33">
                                              <h2>Tab 3</h2>
                                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                  industry. Lorem Ipsum
                                                  has been the industry's standard dummy text ever since the 1500s,
                                                  when
                                                  an unknown
                                                  printer took a galley of type and scrambled it to make a type
                                                  specimen
                                                  book. It has
                                                  survived not only five centuries, but also the leap into electronic
                                                  typesetting,
                                                  remaining essentially unchanged.</p>
                                          </div> --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="block_listLayerelements ">
          <div className="theme_container_tab">
            <div className="tab-teaser">
              <div className="tab-menu_sets line_itemTB">
                <ul>
                  <li style={{ width: "20%" }}>
                    <a
                      className={orderListTab === "openorder" ? "active" : ""}
                      data-rel="tab-111"
                      onClick={() => {
                        setOrderListTab("openorder");
                      }}
                    >
                      Open order <span> ({openOrderTotalCount})</span>
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ width: "auto" }}
                      className={
                        orderListTab === "orderhistory" ? "active" : ""
                      }
                      data-rel="tab-222"
                      onClick={() => {
                        setOrderListTab("orderhistory");
                      }}
                    >
                      Order History <span>({orderHistoryTotalCount})</span>
                    </a>
                  </li>
                  <li>
                    {/* <a href="#" data-rel="tab-333" className="">
                      Trand History
                    </a> */}
                  </li>
                </ul>
              </div>

              <div className="tab-main-box ">
                <div
                  className="tab-box"
                  id="tab-111"
                  style={{
                    display: orderListTab === "openorder" ? "block" : "",
                  }}
                >
                  {openOrdersList?.length > 0 ? (
                    <DetailsTable
                      openOrderTotalCount={openOrderTotalCount}
                      pair={pair}
                      setCounter={setOrderCounter}
                      Counter={orderCounter}
                      OrdersList={openOrdersList}
                      handleCancelOrder={handleCancelOrder}
                      setOpenOrderTotalCount={setOpenOrderTotalCount}
                    ></DetailsTable>
                  ) : (
                    <div className="target_centerdata">
                      <div className="target_centerdata">
                        <h4>You have no Open Order </h4>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="tab-box"
                  id="tab-222"
                  style={{
                    display: orderListTab === "orderhistory" ? "block" : "",
                  }}
                >
                  {orderHistoryList?.length > 0 ? (
                    <HistoryDetailsTable
                      pair={pair}
                      setCounter={setOrderHistoryCounter}
                      Counter={orderHistoryCounter}
                      OrdersList={orderHistoryList}
                      handleCancelOrder={handleCancelOrder}
                      orderHistoryTotalCount={orderHistoryTotalCount}
                    ></HistoryDetailsTable>
                  ) : (
                    <div className="target_centerdata">
                      <div className="target_centerdata">
                        <h4>You have no Order History</h4>
                      </div>
                    </div>
                  )}

                  {/* {orderHistoryList?.map((a, i) => {
                    return (
                      <ul className="tabOrderItems tabOrderItems_inner" key={i}>
                        <li className="tabOurderItem_sets tabItem_Date_inner">
                          <h4 className="tabItemInnertitle ">
                            {a.created_date.substring(0, 10)}
                          </h4>
                        </li>
                        {/* <li className='tabOurderItem_sets tabItem_Pair_inner'>
                        <h4 className='tabItemInnertitle '>BTC/USDT</h4>
                      </li> 
                        <li className="tabOurderItem_sets tabItem_Type_inner">
                          <h4 className="tabItemInnertitle ">{a.type}</h4>
                        </li>
                        {/* <li className='tabOurderItem_sets tabItem_Side_inner'>
                        <h4 className='tabItemInnertitle value_red'>Sell</h4>
                      </li> 
                        <li className="tabOurderItem_sets tabItem_Price_inner">
                          <h4 className="tabItemInnertitle ">{a.price}</h4>
                        </li>
                        <li className="tabOurderItem_sets tabItem_Amount_inner">
                          <h4 className="tabItemInnertitle ">
                            {parseFloat(a.amount)}
                          </h4>
                        </li>
                        {/* <li className='tabOurderItem_sets tabItem_Field_inner'>
                        <h4 className='tabItemInnertitle '>0.00%</h4>
                      </li> 
                        <li className="tabOurderItem_sets tabItem_Total_inner">
                          <h4 className="tabItemInnertitle ">
                            {parseFloat(a.amount) * a.price}{" "}
                            <span>{pair.split("_")[1].toUpperCase()}</span>
                          </h4>
                        </li>
                        {/* <li className='tabOurderItem_sets tabItem_Condition_inner'>
                        <h4 className='tabItemInnertitle '>-</h4>
                      </li> 
                        <li className="tabOurderItem_sets tabItem_trash_inner">
                          <h4 className="tabItemInnertitle">
                            {i}
                            <span
                              className="targetDelete"
                              onClick={() => {
                                handleCancelOrder(a.market, a.lbank_order_id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          </h4>
                        </li>
                      </ul>
                    );
                  })} */}
                </div>
                <div className="tab-box" id="tab-333">
                  <div className="Thistry">
                    <div className="target_centerdata">
                      <div className="target_centerdata">
                        <h4>You have no open order</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <script type="text/javascript" src="./assest/js/jquery.min.js"></script>
      <script type="text/javascript" src="./assest/js/cow-theme.js"></script>
    </div>
  );
};
export default Chart;

const MarketTrade = ({
  pair,
  sendJsonMessage,
  lastJsonMessage,
  readyState,
}) => {
  const messageHistory = useRef([]);

  messageHistory.current = useMemo(
    () => messageHistory.current.concat(lastJsonMessage ?? []),
    [lastJsonMessage]
  );

  const handleClickSendMessage = useCallback(
    () =>
      sendJsonMessage({
        action: "subscribe",
        subscribe: "trade",
        pair: pair,
      }),
    [sendJsonMessage]
  );

  const [marketTradeData, setMarketTradeData] = useState([]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    // if(connectionStatus==="closed"){
    handleClickSendMessage();
    // }
    //console.log("abhi===", connectionStatus);
  }, [connectionStatus]);

  useEffect(() => {
    //  console.log(marketTradeData);
    //console.log("lastJsonMessage: ============", lastJsonMessage?.trade);
    if (lastJsonMessage !== null && lastJsonMessage?.trade) {
      setMarketTradeData([
        {
          price: lastJsonMessage?.trade?.price,
          amount: lastJsonMessage?.trade?.amount,
          ts: lastJsonMessage?.trade?.TS?.substr(11, 8),
        },
        ...marketTradeData,
      ]);
      // setMarketTradeData(
      //   [...marketTradeData.unshift({
      //     price: lastJsonMessage?.trade?.price,
      //     amount: lastJsonMessage?.trade?.amount,
      //     ts: lastJsonMessage?.trade?.TS?.substr(11, 8),
      //   })]
      // );
    }
  }, [lastJsonMessage]);
  return (
    <ul
      className="tableBlockTitle"
      style={{ height: "260px", overflow: "auto" }}
    >
      {marketTradeData.map((a, i) => {
        return (
          <li className="tableBlockTitleList" key={i}>
            <div className="table_titles titleTargetPrice ">
              <span
                style={
                  marketTradeData[i]?.price > marketTradeData[i + 1]?.price
                    ? { color: "green" }
                    : { color: "red" }
                }
              >
                {a.price}
              </span>
            </div>
            <div className="table_titles titleTargetAmount ">
              <span>{a.amount}</span>
            </div>
            <div className="table_titles table_titlesTotal ">
              <span>{a.ts}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

const DetailsTable = ({
  pair,
  setCounter,
  Counter,
  OrdersList,
  handleCancelOrder,
  openOrderTotalCount,
  setOpenOrderTotalCount,
}) => {
  return (
    <>
      <div className="tabOrderMenuBlock set_p30">
        <div className="tabOrderItems">
          <div className="tabOurderItem_sets tabItem_Date">
            <h4 className="tabItemInnertitle ">Date</h4>
          </div>
          {/* <div className='tabOurderItem_sets tabItem_Pair'>
                    <h4 className='tabItemInnertitle '>Pair</h4>
                  </div> */}
          <div className="tabOurderItem_sets tabItem_Type">
            <h4 className="tabItemInnertitle ">Type</h4>
          </div>
          {/* <div className='tabOurderItem_sets tabItem_Side'>
                    <h4 className='tabItemInnertitle '>Side</h4>
                    <a href='#' className='carret_taget value_grey'>
                      <i className='fa fa-caret-down' aria-hidden='true'></i>
                    </a>
                  </div> */}
          <div className="tabOurderItem_sets  tabItem_Price">
            <h4 className="tabItemInnertitle ">Price</h4>
          </div>
          <div className="tabOurderItem_sets tabItem_Amount">
            <h4 className="tabItemInnertitle ">Amount</h4>
          </div>
          {/* <div className='tabOurderItem_sets tabItem_Field'>
                    <h4 className='tabItemInnertitle '>Field</h4>
                  </div> */}
          <div className="tabOurderItem_sets tabItem_Total">
            <h4 className="tabItemInnertitle ">Total</h4>
          </div>
          {/* <div className='tabOurderItem_sets tabItem_Condition'>
                    <h4 className='tabItemInnertitle '>Triger Condition</h4>
                  </div> */}
          <div className="tabOurderItem_sets tabItem_cancel">
            <div className="toggleCancel">
              <div className="elementNavBlock right_toggle_cen">
                <h5 className="value_yellow font_bold ">Cancel</h5>
                {/* <a className='carret_taget '>
                          <i
                            className='fa fa-caret-down'
                            aria-hidden='true'
                          ></i>
                          <div className='carret_tagetBlock_cen tabItem_cancel_toggle '>
                            <ul className='carret_tagetBlockUl tabItem_cancel_toggleUl'>
                              <li className='carret_tagetBlockLi'>All</li>
                              <li className='carret_tagetBlockLi'>Limit</li>
                              <li className='carret_tagetBlockLi'>0.01</li>
                            </ul>
                          </div>
                        </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {OrdersList?.map((a, i) => {
        return (
          <ul className="tabOrderItems tabOrderItems_inner" key={i}>
            <li className="tabOurderItem_sets tabItem_Date_inner">
              <h4 className="tabItemInnertitle ">
                {a?.created_date.substring(0, 10)}
              </h4>
            </li>
            {/* <li className='tabOurderItem_sets tabItem_Pair_inner'>
                        <h4 className='tabItemInnertitle '>BTC/USDT</h4>
                      </li> */}
            <li className="tabOurderItem_sets tabItem_Type_inner">
              <h4 className="tabItemInnertitle ">{a?.type}</h4>
            </li>
            {/* <li className='tabOurderItem_sets tabItem_Side_inner'>
                        <h4 className='tabItemInnertitle value_red'>Sell</h4>
                      </li> */}
            <li className="tabOurderItem_sets tabItem_Price_inner">
              <h4 className="tabItemInnertitle ">{a?.price}</h4>
            </li>
            <li className="tabOurderItem_sets tabItem_Amount_inner">
              <h4 className="tabItemInnertitle ">{parseFloat(a?.quantity)}</h4>
            </li>
            {/* <li className='tabOurderItem_sets tabItem_Field_inner'>
                        <h4 className='tabItemInnertitle '>0.00%</h4>
                      </li> */}
            <li className="tabOurderItem_sets tabItem_Total_inner">
              <h4 className="tabItemInnertitle ">
                {parseFloat(a?.quantity) * a?.price}{" "}
                <span>{pair.split("_")[1].toUpperCase()}</span>
              </h4>
            </li>
            {/* <li className='tabOurderItem_sets tabItem_Condition_inner'>
                        <h4 className='tabItemInnertitle '>-</h4>
                      </li> */}
            <li className="tabOurderItem_sets tabItem_trash_inner">
              <h4 className="tabItemInnertitle">
                <span
                  className="targetDelete"
                  onClick={() => {
                    handleCancelOrder(a?.market, a?.lbank_order_id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa-solid fa-trash"></i>
                </span>
              </h4>
            </li>
          </ul>
        );
      })}
      {OrdersList?.length === openOrderTotalCount ? (
        ""
      ) : (
        <button
          className="btn btn-light"
          onClick={() => {
            console.log(".............", Counter);
            console.log("---------- -- -", OrdersList?.length);
            setCounter((last) => last + 1);
          }}
        >
          Load More
        </button>
      )}
    </>
  );
};

const HistoryDetailsTable = ({
  pair,
  setCounter,
  Counter,
  OrdersList,
  handleCancelOrder,
  orderHistoryTotalCount,
}) => {
  return (
    <>
      <div className="tabOrderMenuBlock set_p30">
        <div className="tabOrderItems">
          <div className="tabOurderItem_sets tabItem_Date">
            <h4 className="tabItemInnertitle ">Date</h4>
          </div>
          {/* <div className='tabOurderItem_sets tabItem_Pair'>
                    <h4 className='tabItemInnertitle '>Pair</h4>
                  </div> */}
          <div className="tabOurderItem_sets tabItem_Type">
            <h4 className="tabItemInnertitle ">Type</h4>
          </div>
          {/* <div className='tabOurderItem_sets tabItem_Side'>
                    <h4 className='tabItemInnertitle '>Side</h4>
                    <a href='#' className='carret_taget value_grey'>
                      <i className='fa fa-caret-down' aria-hidden='true'></i>
                    </a>
                  </div> */}
          <div className="tabOurderItem_sets  tabItem_Price">
            <h4 className="tabItemInnertitle ">Price</h4>
          </div>
          <div className="tabOurderItem_sets tabItem_Amount">
            <h4 className="tabItemInnertitle ">Amount</h4>
          </div>
          {/* <div className='tabOurderItem_sets tabItem_Field'>
                    <h4 className='tabItemInnertitle '>Field</h4>
                  </div> */}
          <div className="tabOurderItem_sets tabItem_Total">
            <h4 className="tabItemInnertitle ">Total</h4>
          </div>
        </div>
      </div>
      {OrdersList?.map((a, i) => {
        return (
          <ul className="tabOrderItems tabOrderItems_inner" key={i}>
            <li className="tabOurderItem_sets tabItem_Date_inner">
              <h4 className="tabItemInnertitle ">
                {a?.created_date.substring(0, 10)}
              </h4>
            </li>
            {/* <li className='tabOurderItem_sets tabItem_Pair_inner'>
                        <h4 className='tabItemInnertitle '>BTC/USDT</h4>
                      </li> */}
            <li className="tabOurderItem_sets tabItem_Type_inner">
              <h4 className="tabItemInnertitle ">{a?.type}</h4>
            </li>
            {/* <li className='tabOurderItem_sets tabItem_Side_inner'>
                        <h4 className='tabItemInnertitle value_red'>Sell</h4>
                      </li> */}
            <li className="tabOurderItem_sets tabItem_Price_inner">
              <h4 className="tabItemInnertitle ">{a?.price}</h4>
            </li>
            <li className="tabOurderItem_sets tabItem_Amount_inner">
              <h4 className="tabItemInnertitle ">{parseFloat(a?.amount)}</h4>
            </li>
            {/* <li className='tabOurderItem_sets tabItem_Field_inner'>
                        <h4 className='tabItemInnertitle '>0.00%</h4>
                      </li> */}
            <li className="tabOurderItem_sets tabItem_Total_inner">
              <h4 className="tabItemInnertitle ">
                {parseFloat(a?.amount) * a?.price}{" "}
                <span>{pair.split("_")[1].toUpperCase()}</span>
              </h4>
            </li>
            {/* <li className='tabOurderItem_sets tabItem_Condition_inner'>
                        <h4 className='tabItemInnertitle '>-</h4>
                      </li> */}
          </ul>
        );
      })}
      {OrdersList?.length === orderHistoryTotalCount ? (
        ""
      ) : (
        <button
          className="btn btn-light"
          onClick={() => {
            console.log(".............", Counter);
            setCounter((last) => last + 1);
          }}
        >
          Load More
        </button>
      )}
    </>
  );
};

const StockDetails = ({
  pair,
  marketList,
  setBuyPrice,
  setSellPrice,
  buyPrice,
  sendJsonMessage,
  lastJsonMessage,
  readyState,
  data,
  preValue,
  currentValue,
  setData,
}) => {
  const { token, setToken } = useToken();
  const [display, setDisplay] = useState("none");

  const messageHistory = useRef([]);
  messageHistory.current = useMemo(
    () => messageHistory.current.concat(lastJsonMessage ?? []),
    [lastJsonMessage]
  );
  const handleClickSendMessage = useCallback(
    () =>
      sendJsonMessage({
        action: "subscribe",
        subscribe: "tick",
        pair: pair,
      }),
    [sendJsonMessage]
  );
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    handleClickSendMessage();
  }, [connectionStatus]);

  useEffect(() => {
    if (
      buyPrice?.buyPriceValue === undefined &&
      !buyPrice.buyPriceSet &&
      lastJsonMessage?.tick?.latest
    ) {
      setBuyPrice({
        buyPriceValue: lastJsonMessage?.tick?.latest,
        buyPriceSet: true,
      });
      setSellPrice({
        sellPriceValue: lastJsonMessage?.tick?.latest,
        sellPriceSet: true,
      });
    }
  }, [lastJsonMessage]);

  const [search, setSearch] = useState("");

  // const [filteredMarketList, setFilteredMarketList] = useState(marketList);
  const [filteredMarketList, setFilteredMarketList] = useState(marketList);

  useEffect(() => {
    setFilteredMarketList(
      marketList.filter((market) =>
        market.market.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, marketList]);

  return (
    <div className="theme_container stock_detailsContainer">
      <div className="stock_detailsOuter">
        <div className="theme_outerLocks">
          <div className="theme_row stock_detailsRow">
            <div className="inner_stockLayer w-title1">
              <div className="inner_blockStock stockbox">
                <h2 className="stock_title">
                  {pair.split("_")[0].toUpperCase()} /{" "}
                  <span>{pair.split("_")[1].toUpperCase()}</span>
                </h2>
                <p className="light_layer">COWRIE Price</p>
              </div>
            </div>
            <div className="inner_stockLayer w-title2">
              <div className="inner_blockStock">
                <h2 className="stock_price green_value font_bold ">
                  {}
                  {preValue > currentValue ? (
                    <p className="red_value ">{data.latest}</p>
                  ) : (
                    <p className="green_value ">{data.latest}</p>
                  )}
                </h2>
                <p className="stock_price_data font_bold">
                  <span className="currency_target">$</span>
                  {data.usd}
                </p>
              </div>
            </div>
            <div className="inner_stockLayer w-title3">
              <div className="inner_blockStock">
                <h4 className="stock_priceBlock grey_text">24h Change</h4>
                <p
                  className={
                    data.change > 0
                      ? "stock_price_data font_boldgreen_value"
                      : "stock_price_data font_bold red_value"
                  }
                >
                  <span>
                    {data.latest
                      ? ((data.latest * data.change) / 100).toFixed(3)
                      : ""}
                  </span>
                  <span className="data_values"></span>
                  <span>{data.change}</span>
                </p>
              </div>
            </div>
            <div className="inner_stockLayer w-title4">
              <div className="inner_blockStock">
                <h4 className="stock_priceBlock grey_text">24h High</h4>
                <p className="stock_price_data  font_bold">{data.high}</p>
              </div>
            </div>
            <div className="inner_stockLayer w-title5">
              <div className="inner_blockStock">
                <h4 className="stock_priceBlock grey_text">24h Low</h4>
                <p className="stock_price_data  font_bold">{data.low}</p>
              </div>
            </div>
            <div className="inner_stockLayer w-title6">
              <div className="inner_blockStock ">
                <h4 className="stock_priceBlock grey_text">24h Volume</h4>
                <p className="stock_price_data  font_bold">{data.vol}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="searchBlockElements">
          <div className="search_Block">
            <div className="searchBlockInner">
              {/* <form className="searchForm" > */}
              <div className="searchForm">
                <div className="search_sets">
                  <i className="fa fa-search" aria-hidden="true"></i>
                  <input
                    type="text"
                    name="input1"
                    id="input1"
                    className="searchLine"
                    placeholder="Search.."
                    onClick={() => {
                      setDisplay("block");
                    }}
                    onBlur={() => {
                      setDisplay("none");
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div
                    id="list1"
                    className="list-group"
                    style={{ display: display }}
                  >
                    <div className="list_innerSearch">
                      {/* <div className="searchhead">
                                                    <h3>Top Search</h3>
                                                </div> */}
                      <div className="topBlockSearch">
                        <div className="Outer_Navtarget">
                          <div className="title_topLayer">
                            <div className="table_titles_out">
                              <p>Pair</p>
                            </div>
                            <div className="table_titles_out wcustom">
                              <p>Price</p>
                            </div>
                            <div className="table_titles_out textBlockCenter">
                              <p>change</p>
                            </div>
                          </div>
                        </div>
                        <div className="table_itemBlocks mt-c0">
                          <div className="tableBlockUouter height_50">
                            <ul className="tableBlockTitle">
                              {filteredMarketList.map((a, i) => {
                                return (
                                  <li
                                    className="tableBlockTitleList blockHover__target"
                                    key={i}
                                  >
                                    <div className="table_titles titleTargetPrice blocklineStar">
                                      <span>{a.market.toUpperCase()}</span>
                                    </div>
                                    {/* <div className="table_titles titleTargetAmount ">
                                                                          <span>0.3000</span>
                                                                      </div>

                                                                      <div
                                                                          className="table_titles table_titlesTotal log_data_w25">
                                                                          <span>636.292315245</span>
                                                                      </div> */}
                                  </li>
                                );
                              })}
                              {/* <li className="tableBlockTitleList blockHover__target">
                                                                    <div
                                                                        className="table_titles titleTargetPrice blocklineStar">
                                                                          <span className="value_red"> 21209.79</span>
                                                                    </div>
                                                                    <div className="table_titles titleTargetAmount ">
                                                                        <span>0.3000</span>
                                                                    </div>

                                                                    <div
                                                                        className="table_titles table_titlesTotal log_data_w25">
                                                                        <span>636.292315245</span>
                                                                    </div>
                                                                    <div className="blockHover__target_show">

                                                                    </div>
                                                                </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
