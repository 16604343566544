import { data } from "jquery";
import { Link } from "react-router-dom";
// import ReactHtmlParser from "react-html-parser";

const Footer = ({ footerHtml }) => {
  return (
    <>
      <footer className="footer mt-3">
     <div className="container">
        <div className="row">
        			<div className="col-lg-3 footer-left">
           			<Link to="/" className="footer-logo"><img src="assets/images/footer-logo.png" alt="" /></Link>
               <div className="footer-right">
                 <Link to="#" className="social-1"><i className="fab fa-facebook-f"></i></Link>
                 <Link to="#" className="social-2"><i className="fab fa-instagram"></i></Link>
                 <Link to="#" className="social-3"><i className="fab fa-twitter"></i></Link>
                 <Link to="#" className="social-4"><i className="fab fa-youtube"></i></Link>
                 <Link to="#" className="social-5"><img src="assets/images/tik-icon.png" alt="" /></Link>
                 <Link to="#" className="social-5"><i className="fab fa-linkedin-in"></i></Link>
                 <Link to="#" className="social-5"><i className="fab fa-whatsapp"></i></Link>
              </div>
           </div>
           <div className="col-lg-9 footer-nav-col">
           			<div className="row">
                <div className="footer-col">
                				<h4>Menu</h4>
                    <ul>
                    			<li><Link to="/">Home</Link></li>
                      <li><Link to="/whitepaper.pdf" target="_blank">Whitepaper</Link></li>
                      <li><Link to="/Kings-Paper.pdf" target="_blank">King's Paper</Link></li>
                    </ul>
                </div>
                <div className="footer-col">
                				<h4>Community</h4>
                    <ul>
                    			<li><Link to={{ pathname: "https://community.mycowrie.org/" }} target="_blank">Discover</Link></li>
                    </ul>
                </div>
                <div className="footer-col">
                		<h4>Technical</h4>
                    <ul>
                    	<li><Link to={{pathname: "https://bscscan.com/token/0xde51d1599339809cafb8194189ce67d5bdca9e9e"}}target="_blank">Blockchain Explorer</Link></li>
                    </ul>
                </div>
                <div className="footer-col">
                				<h4>Get In Touch</h4>
                    <ul>
                    			<li><Link to="#" onClick={(e) => {
                window.location.href = "mailto:support@mycowrie.org";
                e.preventDefault();
            }}>Support</Link></li>
                        </ul>
                </div>
                <div className="footer-col">
                				<h4>Legal</h4>
                    <ul>
                       <li><Link to="/privacy-policy.pdf" target="_blank">Privacy</Link></li>
                       <li><Link to="/terms-and-conditions.pdf" target="_blank" >Terms and Conditions</Link></li>
                       <li><Link to="/VIP-sale-terms.pdf" target="_blank">VIP Sale Terms</Link></li>
                    </ul>




                </div>
             </div>
           </div>
        </div>
        <div className="row copyright">
          <div className="col-lg-7">
            <img src="assets/images/footer-logos.png" alt="" />
          </div>
          <div className="col-lg-5">
            <div className="copyright-text">COPYRIGHT © 2022 | ALL RIGHTS RESERVED | VIRTUAL NATION BUILDERS PROJECTS PTY LTD.</div>
          </div>
        </div>
     </div>

  </footer>

      {/* <div> {ReactHtmlParser(footerHtml)} </div> */}
      {/* <div
        dangerouslySetInnerHTML={{
          __html: footerHtml,
        }}
      /> */}
    </>
  );
};

export default Footer;
