import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    <>
      <section className='sec buy-sec login-sec set_hsa'>
        <div className='container'>
          <div className='setting-box set_centerBlock'>
            <div className='top-img' style={{ background: "none" }}>
              <img src='assets/images/404.svg' alt='' />
            </div>
            <div className='setting-list'>
              <div className='setting-head'>
                <h3>
                  This page is decided to go on a safari.
                  <br />
                  <br />
                  <span>
                    We’ve sent a notice to the technical team to look into it.
                  </span>
                </h3>
              </div>
              <div className='login-col login_btnBlocks'>
                <Link to='/' className='custom-btn'>
                  Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
