const channelToSubscription = new Map();
var urlParams;
(window.onpopstate = function () {
//  console.log("window.location.search: ", window.location.pathname);
  if(window.location.pathname!=='/chart'){
    return;
  }
  var match,
    pl = /\+/g,
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s) {
      return decodeURIComponent(s.replace(pl, " "));
    },
    query = window.location.search.substring(1);
//  console.log("query: ", query);

  urlParams = {};
  while ((match = search.exec(query))) {
    urlParams[decode(match[1])] = decode(match[2]);
  }

  if(query===""){
    urlParams.exchange = "Lbank"
    urlParams.symbol = "ETH/USDT"
    urlParams.pair = "eth_usdt"
  }else{
    urlParams.symbol = urlParams.pair.split("_")[0].toUpperCase() + "/" + urlParams.pair.split("_")[1].toUpperCase();
  }
})();

// console.log("urlParams: ", urlParams);

function getNextDailyBarTime(barTime) {
  const date = new Date(barTime * 1000);
  date.setDate(date.getDate() + 1);
  return date.getTime() / 1000;
}

var LbankSocket = new WebSocket("wss://www.lbkex.net/ws/V2/");

LbankSocket.onmessage = function (e) {
  var data = JSON.parse(e.data);
//  console.log("data: ", data);
  if (data.ping) {
    LbankSocket.send(
      JSON.stringify({
        action: "pong",
        pong: data.ping,
      })
    );
  } else if (data.kbar) {
    const channelString = urlParams.exchange + ":" + urlParams.symbol;
    const subscriptionItem = channelToSubscription.get(channelString);
    if (subscriptionItem === undefined) {
      return;
    }
    const lastDailyBar = subscriptionItem.lastDailyBar;
  //  console.log("lastDailyBar: ", lastDailyBar);
    const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

    let bar;
    if (new Date(data.kbar.t).getTime() >= nextDailyBarTime) {
      bar = {
        time: new Date(data.kbar.t).getTime(),
        low: data.kbar.l,
        high: data.kbar.h,
        open: data.kbar.o,
        close: data.kbar.c,
      };
    } else {
      bar = {
        ...lastDailyBar,
        high: Math.max(lastDailyBar.high, data.kbar.h),
        low: Math.min(lastDailyBar.low, data.kbar.l),
        close: data.kbar.c,
      };
    }
    subscriptionItem.lastDailyBar = bar;

    // send data to every subscriber of that symbol
    subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
  }
};

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  const channelString = symbolInfo.full_name;
  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscribeUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };
  channelToSubscription.set(channelString, subscriptionItem);

  LbankSocket.send(
    JSON.stringify({
      action: "subscribe",
      subscribe: "kbar",
      kbar: "day",
      pair: urlParams.pair,
    })
  );
//  console.log("channelToSubscription: ", channelToSubscription);
}

export function unsubscribeFromStream(subscriberUID) {
//  console.log("subscriberUID: ", subscriberUID);
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      // remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // unsubscribe from the channel, if it was the last handler
        if (urlParams.exchange == "Lbank") {
          LbankSocket.send(
            JSON.stringify({
              action: "unsubscribe",
              pair: "eth_usdt",
            })
          );
        }
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}
