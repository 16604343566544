import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useToken from "../components/useToken";
import LoadingSpinner from "./Loader";
import { useState } from "react";
import InfoModal from "./InfoModal";

const Header = ({ activeTab, isLoading, signupOTP }) => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [showPhoneVerifiedRequiredModal, setshowPhoneVerifiedRequiredModal] =
    useState(false);

  // console.log("signupOTP",signupOTP)

  const logout = () => {
    localStorage.removeItem("is_two_factor_enabled");
    setToken("");
  };
  return (
    <header className="header">
      {isLoading && <LoadingSpinner />}

      <InfoModal
        show={showPhoneVerifiedRequiredModal}
        setShow={setshowPhoneVerifiedRequiredModal}
        title="First verify your phone number"
        body='To proceed please verify your phone number in your <a href="/myprofile">Profile</a>.'
      />

      <div className="container">
        <div className="top-header">
          <div className="logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="" />
            </Link>
          </div>

          <div className="nav-right">
            <nav className="main-nav" id="cssmenu">
              <div
                className={` ${
                  showMenu ? "menu-opened manu-overlay bg-overlay-anim" : ""
                }`}
                onClick={() => setShowMenu(!showMenu)}
              ></div>
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/" className={activeTab === "home" ? "active" : ""}>
                    HOME
                  </Link>
                </li>
                <li>
                  <Link
                    to="/buy"
                    className={activeTab === "buy" ? "active" : ""}
                  >
                    BUY
                  </Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          //  console.log(
                          //     "chal rha hai",
                          //     localStorage.getItem("is_mobile_verified")
                          //   );
                          if (
                            localStorage.getItem("is_mobile_verified") ===
                            "true"
                          ) {
                            //  console.log("+++++++");
                            history.push("/wallet");
                          } else {
                            //  console.log("------");
                            setshowPhoneVerifiedRequiredModal(true);
                          }
                        }}
                        className={activeTab === "wallet" ? "active" : ""}
                      >
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/myprofile"
                        className={activeTab === "myprofile" ? "active" : ""}
                      >
                        MY PROFILE
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={logout}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link
                        to="/login"
                        className={activeTab === "login" ? "active" : ""}
                      >
                        LOGIN
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/signup"
                        className={activeTab === "signup" ? "active" : ""}
                      >
                        SIGN UP
                      </Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
