import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import config from "../config";
import useToken from "./useToken";

export default function TwoFactorVerify() {
  const { token, setToken } = useToken();

  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");

  const [twoFactorOTP, setTwoFactorOTP] = useState("");

  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + token.access,
  };

  let number = localStorage.getItem("phone");
  let [dial, phone] = number.split(" ");

  // totp/two-factor-verify/

  const test2FAotp = () => {
    axios
      .post(
        `${config.URL}/totp/two-factor-code-verify/`,
        {
          phone: phone,
          dial_code: dial,
          token: twoFactorOTP,
        },
        {
          headers,
        }
      )
      .then((response) => {
        if (
          response.data.message ==
          "Logged in successfully with Google Auth code."
        ) {
          localStorage.setItem(
            "is_two_factor_enabled",
            response.data.data["is_two_factor_enabled"]
          );
          localStorage.setItem("first_name", response.data.data["first_name"]);
          localStorage.setItem("last_name", response.data.data["last_name"]);
          localStorage.setItem("email", response.data.data["email"]);
          localStorage.setItem("user_id", response.data.data["user_id"]);
          localStorage.setItem("kingdom", response.data.data["kingdom"]);
          localStorage.setItem("country", response.data.data["country"]);
          localStorage.setItem("phone", response.data.data["phone"]);
          localStorage.setItem("refer_code", response.data.data["refer_code"]);
          localStorage.setItem(
            "is_mobile_verified",
            response.data.data["is_phone_verified"]
          );
          localStorage.setItem(
            "is_email_verified",
            response.data.data["is_email_verified"]
          );
          localStorage.setItem(
            "reffreal_count",
            response.data.data["reffreal_count"]
          );
          setToken({
            access: response.data.data["access"],
            refresh: response.data.data["refresh"],
          });
          history.push("/buy");
        } else {
          setErrorMessage(response.data.message);
          console.log("response", response.data.message);
        }
      })
      .catch(console.log);
    // .then(() => setIsLoading(false));
  };

  return (
    <>
      {/* <Header activeTab="login" /> */}
      <div className="setBody">
        <main className="cowrie_code" id="cowrie_codeId">
          <section className="sec wallet-sec buy-sec cowrie_codeSection">
            <div className="container">
              <div className="cowrie_codeInner_container mx-auto">
                <div className="cowrie_codeInner">
                  <div
                    className="cowrie_codeInner_sets "
                    style={{ textAlign: "center" }}
                  >
                    <div className="cowrie_codeHeader">
                      <h3 className="wsuccess">Two Factor Authentication</h3>
                    </div>

                    <div className="code_lines">
                      <p className="withdLIne ">
                        Enter 6 digit code from the Google Authenticator app on
                        your phone.
                      </p>
                    </div>
                    <div className="code_lines">
                      <input
                        type="text"
                        placeholder="Code"
                        maxLength={6}
                        onChange={(e) => {
                          setTwoFactorOTP(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className=""
                      style={{ padding: "18px", textAlign: "start" }}
                    >
                      <p
                        className="feild-error-y text-center"
                        style={{ textAlign: "start", fontSize: "16px" }}
                      >
                        {errorMessage}
                      </p>
                    </div>

                    {twoFactorOTP.length === 6 ? (
                      <div className="float-left">
                        <button
                          className="custom-btn button_custom btn-sm"
                          onClick={() => {
                            test2FAotp();
                          }}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <div className="float-left">
                        <button className="custom-btn button_custom bg-secondary">
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                  {/* <div className="cowrie_codeInner_sets">
                  {twoFactorOTP.length === 6 ? (
                    <div className="cowrie_codeInner_sets__button">
                      <button
                        className="custom-btn button_custom"
                        onClick={() => {
                          test2FAotp();
                        }}
                      >
                        Disable 2FA
                      </button>
                    </div>
                  ) : (
                    <div className="cowrie_codeInner_sets__button">
                      <button className="custom-btn button_custom bg-secondary">
                        Disable 2FA
                      </button>
                    </div>
                  )}
                </div> */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
