import { useEffect } from "react";
import Datafeed from "./datafeed.js";

const Jschart = ({ pair, exchange }) => {
  const MySymbol =
    pair.split("_")[0].toUpperCase() + "/" + pair.split("_")[1].toUpperCase();
  useEffect(() => {
    console.log(window.TradingView);
    if (window.TradingView) {
      console.log("chintu");
      window.tvWidget = new window.TradingView.widget({
        symbol: exchange + ":" + MySymbol, // default symbol
        // symbol: "Lbank:ETH/USDT", // default symbol
        interval: "1D", // default interval
        fullscreen: true, // displays the chart in the fullscreen mode
        container: "tv_chart_container",
        datafeed: Datafeed,
        library_path: "/charting_library/",
      });
    }
  }, []);

  return <div id="tv_chart_container"></div>;
};

export default Jschart;
