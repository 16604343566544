import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const InfoModal = ({show, setShow, title, body}) => {
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <Modal show={show} onHide={handleClose}>
        <div className="modal-header modal-headerBlock">
          <h5 className="modal-title modal-headerTitle" id="exampleModalLabel">
            {title}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body modalBody">
          <p dangerouslySetInnerHTML={{
                __html: body,
              }}>
          </p>
        </div>
        {/* <div className="modal-footer set_btnBottom text-center">
        <button type="button" className="custom-btn" data-dismiss="modal">Close</button>
      </div> */}

        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
  );
}

export default InfoModal;
