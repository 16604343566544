// Make requests to CryptoCompare API
import config from "../../config";

export async function makeLbankSpotApiRequest(path) {
  console.log("path: ", path);
  try {
    const response = await fetch(
      `${config.URL}/trade/lbank-get-apis-gateway/${path}`
    );
    // const response = await fetch(
    //   `http://localhost:8000/trade/lbank-get-apis-gateway/${path}`
    // );
    // const response = await fetch(`http://192.168.1.197:8000/lbank/${path}`);
    console.log("+++++++&&&&&&&&$$$$$$", response);
    return response.json();
  } catch (error) {
    throw new Error(`Lbank spot request error: ${error.status}`);
  }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }

  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}
