import { useState, useEffect } from "react";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import Home from "./components/Home";
import Wallet from "./components/Wallet";
import Buy from "./components/Buy";
import Profile from "./components/Profile";
import Login from "./components/Login";
import Signup from "./components/Signup";
import useToken from "./components/useToken";
import FullVerification from "./components/fullVerification";
import UpdateInfo from "./components/updateInfo";
import ShareReferral from "./components/share-referral";
import config from "./config";
import SignupSuccess from "./components/SignupSuccess";
import ForgotPassword from "./components/ForgotPassword";
import TransactionHistory from "./components/TransactionHistory";
import PaymentSuccess from "./components/PaymentSuccess";
import PageNotFound from "./components/PageNotFound";
import PaymentUnsuccess from "./components/PaymentUnsuccess";
// import Kachra from "./components/Kachra";
import Chart from "./components/chart/Chart";
// import Jschart from "./components/jschart/Jschart";
import Withdraw from "./components/Withdraw";
import AddBankAccount from "./components/AddBankAccount";
import BankAccountActivated from "./components/BankAccountActivated";
import VerifyOTP from "./components/VerifyOTP";
import VerifyOTPsignup from "./components/VerifyOTPsignup";
import UpdateEmail from "./components/UpdateEmail";
import SendCowrie from "./components/SendCowrie";
import TwoFA from "./components/TwoFA";
import TwoFactorDisable from "./components/TwoFactorDisable";
import TwoFactorVerify from "./components/TwoFactorVerify";
import CowrieDeposite from "./components/CowrieDeposite";
import OTPNotRecived from "./components/OTPNotRecived";
import CowrieDepositHistory from "./components/CowrieDepositHistory";
import Staking from "./components/Staking";
import StakedTokens from "./components/StakedTokens";

function App() {
  const { token, setToken } = useToken();
  const history = useHistory();
  let time = 900000;
  let masterKey;
  let key = 0;
  const [userCountryName, setUserCountryName] = useState("xyz");
  const [referedCode, setReferedCode] = useState("xyz");

  // setUserCountryName();

  function setSessionTime() {
    let x = setTimeout(sessionOut, time);
    setUserCountryName(localStorage.getItem("country"));
    setReferedCode(localStorage.getItem("refered_code"));
    return x;
  }

  function sessionOut() {
    setToken("");
  }

  function incSessionTime() {
    if (key === 0) {
      clearTimeout(masterKey);
    } else {
      clearTimeout(key);
    }
    // console.log("called");
    key = setSessionTime();
  }

  return (
    <div
      className="App"
      onLoad={() => {
        masterKey = setSessionTime();
      }}
      onMouseDown={incSessionTime}
      onKeyDown={incSessionTime}
      onTouchStart={incSessionTime}
    >
      <Switch>
        <Route
          exact
          path="/"
          component={() => {
            window.location.href = config.HOME_PAGE_URL;
            return null;
          }}
        />
        <Route path="/home">
          <Home />
        </Route>

        <Route path="/login">
          <Login />
        </Route>

        <Route path="/signup">
          <Signup />
        </Route>

        <Route path="/wallet">
          <Wallet />
        </Route>

        <Route path="/buy">
          <Buy />
        </Route>

        <Route path="/myprofile">
          <Profile />
        </Route>

        <Route path="/full-verification">
          <FullVerification token={token} />
        </Route>

        <Route path="/update-info">
          <UpdateInfo />
        </Route>

        <Route path="/share-referral">
          <ShareReferral />
        </Route>

        <Route path="/signup-success">
          <SignupSuccess />
        </Route>

        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/transaction-history">
          <TransactionHistory />
        </Route>

        <Route path="/payment-success">
          <PaymentSuccess />
        </Route>

        <Route path="/payment-unsuccess">
          <PaymentUnsuccess />
        </Route>

        {/* <Route path="/kachra">
          <Kachra />
        </Route> */}

        <Route path="/chart">
          <Chart />
        </Route>

        <Route path="/zar-withdraw">
          <Withdraw />
        </Route>

        <Route path="/add-bank-account">
          <AddBankAccount />
        </Route>

        <Route path="/bank-account-activated">
          <BankAccountActivated />
        </Route>

        <Route path="/verify-otp/:carrier">
          <VerifyOTP />
        </Route>

        <Route path="/verify-otp-signup/:carrier">
          <VerifyOTPsignup />
        </Route>

        <Route path="/update-email">
          <UpdateEmail />
        </Route>

        <Route path="/cowrie-deposite">
          <CowrieDeposite />
        </Route>

        <Route path="/cowrie-deposit-history">
          <CowrieDepositHistory />
        </Route>

        {/* <Route path="/send-cowrie">
          <SendCowrie />
        </Route> */}

        <Route path="/send-cowrie">
          {referedCode != "ZAGGWTYM" ? (
            <SendCowrie />
          ) : (
            <Redirect to={"/wallet"} />
          )}
        </Route>

        {/* <Route path="/send-cowrie">
          {userCountryName != "South Africa" ? (
            <SendCowrie />
          ) : (
            <Redirect to={"/wallet"} />
          )}
        </Route> */}
        <Route path="/two-fa">
          <TwoFA />
        </Route>

        <Route path="/two-fa-disable">
          <TwoFactorDisable />
        </Route>

        <Route path="/two-fa-verify">
          <TwoFactorVerify />
        </Route>

        <Route path="/otp-not-send">
          <OTPNotRecived />
        </Route>

        <Route path="/staking">
          <Staking />
        </Route>

        <Route path="/staked-tokens">
          <StakedTokens />
        </Route>

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
