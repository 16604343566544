import { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import config from "../config";
import axios from "axios";

const Home = () => {
  const [data, setData] = useState("");
  const [ansDisplay, setAnsDisplay] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [kingdomList, setKingdomList] = useState([]);
  const [firstHalf,setFirstHalf] = useState([])
  const [secondHalf,setSecondHalf] = useState([])
  const [maxScore, setMaxScore] = useState(0)

  useEffect(() => {
    setIsLoading(true);
    setTimeout(()=>{
      setIsLoading(false)
    }, 30000)
    axios
      .get(`${config.URL}/setting/`)
      .then(({ data }) => {
        setData(data);
        setMaxScore(data.tokens_to_sell-data.token_available)
      })
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  useEffect(()=>{
    axios
    .get(`${config.URL}/fetch-kingdoms/`)
    .then((response) => {
    //  console.log('response: ', response.data.data['kingdoms']);
      // setKingdomList(response.data.data['kingdoms']);
      const sortedKingdom = response.data.data['kingdoms'].sort((a,b)=>{return b.total_purchase - a.total_purchase})
      // setMaxScore(sortedKingdom[0].total_purchase)
    //  console.log('sortedKingdom: ', sortedKingdom);
      const middleIndex = Math.ceil(sortedKingdom.length / 2);
      setFirstHalf(sortedKingdom.splice(0, middleIndex));
      setSecondHalf(sortedKingdom.splice(-middleIndex));
    })
    .catch((error) => {
     console.log(error);
    })
    .then(() => setIsLoading(false));
  },[])

  useEffect(()=>{
      // const middleIndex = Math.ceil(kingdomList.length / 2);
      // setFirstHalf(kingdomList.splice(0, middleIndex));
      // setSecondHalf(kingdomList.splice(-middleIndex));
    //  console.log('firstHalf: ', firstHalf);
    //  console.log('secondHalf: ', secondHalf);
    //  console.log('maxScore: ', maxScore)
  },[firstHalf,secondHalf,maxScore])

  const onFaqClick = (index, e) => {
  //  console.log("e: ", e);
    if (!ansDisplay[index]) {
      setAnsDisplay(
        Object.keys(ansDisplay).map((key, index) => (ansDisplay[key] = false))
      );
      setTimeout(() => {
        const element = document.getElementById(`accordion-${index}`);
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
        setTimeout(() => window.scrollBy(0, -100),500);

      }, 500);
    }

    setAnsDisplay((ansDisplay) => ({
      ...ansDisplay,
      [index]: !ansDisplay[index],
    }));


  };
  return (
    <div>
      <Header activeTab="home" isLoading={isLoading} />
      <section className="home-banner">
        <div className="container">
          <div className="row align-items-center banner_elements">
            <div className="col-md-5">
              <div className="banner-caps">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: data.top_section_title,
                  }}
                >
                  {/* <span className="w-light">Africa’s</span> <span className="text-yellow">most</span> <span className="text-red">reputable</span> <span className="text-green">crypto</span> <span className="text-blue">project</span> <span className="w-light">yet.</span> */}
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.top_section_description,
                  }}
                ></p>
                {/* <p className="small-text">
                  Founded by His Majesty - King Makhosoke II
                </p> */}
              </div>
            </div>
            <div className="col-md-7">
              <div className="banner-img">
                <OwlCarousel
                  className="owl-theme home-carousel"
                  loop
                  autoplay
                  margin={0}
                  items={1}
                  key={data.top_section_banner}
                >
                  <div className="item">
                    <div className="trending-cat-img">
                      <img src={config.URL + data.top_section_banner} alt="" />
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="sec vip-event"
        style={{
          backgroundImage: `url(${
            config.URL + data.cowrie_vip_event_section_background_image
          })`,
          backgroundSize: "100% 50%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <h2 className="sec-head">COWRIE VIP EVENT</h2>
          <h2 className="event-head">
            {parseInt(data.token_available)} <span className="w-light">OF</span>{" "}
            {data.tokens_to_sell}
            <br />
            <span className="w-light d-block">AVAILABLE</span>
          </h2>
          {/* <Link to="/buy" className="custom-btn">
            Buy Now
          </Link> */}
          <div className="mid-vedio">
            {data?.cowrie_vip_event_section_video &&
            data.cowrie_vip_event_section_video.includes("embed") ? (
              <iframe
                className="yt-embed-video"
                src={data.cowrie_vip_event_section_video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <div className="yt-embed-video">YouTube video player</div>
            )}
          </div>
          <Link to="/buy" className="custom-btn">
            JOIN THE VIP SALE
          </Link>
        </div>
      </section>

      <section className="sec how-it-works">
        <div className="container">
          <h2 className="sec-head">How It Works</h2>

          <div className="works-vedio">
            <OwlCarousel
              key={Math.random()}
              className="owl-theme works-carousel"
              loop
              nav
              autoplay
              margin={30}
              items={3}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                900: {
                  items: 3,
                },
                1000: {
                  items: 3,
                },
              }}
            >
              {/* <div className="mid-vedio">
            {data?.cowrie_vip_event_section_video &&
            data.cowrie_vip_event_section_video.includes("embed") ? (
              <iframe
                className="yt-embed-video"
                src={data.cowrie_vip_event_section_video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <div className="yt-embed-video">YouTube video player</div>
            )}
          </div> */}
              {data.howitworks?.map((item, index) => (
                <div className="item" key={index}>
                  <div className="trending-cat-img">
                    <iframe
                      src={item.video_sliders}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                  ></iframe>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
          <div className="text-center">
            <Link to="#" className="view-more">
              VIEW MORE
            </Link>
          </div>
        </div>
      </section>

      <section className="sec vip-offer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="trending-cat-img">
                {data.join_risk_section_banner && (
                  <img
                    src={config.URL + data.join_risk_section_banner}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="col-md-5">
              <div className="banner-caps">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: data.join_risk_section_title,
                  }}
                >
                  {/* <span className="w-light">Join the</span> <span className="text-yellow">risk</span> <span className="text-red">free</span> <span className="text-green">VIP</span> <span className="text-blue">offering.</span> */}
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.join_risk_section_description,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec news news-sec">
        <div className="container">
          <h2 className="sec-head">In The News</h2>
          <div className="news-slider">
            <OwlCarousel
              key={Math.random()}
              className="owl-theme news-carousel"
              loop
              nav
              autoplay
              margin={30}
              items={3}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                900: {
                  items: 3,
                },
                1000: {
                  items: 3,
                },
              }}
            >
              {data.news?.map((item, index) => (
                <div className="item" key={index}>
                  <div className="news-article">
                    <div className="news-logo">
                      <img src={config.URL + item.logo} alt="" loading="lazy" />
                    </div>
                    <div className="article-con">
                      <h4
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      ></h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))}
              {/*
                  <div className="item">
                      <div className="news-article">
                          <div className="news-logo">

                          </div>
                          <div className="article-con">
                                <h4>Article Title Here</h4>
                            <p>Some introduction text goes here. Yes, it’s truly the best coin ever...</p>
                          </div>
                      </div>
                  </div>
                  <div className="item">
                      <div className="news-article">
                          <div className="news-logo">
                          </div>
                          <div className="article-con">
                                <h4>Article Title Here</h4>
                            <p>Some introduction text goes here. Yes, it’s truly the best coin ever...</p>
                          </div>
                      </div>
                  </div>
                  <div className="item">
                      <div className="news-article">
                          <div className="news-logo">
                                <img src="assets/images/article-logo.png" alt="" />
                          </div>
                          <div className="article-con">
                                <h4>Article Title Here</h4>
                            <p>Some introduction text goes here. Yes, it’s truly the best coin ever...</p>
                          </div>
                      </div>
                  </div> */}
            </OwlCarousel>
          </div>
          <div className="text-center">
            <Link to="#" className="view-more">
              VIEW MORE
            </Link>
          </div>
        </div>
      </section>

      <section className="sec community-sec">
      <div className="container">
          <h2 className="sec-head text-uppercase">Community <span>Leaderboard</span></h2>
          <p>During the VIP Sale Offering the race is on.  When someone signs up, they nominate the Community Land that they would like to support.
On 24 September 2022, Heritage Day the results will be tallied up and the proportions and ensuing benefits to the land of the communities
in the Kingdoms Community Trust will be set in stone until the next race.</p>

          <p>Every year 30% of the COWRIE will be released to the Kingdom Community Trust. The Kingdom Community Trust will be utilised to uplift the
          grass roots people in the local communities of South Africa in the community lands in proportion to the results of the race.</p>

         <div className="row commonity-row">
         			<div className="col-md-6">
            		<div className="commonity-box">
              			<div className="table-responsive">
                 			<table width="100%" border="0" cellSpacing="0" cellPadding="0">
                      <tbody>
                        {firstHalf.map((kingdom,index)=>{
                          return(
                                <tr key={index}>
                                  <td width="5%">{index+1}</td>
                                  <td width="42%">{kingdom.name}</td>
                                  <td width="25%"><span className="prog-st" style={{ width:((kingdom.total_purchase * 100)/maxScore)<=1?1+"%":(kingdom.total_purchase * 100)/maxScore+"%"}}></span></td>
                                  <td style={{textAlign:'right'}}>{((kingdom.total_purchase * 100)/maxScore).toFixed(2)}%</td>
                                  {/* <td>{Math.trunc(kingdom.total_purchase)}</td> */}
                                  {/* <td>{(kingdom.total_purchase * 100)/maxScore}</td> */}
                                </tr>
                          )
                        })}
                      </tbody>
                     </table>

                 </div>
              </div>
            </div>
            <div className="col-md-6">
            		<div className="commonity-box">
              			<div className="table-responsive">
                 			<table width="100%" border="0" cellSpacing="0" cellPadding="0">
                      <tbody>
                      {secondHalf.map((kingdom,index)=>{
                          return(
                                <tr key={index}>
                                  <td width="5%">{index+secondHalf.length+1}</td>
                                  <td width="42%">{kingdom.name}</td>
                                  <td width="25%"><span className="prog-st" style={{ width:((kingdom.total_purchase * 100)/maxScore)<=1?1+"%":(kingdom.total_purchase * 100)/maxScore+"%"}}></span></td>
                                  <td style={{textAlign:'right'}}>{((kingdom.total_purchase * 100)/maxScore).toFixed(2)}%</td>
                                  {/* <td>{Math.trunc(kingdom.total_purchase)}</td> */}
                                  {/* <td>{(kingdom.total_purchase * 100)/maxScore}</td> */}

                                </tr>
                          )
                        })}
                      </tbody>
                     </table>

                 </div>
              </div>
            </div>
         </div>
      </div>
      </section>

      <section className="sec news faq">
        <div className="container">
          <h2 className="sec-head">FAQ</h2>
          <div className="news-slider">
            <ul className="accordion-list">
              {data.faq?.map((item, index) => (
                <li
                  key={index}
                  id={"accordion-" + index}
                  className={ansDisplay[index] ? "active" : ""}
                  onClick={(e) => onFaqClick(index, e)}
                >
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: item.ques,
                    }}
                  ></h3>
                  {/* style={ansDisplay[index] ? {}:{display:"none"}} */}
                  <div
                    className={`answer ${
                      ansDisplay[index] ? "slidedown" : "slideup"
                    }`}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.ans,
                      }}
                    ></p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="sec collection">
        <div className="container">
          <p>Our Partners | Ozakwethu</p>
          {/* <OwlCarousel
            key={Math.random()}
            className="owl-theme news-carousel"
            loop
            nav
            autoplay
            margin={30}
            items={3}
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 2,
              },
              900: {
                items: 3,
              },
              1000: {
                items: 3,
              },
            }}
          >
            {data.collaboration?.map((item, index) => (
              <div className="item" key={index}>
                <div className="collection-in">
                  <img
                    src={config.URL + item.logo}
                    alt={`collaboration-img-${index}`}
                  />
                </div>
              </div>
            ))}
          </OwlCarousel> */}
          <img src="assets/images/partners-homepage.png" />
        </div>
      </section>
      <Footer data={data} footerHtml={data.footer} />
    </div>
  );
};

export default Home;
