import { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import useToken from "./useToken";
import config from "../config";
import axios from "axios";

const AddBankAccount = ({}) => {
  const { token, setToken } = useToken();
  const history = useHistory();
  if (!token) {
    history.push("/login");
  }

  const [activeStep, setActiveStep] = useState(0);
  const next = () => setActiveStep((active) => active + 1);
  const prev = () => setActiveStep((active) => active - 1);
  const [banks, setBanks] = useState([]);
  const [bankDetailID, setBankDetailID] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOptSent] = useState(false);
  const [counter, setCounter] = useState(15);

//  console.log("Parent running counter 1: ", counter);
  // setCounter(14)
  //console.log("Parent running counter 2: ", counter);

  // {'1': "Current"),
  // {'2': "Savings"),
  // {'3': "Transmission"),
  // {'4': "Bond"),
  // {'6': "Subscription"),

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    setTimeout(()=>{
      setIsLoading(false)
    }, 30000)

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };

    axios
      .get(`${config.URL}/fetch-bank-name/`, {
        headers: headers,
      })
      .then(({ data }) => setBanks(data.data))
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Header activeTab="addBankAccount" isLoading={isLoading}  />

      <section className="sec wallet-sec buy-sec">
        <div className="container">
          {activeStep === 0 && (
            <AddAccountInfoMessage
              next={next}
              prev={prev}
              token={token}
              setToken={setToken}
            />
          )}
          {activeStep === 1 && (
            <AddAccountForm
              next={next}
              prev={prev}
              token={token}
              setToken={setToken}
              banks={banks}
              setIsLoading={setIsLoading}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setBankDetailID={setBankDetailID}
            />
          )}
          {activeStep === 2 && (
            <AddAccountSuccess
              next={next}
              prev={prev}
              token={token}
              setToken={setToken}
              otpSent={otpSent}
              setOptSent={setOptSent}
              counter={counter}
              setCounter={setCounter}
              // sendOTP={sendOTP}
              bankDetailID={bankDetailID}
            />
          )}
        </div>
      </section>

      <Footer />
    </>
  );
};

function AddAccountInfoMessage(props) {
  return (
    <div className="setting-box">
      <div className="success_element">
        <div className="element_bank">
          <div className="bank_record">
            <div className="bank_tilte set_center">
              <h2 className="wsuccess ">
                Add a ZAR <br /> Bank Account
              </h2>
            </div>
            <div className="note">
              <p>
                Please ensure you only add a South African bank account that is
                in your own name
              </p>
            </div>
            <div className="nextBtn">
              <a
                href="#"
                className="custom-btn button_custom Hbtn"
                onClick={(e) => {
                  e.preventDefault();
                  props.next();
                }}
              >
                NEXT
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AddAccountForm(props) {
  const account_type = [
    { id: "1", type: "Current" },
    { id: "2", type: "Savings" },
    { id: "3", type: "Transmission" },
    { id: "4", type: "Bond" },
    { id: "6", type: "Subscription" },
  ];
//  console.log("props: ", props.banks);
  const [bankID, setBankID] = useState(props.banks[0]?.id);
  const [accountHolder, setAccountHolder] = useState("");
  const [accounttype, setAccounttype] = useState(account_type[0].id);
  const [accountNumber, setAccountNumber] = useState("");

  const handleSubmit = () => {
    if (!accountHolder) {
      props.setErrorMessage("Please enter a account holder name");
      return;
    } else if (!accountNumber) {
      props.setErrorMessage("Please enter a account number");
      return;
    }

  //  console.log("bankName: ", bankID);
  //  console.log("Yeah", accountHolder);
  //  console.log("accounttype: ", accounttype);
  //  console.log("branchCode: ", branchCode);
  //  console.log("accountNumber: ", accountNumber);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + props.token.access,
    };

    const request_payload = {
      bank: bankID,
      account_number: accountNumber,
      account_holder_name: accountHolder,
      account_type: accounttype,
    };

    props.setIsLoading(true);

    axios
      .post(`${config.URL}/bank-detail/`, request_payload, { headers })
      .then((response) => {
      //  console.log("response: ", response);
        props.setBankDetailID(response.data.data.bank_detail_id);
        props.next();
      })
      .catch((error) => {
       console.log("error: ", error);
      })
      .then(() => {
        props.setIsLoading(false);
      });
  };

  return (
    <div className="setting-box set-pb">
      <div className="success_element">
        <div className="element_bank">
          <div className="bank_record">
            <div className="bank_tilte set_center">
              <h2 className="wsuccess ">
                Add a ZAR <br /> Bank Account
              </h2>
            </div>
            <div className="bankSelect">
              <div className="bankOUterblock form_bankid">
                <form action="#" className="formBank">
                  <label>Bank Name</label>
                  <select
                    name="bank"
                    id="bank"
                    onChange={(e) => {
                      setBankID(e.target.value);
                    }}
                  >
                    {props.banks.map((bank, index) => {
                      return <option key={index} value={bank.id}>{bank.name}</option>;
                    })}
                  </select>
                </form>
              </div>

              <div className="bankOUterblock form_bankid">
                <label className="set_lable">Account type</label>
                <select
                  name="banktype"
                  id="banktype"
                  onChange={(e) => {
                    setAccounttype(e.target.value);
                  }}
                >
                  {account_type.map((a, i) => {
                    return (
                      <option key={i} value={a.id}>
                        {a.type}
                      </option>
                    );
                  })}
                  {/* <option value="saab">Saab</option>
                      <option value="opel">Opel</option>
                      <option value="audi">Audi</option> */}
                </select>
              </div>

              <div className="bankOUterblock form_bankid">
                <label>Account holder</label>
                <div className="amountElements">
                  <div className="amountWourter w-100">
                    <input
                      type="text"
                      className="amount_withdraw w-100 account_blocks"
                      required
                      placeholder="Enter account holder name"
                      value={accountHolder}
                      onChange={(e) => {
                        setAccountHolder(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="bankOUterblock form_bankid">
                <label className="set_lable">Account number</label>
                <div className="amountElements">
                  <div className="amountWourter w-100">
                    <input
                      type="text"
                      className="amount_withdraw w-100 account_blocks"
                      required
                      placeholder="Enter account number"
                      value={accountNumber}
                      onChange={(e) => {
                        let account_number = e.target.value
                        account_number = account_number.replace(/\D/g, '');
                        setAccountNumber(account_number);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="buy-price pt-4">
                <p className="log-error">{props.errorMessage}</p>
              </div>
              <div className="buy-btn unlockLayerBtn btnGotitOuter">
                <div className="nextBtn-wrap">
                  <a
                    href="#"
                    className="custom-btn button_custom"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   props.next();
                    // }}
                    onClick={handleSubmit}
                  >
                    Add Bank Account
                  </a>
                  {/* <button type="submit" className="custom-btn btn-full">Add Bank Account</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AddAccountSuccess(props) {
  // const sendOTP = () => {
  //   props.setOptSent(true);
  //   // const headers = {
  //   //   "Content-Type": "application/json",
  //   //   Authorization: "Bearer " + token.access,
  //   // };
  //   // axios
  //   //   .get(`${config.URL}/trade/withdrawal-view/`, { headers })
  //   //   .then((response) => {
  //   //    console.log("response: ", response);
  //   //   })
  //   //   .catch((error) => {
  //   //    console.log("error: ", error);
  //   //   });

  //   const myInterval = setInterval(() => {
  //     if (counter === 0) {
  //       clearInterval(myInterval);
  //       props.setOptSent(false);
  //     }else{
  //       counter--
  //     }
  //   }, 1000);
  // };
  //console.log(counter);

  // let counter= 15;
  // const [counter, setCounter] = useState(15);
  const resendMail = () => {
    props.setOptSent(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + props.token.access,
    };
    const request_payload = {
      bank_detail_id: props.bankDetailID,
    };
  //  console.log('"bank_detail_id": ', props.bankDetailID);

    axios
      .post(`${config.URL}/resend-bank-detail-verify-mail/`, request_payload, {
        headers,
      })
      .then((response) => {
      //  console.log("response: ", response);
      })
      .catch((error) => {
       console.log("error: ", error);
      });
  };
  // const myInterval = setInterval(() => setCounter((counter) => {
  //     if(counter === 0){
  //       clearInterval(myInterval);
  //       props.setOptSent(false);
  //       return 0;
  //     } else {
  //       return counter-=1
  //     }
  //   }
  // ), 1000)

  // const time = (
  //   <div>
  //     RESEND MAIL
  //     <br />
  //     in 00:{" "}
  //     {counter.toLocaleString("en-US", {
  //       minimumIntegerDigits: 2,
  //       useGrouping: false,
  //     })}{" "}
  //     SEC
  //   </div>
  // );
  return (
    <div className="setting-box">
      <div className="success_element">
        <div className="successOuter">
          <div className="successImage">
            <img src="assets/images/add-bank-success.svg" alt="img" />
          </div>
          <div className="successTitle pt-4">
            <h3 className="wsuccess">Email Authorization Sent</h3>
            <p className="withdLIne ">
              An email with a link to authorize linking of
              <br />a new bank account has been sent to your
              <br />
              registered email address.
            </p>
            <p className="withdLIne">
              Please check your email account and click
              <br />
              on the link to complete.
            </p>
          </div>
          <div className="buy-btn unlockLayerBtn">
            <div className="unlockLayerBlock wth_btns">
              <div className="HomeBtn">
                {/* <a href="/" className="custom-btn button_custom Hbtn">HOME</a> */}
              </div>
            </div>
          </div>
          <div className="buy-btn unlockLayerBtn">
            <div className="unlockLayerBlock wth_btns">
              <div className="HomeBtn">
                <button
                  className="custom-btn button_custom Hbtn"
                  onClick={resendMail}
                  disabled={props.otpSent}
                  style={props.otpSent ? { background: "gray" } : {}}
                >
                  {props.otpSent ? "Sent" : "RESEND MAIL"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBankAccount;
