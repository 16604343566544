import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import useToken from "./useToken";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import handleError from "./handleError";

const TransactionHistory = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [cowrieTransactions, setCowrieTransactions] = useState([]);
  const [zarTransactions, setZarTransactions] = useState([]);
  //  const referralList1 =
  //  [
  //    {
  //      created_date:"10 June 2033",
  //      name:"Chintu",
  //      cowrie_token:"fgdf654h"
  //     },
  //     {
  //       created_date:"10 June 2033",
  //       name:"Chintu",
  //       cowrie_token:"fgdf654h"
  //     },
  //   {
  //     created_date:"10 June 2033",
  //     name:"Chintu",
  //     cowrie_token:"fgdf654h"
  //   } ];

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    //console.log('config.URL: ', config.URL);
    //console.log('headers: ', headers);
    axios
      .get(`${config.URL}/fetch-payment-detail-view/`, { headers: headers })
      .then(({ data }) => {
        //  console.log("data", data);
        setTransactions(data);
      })
      .catch(function (error) {
        handleError(error, setToken);
      });
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    //console.log('config.URL: ', config.URL);
    //console.log('headers: ', headers);
    axios
      .get(`${config.URL}/trade/fetch-cowrie-withdrawal-record-view/`, {
        headers: headers,
      })
      .then(({ data }) => {
        // console.log("data related to cowrie withdrawal", data);
        setCowrieTransactions(data);
      })
      .catch(function (error) {
        handleError(error, setToken);
      });
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.access,
    };
    //console.log('config.URL: ', config.URL);
    //console.log('headers: ', headers);
    axios
      .get(`${config.URL}/trade/fetch-zar-withdrawal-record-view/`, {
        headers: headers,
      })
      .then(({ data }) => {
        // console.log("data related to zar withdrawal", data);
        setZarTransactions(data);
      })
      .catch(function (error) {
        handleError(error, setToken);
      });
  }, []);

  if (!token) {
    history.push("/login");
  }

  const [conversionPrice, setConversionPrice] = useState(1.0);
  const [myCowrieToken, setMyCowrieToken] = useState(0.0);
  const [isLoading, setIsLoading] = useState(false);
  const first_name = localStorage.getItem("first_name");
  //  console.log("first_name: ", first_name);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchTokenPrice = () => {
    axios
      .get(`${config.URL}/fetch-token-price-and-fee/`)
      .then(({ data }) => {
        setConversionPrice(data.data.token_price);
      })
      .catch(console.log);
  };

  useEffect(() => {
    //   setIsLoading(true)
    //   fetchTokenPrice();
    //   const headers = {
    //      "Content-Type": "application/json",
    //      Authorization: "Bearer " + token.access,
    //    };
    //   axios
    //     .get(`${config.URL}/fetch-my-cowrie-token/`, {
    //        headers:headers
    //     })
    //     .then(({ data }) => {
    //      setMyCowrieToken(data.cowrie_token);
    //     })
    //     .catch(function (error) {
    //         if (error.response.status == 401) {
    //         setToken("");
    //         } else {
    //        console.log('error.response.data.message: ', error.response.data.message);
    //         // alert(error.response.data.message);
    //         }
    //      })
    //     .then(() => setIsLoading(false));
  }, []);
  return (
    <div>
      <Header activeTab="wallet" isLoading={isLoading} />
      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span className="back-btn" onClick={history.goBack}>
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul
                className={`${showMenu ? "slidedown" : "slideup"}`}
                // style={{display:"block"}}
                // style={{display:showMenu ? "block":"none"}}
              >
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {/* <ul>
                            <li><Link href="#">Overview</Link></li>
                            <li><Link href="#">National-Level</Link></li>
                            <li><Link href="#">Sector-Level</Link></li>
                            <li><Link href="#">Governance</Link></li>
                        </ul> */}

                {token ? (
                  <>
                    <li>
                      <Link to="/wallet" className="active">
                        WALLET
                      </Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup">SIGN UP</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <div className="mov-h wallet-top">
        <div className="container">
          <div className="top-header wallet-header">
            <div className="wallet-head trans-head">
              <h2>Transaction History</h2>
              <p>Hello {first_name}</p>

              <div className="tabitems">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Payment
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Cowrie
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#contact"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Zar
                    </a>
                  </li>
                </ul>
                <div class="tab-content setoverflow" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <td align="center" valign="middle">
                            <strong>Reference ID</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payment Status</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payment Method</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Amount</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Fees</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Is Cowrie Transfered</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Created Date</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((data, index) => (
                          <tr key={index}>
                            <td align="center" valign="middle">
                              {data.reference_id}
                            </td>
                            <td align="center" valign="middle">
                              {data.payment_status}
                            </td>
                            <td align="center" valign="middle">
                              {data.payment_method}
                            </td>
                            <td align="center" valign="middle">
                              {data.amount}
                            </td>
                            <td align="center" valign="middle">
                              {data.fees}
                            </td>
                            <td align="center" valign="middle">
                              {data.is_cowrie_transfered ? (
                                <i className="fas fa-check"></i>
                              ) : (
                                <i className="fas fa-times"></i>
                              )}
                            </td>
                            <td align="center" valign="middle">
                              {new Date(data.created_date).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <td align="center" valign="middle">
                            <strong>Reference ID</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Wallet Address</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Currency</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payment Status</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Network Chain</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Amount</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Fees</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Token Trans Hash</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Created Date</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {cowrieTransactions.map((data, index) => (
                          <tr key={index}>
                            <td align="center" valign="middle">
                              {data.id}
                            </td>
                            <td align="center" valign="middle">
                              {data.wallet_address}
                            </td>
                            <td align="center" valign="middle">
                              {data.currency}
                            </td>
                            <td align="center" valign="middle">
                              {data.status}
                            </td>
                            <td align="center" valign="middle">
                              {data.network_chain}
                            </td>
                            <td align="center" valign="middle">
                              {data.amount}
                            </td>
                            <td align="center" valign="middle">
                              {data.fee}
                            </td>
                            <td align="center" valign="middle">
                              {data.token_trans_hash === null
                                ? "-"
                                : data.token_trans_hash}
                            </td>
                            <td align="center" valign="middle">
                              {new Date(data.created_date).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <td align="center" valign="middle">
                            <strong>Reference ID</strong>
                          </td>

                          <td align="center" valign="middle">
                            <strong>Currency</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payment Status</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Bank Detail</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payacc Transaction Code</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payacc Batch Id</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payacc Withdraw Id</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Amount</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Fees</strong>
                          </td>

                          <td align="center" valign="middle">
                            <strong>Created Date</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {zarTransactions.map((data, index) => (
                          <tr key={index}>
                            <td align="center" valign="middle">
                              {data.id}
                            </td>

                            <td align="center" valign="middle">
                              {data.currency}
                            </td>
                            <td align="center" valign="middle">
                              {data.status}
                            </td>
                            <td align="center" valign="middle">
                              {data.bank_detail}
                            </td>
                            <td align="center" valign="middle">
                              {data.payacc_transaction_code === null
                                ? "-"
                                : data.payacc_transaction_code}
                            </td>
                            <td align="center" valign="middle">
                              {data.payacc_batch_id === null
                                ? "-"
                                : data.payacc_batch_id}
                            </td>
                            <td align="center" valign="middle">
                              {data.payacc_withdraw_id === null
                                ? "-"
                                : data.payacc_withdraw_id}
                            </td>
                            <td align="center" valign="middle">
                              {data.amount}
                            </td>
                            <td align="center" valign="middle">
                              {data.fee}
                            </td>

                            <td align="center" valign="middle">
                              {new Date(data.created_date).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* <span>My combined wallet value</span>
              <h2>
                R {parseFloat(myCowrieToken * conversionPrice).toFixed(2)}
              </h2> */}
            </div>
            {/* <div className="wallet-tab-list">
              <ul>
                <li>
                  <Link to="/buy">
                    <img src="assets/images/icon-wallet-buy.svg" alt="" />
                    <span className="wallet-buy-btn">Buy</span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="assets/images/icon-wallet-sell.svg" alt="" />
                    <span>Sell</span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="assets/images/icon-wallet-send.svg" alt="" />
                    <span>Send</span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="assets/images/icon-wallet-receive.svg" alt="" />
                    <span>Receive</span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="assets/images/icon-wallet-play.svg" alt="" />
                    <span>Play2Win</span>
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>

      <section className="sec wallet-sec">
        <div className="container">
          <div className="setting-box" style={{ maxWidth: "750px" }}>
            <div className="wallet-head dest-head" style={{ width: "100%" }}>
              <h2>Transaction History</h2>
              <p>Hello {first_name}</p>

              <div className="tabitems">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="home-tab1"
                      data-toggle="tab"
                      href="#home1"
                      role="tab"
                      aria-controls="home1"
                      aria-selected="true"
                    >
                      Payment
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="profile-tab1"
                      data-toggle="tab"
                      href="#profile1"
                      role="tab"
                      aria-controls="profile1"
                      aria-selected="false"
                    >
                      Cowrie
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="contact-tab1"
                      data-toggle="tab"
                      href="#contact1"
                      role="tab"
                      aria-controls="contact1"
                      aria-selected="false"
                    >
                      Zar
                    </a>
                  </li>
                </ul>
                <div class="tab-content setoverflow" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home1"
                    role="tabpanel"
                    aria-labelledby="home-tab1"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <td align="center" valign="middle">
                            <strong>Reference ID</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payment Status</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payment Method</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Amount</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Fees</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Is Cowrie Transfered test</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Created Date</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((data, index) => (
                          <tr key={index}>
                            <td align="center" valign="middle">
                              {data.reference_id}
                            </td>
                            <td align="center" valign="middle">
                              {data.payment_status}
                            </td>
                            <td align="center" valign="middle">
                              {data.payment_method}
                            </td>
                            <td align="center" valign="middle">
                              {data.amount}
                            </td>
                            <td align="center" valign="middle">
                              {data.fees}
                            </td>
                            <td align="center" valign="middle">
                              {data.is_cowrie_transfered ? (
                                <i className="fas fa-check"></i>
                              ) : (
                                <i className="fas fa-times"></i>
                              )}
                            </td>
                            <td align="center" valign="middle">
                              {new Date(data.created_date).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile1"
                    role="tabpanel"
                    aria-labelledby="profile-tab1"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <td align="center" valign="middle">
                            <strong>Reference ID</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Wallet Address</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Currency</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payment Status</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Network Chain</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Amount</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Fees</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Token Trans Hash</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Created Date</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {cowrieTransactions.map((data, index) => (
                          <tr key={index}>
                            <td align="center" valign="middle">
                              {data.id}
                            </td>
                            <td align="center" valign="middle">
                              {data.wallet_address}
                            </td>
                            <td align="center" valign="middle">
                              {data.currency}
                            </td>
                            <td align="center" valign="middle">
                              {data.status}
                            </td>
                            <td align="center" valign="middle">
                              {data.network_chain}
                            </td>
                            <td align="center" valign="middle">
                              {data.amount}
                            </td>
                            <td align="center" valign="middle">
                              {data.fee}
                            </td>
                            <td align="center" valign="middle">
                              {data.token_trans_hash === null
                                ? "-"
                                : data.token_trans_hash}
                            </td>
                            <td align="center" valign="middle">
                              {new Date(data.created_date).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="contact1"
                    role="tabpanel"
                    aria-labelledby="contact-tab1"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <td align="center" valign="middle">
                            <strong>Reference ID</strong>
                          </td>

                          <td align="center" valign="middle">
                            <strong>Currency</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payment Status</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Bank Detail</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payacc Transaction Code</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payacc Batch Id</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Payacc Withdraw Id</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Amount</strong>
                          </td>
                          <td align="center" valign="middle">
                            <strong>Fees</strong>
                          </td>

                          <td align="center" valign="middle">
                            <strong>Created Date</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {zarTransactions.map((data, index) => (
                          <tr key={index}>
                            <td align="center" valign="middle">
                              {data.id}
                            </td>

                            <td align="center" valign="middle">
                              {data.currency}
                            </td>
                            <td align="center" valign="middle">
                              {data.status}
                            </td>
                            <td align="center" valign="middle">
                              {data.bank_detail}
                            </td>
                            <td align="center" valign="middle">
                              {data.payacc_transaction_code === null
                                ? "-"
                                : data.payacc_transaction_code}
                            </td>
                            <td align="center" valign="middle">
                              {data.payacc_batch_id === null
                                ? "-"
                                : data.payacc_batch_id}
                            </td>
                            <td align="center" valign="middle">
                              {data.payacc_withdraw_id === null
                                ? "-"
                                : data.payacc_withdraw_id}
                            </td>
                            <td align="center" valign="middle">
                              {data.amount}
                            </td>
                            <td align="center" valign="middle">
                              {data.fee}
                            </td>

                            <td align="center" valign="middle">
                              {new Date(data.created_date).toLocaleDateString(
                                "en-us",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* <span>Your combined wallet value</span>
                <h2>R {parseFloat(myCowrieToken*conversionPrice).toFixed(2)}</h2> */}
            </div>

            {/* <div className="wallet-tab-list dest-head">
               <ul>
                  <li>
                     <Link to="/buy">
                       			<img src="assets/images/icon-wallet-buy.svg" alt="" />
                          <span>Buy</span>
                     </Link>
                   </li>
                   <li>
                        <Link to="#">
                       			<img src="assets/images/icon-wallet-sell.svg" alt="" />
                          <span>Sell</span>
                       </Link>
                   </li>
                   <li>
                        <Link to="#">
                       			<img src="assets/images/icon-wallet-send.svg" alt="" />
                          <span>Send</span>
                       </Link>
                   </li>
                   <li>
                        <Link to="#">
                       			<img src="assets/images/icon-wallet-receive.svg" alt="" />
                          <span>Receive</span>
                       </Link>
                   </li>
                   <li>
                        <Link to="#">
                       			<img src="assets/images/icon-wallet-play.svg" alt="" />
                          <span>Play2Win</span>
                       </Link>
                   </li>
               </ul>
             </div> */}
            <div className="setting-list mt-4">
              <div className="wallet-row">
                <div className="trans-icon-img">
                  <img src="assets/images/wallet-purchased-icon.svg" alt="" />
                </div>
                <div className="wallet-con-det">
                  <p>Thank you for participating in the COWRIE VIP Event.</p>

                  <p>Your COWRIE are deployed and safe in your wallet.</p>

                  <p>
                    Your staking rewards will commence on 24 September 2022.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TransactionHistory;
