import { useState, useEffect } from "react";
import config from "../config";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import { Link, useHistory } from "react-router-dom";
import handleError from "./handleError";

const BankTransferPayment = (props) => {
    const [voucherPin, setVoucherPin] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [staticData, setStaticData] = useState(null);
    const [bankDetails, setBankDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState(localStorage.getItem('user_id'));
    const [paymentRefCopyText, setPaymentRefCopyText] = useState('Copy to clipboard')
    const [accNumberCopyText, setAccNumberCopyText] = useState('Copy to clipboard')
    const history = useHistory();
    const [accNameCopyText, setAccNameCopyText] = useState('Copy to clipboard')

  useEffect(() => {
    setTimeout(() => setPaymentRefCopyText('Copy to clipboard'), 5000)
  }, [paymentRefCopyText]);

  useEffect(() => {
    setTimeout(() => setAccNumberCopyText('Copy to clipboard'), 5000)
  }, [accNumberCopyText]);

  useEffect(() => {
    setTimeout(() => setAccNumberCopyText('Copy to clipboard'), 5000)
  }, [accNameCopyText]);

  useEffect(() => {
      props.setIsLoading(true)
      axios
        .get(`${config.URL}/setting/`)
        .then(({ data }) => setStaticData(data))
        .catch(console.log)
        // .then(() => props.setIsLoading(false));

      // props.setIsLoading(true)
      const request_payload = {
        amount: props.amount,
        fees: props.amount * props.feePercent / 100
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token.access,
      };

    //  console.log("request_payload: ", request_payload);
    //  console.log("headers: ", headers);

      axios
        .post(`${config.URL}/eft-bank-detail-view/`, request_payload, {
          headers: headers,
        })
        .then(({ data }) => setBankDetails(data.data))
        .catch(function (error) {
          if (error.response.status === 401) {
            handleError(error,props.setToken)
          } else {
            props.setErrorMessage(error.response.data.message);
            // alert(error.response.data.message);
          }
        })
        .then(() => props.setIsLoading(false));
    }, []);

    useEffect(() => {
    //  console.log("bankDetails: ",bankDetails)

    }, [bankDetails]);

    const onSubmit = async (e) => {
      e.preventDefault();
      props.setIsLoading(true);

      const request_payload = {
        amount: props.amount,
        fees: props.amount * props.feePercent / 100
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token.access,
      };

    //  console.log("request_payload: ", request_payload);
    //  console.log("headers: ", headers);

      let response = await axios
        .post(`${config.URL}/make-bank-transfer-payment/`, request_payload, {
          headers: headers,
        })
        .then((response) => {
        //  console.log("response: ", response);
        //  console.log("data: ", response.data);
          // alert("Reponse: " + response.data.message);
          // props.setActiveStep(6)
          history.push('/wallet')
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            handleError(error,props.setToken)
          } else {
            props.setErrorMessage(error.response.data.message);
            // alert(error.response.data.message);
          }
        })
        .then(() => {
          props.setIsLoading(false);
        });
    };
    return (
      <div className="setting-box">
        <div className="top-img succ-img">
          <img src="assets/images/icon-buy-1.svg" alt="" />
        </div>
        <div className="setting-list">
          <div className="setting-head">
            <span
              className="back-btn"
              onClick={() => {
                props.prev();
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </span>
            <h3>Manual EFT Transfer <span>Sign into your bank, create a recipient and make a deposit using the below information</span></h3>
            <p className="f-error">{props.errorMessage}</p>
            <a href="#" className="info-h desk-d">
              <i data-tip data-for="conversion-tip" className="fas fa-question-circle"></i>
            <ReactTooltip id="conversion-tip" place="bottom" effect="solid">
                {staticData?.login_page_tip}
            </ReactTooltip>
            </a>
          </div>
          <form
            name="bankTransferPaymentForm"
            id="bankTransferPaymentForm"
            method="POST"
            onSubmit={onSubmit}
          >
           {bankDetails?.ACCOUNT_NAME && <div className="buy-col">
              <div className="buy-con flex-wrap">

                <div className="set-user bank-trans-detail">
                  <label>Your Payment Reference</label>
                  <input
                    className="ref-no"
                    type="text"
                    placeholder={userId}
                    disabled
                  />
                  <img className="buy-copy-icon" src="assets/images/copy.png" onClick={() => {
                    navigator.clipboard.writeText(userId);
                    setPaymentRefCopyText("COPIED");
                  }}
                  data-tip
                  data-for="paymentRefTip"
                  alt=""
                   />
                  <ReactTooltip id="paymentRefTip" place="right" effect="solid">
                    {paymentRefCopyText}
                  </ReactTooltip>
                </div>

                <div className="set-user bank-trans-detail">
                  <label>Account Name</label>
                  <input
                    type="text"
                    placeholder={bankDetails.ACCOUNT_NAME}
                    disabled
                  />
                  <img className="buy-copy-icon" src="assets/images/copy.png" onClick={() => {
                    navigator.clipboard.writeText(bankDetails.ACCOUNT_NAME);
                    setAccNameCopyText("COPIED");
                  }}
                  data-tip
                  data-for="accNameTip"
                  alt=""
                  />
                  <ReactTooltip id="accNameTip" place="right" effect="solid">
                    {accNameCopyText}
                  </ReactTooltip>
                </div>

                <div className="set-user bank-trans-detail">
                  <label>Account Number</label>
                  <input
                    type="text"
                    placeholder={bankDetails.ACCOUNT_NUMBER}
                    disabled
                  />
                  <img className="buy-copy-icon" src="assets/images/copy.png" onClick={() => {
                    navigator.clipboard.writeText(bankDetails.ACCOUNT_NUMBER);
                    setAccNumberCopyText("COPIED");
                  }}
                  data-tip
                  data-for="accNumTip"
                  alt=""
                  />
                  <ReactTooltip id="accNumTip" place="right" effect="solid">
                    {accNumberCopyText}
                  </ReactTooltip>
                </div>

                <div className="set-user bank-trans-detail">
                  <label>Account Type</label>
                  <input
                    type="text"
                    placeholder={bankDetails.ACCOUNT_TYPE}
                    disabled
                  />
                </div>
                <div className="set-user">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    placeholder={bankDetails.BANK}
                    disabled
                  />
                </div>
                <div className="set-user bank-trans-detail">
                  <label>Branch Code</label>
                  <input
                    type="text"
                    placeholder={bankDetails.BRANCH_CODE}
                    disabled
                  />
                </div>

              </div>
            </div>}

            <div className="buy-btn">
              <button className="custom-btn" disabled={props.isLoading}>
                Confirm Payment
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }


export default BankTransferPayment;
