import { useState } from "react";
import { useHistory } from "react-router-dom";

export default function useToken() {
  const history = useHistory();
  // console.log("history,",history)
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    let token = userToken;
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken);
    if (token === "") {
      localStorage.setItem("country", "");
      history.push("/login");
    }
  };

  return {
    setToken: saveToken,
    token,
  };
}
