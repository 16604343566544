import { useState, useEffect } from "react";
import Footer from './Footer';
import Header from './Header';
import useToken from './useToken';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import config from "../config";
import axios from "axios";
import ReactTooltip from "react-tooltip";

const PaymentUnsuccess = () => {
    const { token, setToken } = useToken();
    const history = useHistory()
    if(!token) {
        history.push('/login')
    }

    const [isLoading, setIsLoading] = useState(false);
    const [staticData, setStaticData] = useState(null);

  useEffect(() => {
      setIsLoading(true)
      setTimeout(()=>{
        setIsLoading(false)
      }, 30000)
      axios
        .get(`${config.URL}/setting/`)
        .then(({ data }) => setStaticData(data))
        .catch(console.log)
        .then(() => setIsLoading(false));
    }, []);


  return (
    <>
    <Header activeTab="signup" />
    <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span className="back-btn">
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
                <Link to="/"><img src="assets/images/logo.png" alt="" /></Link>
            </div>
          </div>
        </div>
      </header>


      <section className="sec buy-sec login-sec">
            <div className="container">
                <div className="setting-box">
                    <div className="top-img desk-o"><img src="assets/images/icon-payment-failed.svg" alt="" /></div>
                    <div className="setting-list">

                    <div className="top-img mob-o"><img src="assets/images/icon-payment-failed.svg" alt="" /></div>

                    <div className="buy-col">
                        <h4 className="text-center">Payment Unsuccessful</h4>
                        <p style={{fontSize: "14px",fontWeight: "400", fontStyle: "italic", textAlign:"center"}}>Your payment attempt was unsuccessful,<br/>please try a different card or payment method.</p>
                    </div>
                    <div className="login-col">
                      <div className="btn-row" style={{justifyContent: "center"}}>
                            <Link to="/buy" className="custom-btn">Buy</Link>
                         {/* <div className="buy-btn">
                         </div> */}
                      </div>

                    </div>
                </div>
                </div>
            </div>
        </section>
    <Footer />
    </>
  );
}

export default PaymentUnsuccess;
