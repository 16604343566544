import { Link, useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
// import validator from "validator";
import Footer from "./Footer";
import Header from "./Header";
import useToken from "./useToken";
import config from "../config";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";

const Signup = () => {
  const { token, setToken } = useToken();
  const history = useHistory();
  const { search } = useLocation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const referal_code = query.get("referal_code");
  const local_referal_code = localStorage.getItem("referal_code");
  const [showMenu, setShowMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [kingdomList, setKingdomList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countryInfo, setCountryInfo] = useState([]);
  const [key, setKey] = useState("");

  let selectOptions = [];

  if (referal_code !== null) {
    localStorage.setItem("referal_code", referal_code);
  }

  if (token) {
    history.push("/");
  }

  const getKingdomData = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/fetch-kingdoms/`)
      .then((response) => {
        setKingdomList(response.data.data["kingdoms"]);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => setIsLoading(false));
  };

  const [staticData, setStaticData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .get(`${config.URL}/setting/`)
      .then(({ data }) => setStaticData(data))
      .catch(console.log)
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getKingdomData();
  }, []);

  useEffect(() => {
    console.log("running");
    axios
      .get(`${config.URL}/fetch-country-dail-code/`)
      .then(({ data }) => {
        console.log("country code", data.data);
        setCountryInfo(data.data);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  selectOptions = countryInfo.map((data, index) => {
    return { label: data.phone, value: data.id };
  });

  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [firstNameValid, setFirstNameValid] = useState(false);
  const [surNameValid, setSurNameValid] = useState(false);
  const [fnameError, setfNameError] = useState("");
  const [lnameError, setlNameError] = useState("");

  const [step0Btn, setStep0Btn] = useState(false);

  const [userCountryCode, setUserCountryCode] = useState("224");
  const [userCountry, setUserCountry] = useState("224");
  const [userDialCode, setUserDialCode] = useState("27");
  const [countryError, setCountryError] = useState("");
  const [stepCBtn, setStepCBtn] = useState(false);

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumber2, setMobileNumber2] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [step1Btn, setStep1Btn] = useState(false);

  const [password1, setpaaword1] = useState("");
  const [password2, setpaaword2] = useState("");
  const [passwordText, setPasswordText] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [step2Btn, setStep2Btn] = useState(false);

  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [emailError, setEmailError] = useState("");
  const [step3Btn, setStep3Btn] = useState(false);

  const [nationalId, setNationalId] = useState("");
  const [nationalIdError, setNationalIdError] = useState("");
  const [step4Btn, setStep4Btn] = useState(false);

  const [kingdomId, setKingdomId] = useState("");
  const [kingdomIdError, setKingdomIdError] = useState("");
  const [step5Btn, setStep5Btn] = useState(false);

  const [referralId, setReferralId] = useState("");

  const [registerError, setRegisterError] = useState("");

  const [isreferralrequire, setIsreferralRequire] = useState(true);
  const [isreferralError, setIsreferralError] = useState("");

  const [step7Btn, setStep7Btn] = useState(false);
  const [captCha, setCaptCha] = useState(" ");

  useEffect(() => {
    console.log("userCountryCode: ", userCountryCode);
  }, [userCountryCode]);

  const next = () => {
    if (activeStep === 4) {
      if (userCountry === "224") {
        setActiveStep((active) => active + 1);
      } else {
        setActiveStep((active) => active + 3);
      }
    } else {
      setActiveStep((active) => active + 1);
    }
  };

  const prev = () => {
    if (activeStep === 7) {
      if (userCountry === "224") {
        setActiveStep((active) => active - 1);
      } else {
        setActiveStep((active) => active - 3);
      }
    } else {
      setActiveStep((active) => active - 1);
    }
  };

  useEffect(() => {
    setTimeout(() => setRegisterError(""), 5000);
  }, [registerError]);

  useEffect(() => {
    console.log("user country", userCountry);
  }, [userCountry]);

  //UserCountry
  useEffect(() => {
    const local_referal_code = localStorage.getItem("referal_code");
    if (local_referal_code) {
      setReferralId(local_referal_code);
    }
  }, []);

  const firstSurnameNull = (firstName, surName) => {
    if (firstName === "" || surName === "") {
      setStep0Btn(false);
    } else {
      setStep0Btn(true);
    }
  };

  const validateFirstName = (fname) => {
    const regex = /^[A-Za-z ]{3,}$/;

    setFirstName(fname);
    if (regex.test(fname) === true) {
      if (fname.length >= 3) {
        setFirstNameValid(true);
        setfNameError("");
      }
    } else {
      if (fname.length < 3 && regex.test(fname) === false) {
        setFirstNameValid(false);
        setfNameError(
          "Name must have more than 3 characters and Numbers are not allowed"
        );
      } else if (fname.length < 3) {
        setFirstNameValid(false);
        setfNameError("Name must have more than 3 characters");
      } else {
        setFirstNameValid(false);
        // setStep0Btn(false);
        setfNameError(" Numbers are not allowed");
      }
    }
  };

  const validateSurName = (sname) => {
    const regex = /^[A-Za-z ]{3,}$/;

    setSurName(sname);
    if (regex.test(sname) === true) {
      if (sname.length >= 3) {
        setSurNameValid(true);
        setlNameError("");
      }
    } else {
      if (sname.length < 3 && regex.test(sname) === false) {
        setSurNameValid(false);
        setlNameError(
          "Surname must have more than 3 characters and Numbers are not allowed"
        );
      } else if (sname.length < 3) {
        setSurNameValid(false);
        setlNameError("Surname must have more than 3 characters");
      } else {
        setSurNameValid(false);
        // setStep0Btn(false);
        setlNameError(" Numbers are not allowed");
      }
    }
    // firstSurnameNull(firstName, surname);
  };

  const validateName = (fname, sname) => {};

  const validateMobileNumber = (mobile_number, mobile_number2) => {
    mobile_number = mobile_number.replace(/\D/g, "");
    mobile_number2 = mobile_number2?.replace(/\D/g, "");
    if (/^-?\d+$/.test(mobile_number) && mobile_number.length >= 5) {
      if (mobile_number !== mobile_number2) {
        setStep1Btn(false);
        setMobileError("Mobile Number Mismatch.");
      } else {
        setStep1Btn(true);
        setMobileError("");
      }
    } else {
      setStep1Btn(false);
      setMobileError("Please enter valid mobile number.");
    }
    setMobileNumber(mobile_number);
    setMobileNumber2(mobile_number2);
  };

  const validatePassword = (password1, password2) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const passwordLength = password1.length;
    const uppercasePassword = uppercaseRegExp.test(password1);
    const lowercasePassword = lowercaseRegExp.test(password1);
    const digitsPassword = digitsRegExp.test(password1);
    const specialCharPassword = specialCharRegExp.test(password1);
    const minLengthPassword = minLengthRegExp.test(password1);

    if (password1 !== password2) {
      setpaaword1(password1);
      setpaaword2(password2);
      setPasswordError("Password not matched.");
      setStep2Btn(false);
    } else if (
      passwordLength === 0 ||
      !uppercasePassword ||
      !lowercasePassword ||
      !digitsPassword ||
      !specialCharPassword ||
      !minLengthPassword
    ) {
      setpaaword1(password1);
      setpaaword2(password2);
      setPasswordError(
        "Password should be minumum 8 characters, at least one uppercase, one lowercase, one digit, one special character."
      );
      setStep2Btn(false);
    } else {
      setpaaword1(password1);
      setpaaword2(password2);
      setPasswordError("");
      setStep2Btn(true);
    }
  };

  const validateEmail = (email1, email2) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (email1 !== email2) {
      setEmail1(email1);
      setEmail2(email2);
      setEmailError("Email not matched.");
      setStep3Btn(false);
    } else if (!email1 || regex.test(email1) === false) {
      setEmail1(email1);
      setEmail2(email2);
      setEmailError("Email is not valid.");
      setStep3Btn(false);
    } else {
      setEmail1(email1);
      setEmail2(email2);
      setEmailError("");
      setStep3Btn(true);
    }
  };

  function checkLuhn(cardNo) {
    let nDigits = cardNo.length;

    let nSum = 0;
    let isSecond = false;
    for (let i = nDigits - 1; i >= 0; i--) {
      let d = cardNo[i].charCodeAt() - "0".charCodeAt();

      if (isSecond === true) d = d * 2;

      // We add two digits to handle
      // cases that make two digits
      // after doubling
      nSum += parseInt(d / 10, 10);
      nSum += d % 10;

      isSecond = !isSecond;
    }
    return nSum % 10 === 0;
  }

  const validateNationalId = (national_id) => {
    if (!national_id) {
      setStep4Btn(true);
      setNationalId("");
      setNationalIdError("");
    }
    if (national_id.length === 13 && /^[a-z0-9]+$/i.test(national_id)) {
      let year = parseInt(national_id.slice(0, 2));
      let month = parseInt(national_id.slice(2, 4));
      let day = parseInt(national_id.slice(4, 6));
      let gender = parseInt(national_id.slice(6, 10));
      let citizen = parseInt(national_id.slice(10, 11));

      if (
        (0 <= year <= 22 || 45 <= year <= 99) &&
        !((month < 1) & (month > 12)) &&
        !((day < 1) | (day > 31)) &&
        !(
          ((month === 4) | (month === 6) | (month === 9) | (month === 11)) &
          (day > 30)
        ) &&
        !((month === 2) & (day > 29)) &&
        0 <= gender <= 9999 &&
        0 <= citizen <= 1 &&
        checkLuhn(national_id)
      ) {
        setStep4Btn(true);
        setNationalId(national_id.toUpperCase());
        setNationalIdError("");
      } else {
        setNationalId(national_id.toUpperCase());
        setStep4Btn(false);
        setNationalIdError("Please enter valid 13 digit national ID.");
      }
    } else {
      setNationalId(national_id.toUpperCase());
      setStep4Btn(false);
      setNationalIdError("Please enter valid 13 digit national ID.");
    }
  };

  const validateKingdom = (kingdom_id) => {
    kingdomList.map((data) => {
      if (kingdom_id === data.id) {
        setKingdomId(kingdom_id);
      } else {
        setKingdomId("");
      }
    });
    if (kingdom_id !== "") {
      setKingdomId(kingdom_id);
      setKingdomIdError("");
      setStep5Btn(true);
    } else {
      setKingdomId("");
      setKingdomIdError("Choose a valid option.");
      setStep5Btn(false);
    }
  };

  const validateReferralId = (referralId) => {
    setReferralId(referralId);
    //  console.log("referral ID == ", referralId);
    if (referralId.length !== 0) {
      setIsreferralRequire(false);
    } else {
      setIsreferralError("Referral code is not required, You can skip.");

      setIsreferralRequire(true);
    }
  };

  const validateUnderstand = (checked) => {
    setStep7Btn(checked);
  };

  var ab;

  const userLogin = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);
    axios
      .post(`${config.URL}/login/`, {
        phone: mobileNumber,
        password: password1,
        dial_code: userCountryCode,
      })
      .then((response) => {
        //  console.log(response.data, "login response");
        localStorage.setItem("first_name", response.data.data["first_name"]);
        localStorage.setItem("last_name", response.data.data["last_name"]);
        localStorage.setItem("email", response.data.data["email"]);
        localStorage.setItem("user_id", response.data.data["user_id"]);
        localStorage.setItem("kingdom", response.data.data["kingdom"]);
        localStorage.setItem("country", response.data.data["country"]);
        localStorage.setItem("phone", response.data.data["phone"]);
        localStorage.setItem("refer_code", response.data.data["refer_code"]);
        localStorage.setItem(
          "is_mobile_verified",
          response.data.data["is_phone_verified"]
        );
        localStorage.setItem(
          "is_email_verified",
          response.data.data["is_email_verified"]
        );
        localStorage.setItem(
          "reffreal_count",
          response.data.data["reffreal_count"]
        );
        setToken({
          access: response.data.data["access"],
          refresh: response.data.data["refresh"],
        });

        console.log("key", ab);

        if (ab == 1) {
          history.push("/otp-not-send");
        } else if (ab == 2) {
          history.push("/verify-otp-signup/mobile");
        }
      })
      .catch((error) => {
        console.log(error, "login response");
        history.push("/login");
      })
      .then(() => setIsLoading(false));
  };

  const requestRegister = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 30000);

    let data = {};

    if (userCountry === "224") {
      data = {
        password: password1,
        confirm_password: password2,
        first_name: firstName,
        last_name: surName,
        phone: mobileNumber,
        dial_code: userCountryCode,
        captCha_value: captCha,
        user_other_detail: {
          kingdom: kingdomId,
          national_id: nationalId,
          country: userCountry,
        },
      };
    } else {
      data = {
        password: password1,
        confirm_password: password2,
        first_name: firstName,
        last_name: surName,
        phone: mobileNumber,
        dial_code: userCountryCode,
        captCha_value: captCha,
        user_other_detail: {
          country: userCountry,
        },
      };
    }

    console.log("request_data: ", data);
    if (email1) {
      data["email"] = email1;
      data["cemail"] = email2;
    }
    if (referralId) {
      data["user_other_detail"]["refered_code"] = referralId;
    }
    // console.log("request_data: ", data);

    axios
      .post(`${config.URL}/register/`, data)
      .then((response) => {
        console.log(response.data, "register response ");
        console.log(response.data.key, "register response key ");
        localStorage.removeItem("referal_code");
        ab = response.data.key;
        userLogin();
      })
      .catch((error) => {
        console.log(error.response.data.message, "register response ");
        setRegisterError(error.response.data.message);
      })
      .then(() => setIsLoading(false));
  };

  const RegisterUser = () => {
    requestRegister();
  };

  return (
    <>
      <Header activeTab="signup" isLoading={isLoading} />
      <header className="header mov-h">
        <div className="container">
          <div className="top-header">
            <span
              className="back-btn"
              onClick={activeStep ? prev : history.goBack}
            >
              <i className="fas fa-chevron-left"></i>
            </span>
            <div className="logo">
              <Link to="/">
                <img src="assets/images/logo.png" alt="" />
              </Link>
            </div>

            <span className="info-h">
              <i
                data-tip
                data-for="signup-mob-tip"
                className="fas fa-question-circle"
              ></i>
              <ReactTooltip id="signup-mob-tip" place="bottom" effect="solid">
                {staticData?.signup_page_tip && staticData.signup_page_tip}
              </ReactTooltip>
            </span>

            <nav className="main-nav" id="cssmenu1">
              <div
                className={` ${
                  showMenu ? "menu-opened manu-overlay bg-overlay-anim" : ""
                }`}
                onClick={() => setShowMenu(!showMenu)}
              ></div>
              <div
                className={`button ${showMenu ? "menu-opened" : ""}`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <ul className={`${showMenu ? "slidedown" : "slideup"}`}>
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/buy">BUY</Link>
                </li>

                {token ? (
                  <>
                    <li>
                      <Link to="/wallet">WALLET</Link>
                    </li>
                    <li>
                      <Link to="/myprofile">MY PROFILE</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setToken("")}>
                        LOGOUT
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to="/login">LOGIN</Link>
                    </li>
                    <li>
                      <Link to="/signup" className="active">
                        SIGN UP
                      </Link>
                    </li>
                  </>
                )}
                <li>
                  <Link
                    to={{ pathname: "https://community.mycowrie.org/" }}
                    target="_blank"
                  >
                    DISCOVER
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <>
        {activeStep === 0 && (
          <Step0
            next={next}
            firstName={firstName}
            surName={surName}
            validateName={validateName}
            validateFirstName={validateFirstName}
            firstNameValid={firstNameValid}
            surNameValid={surNameValid}
            fnameError={fnameError}
            lnameError={lnameError}
            validateSurName={validateSurName}
            nextBtn={step0Btn}
            staticData={staticData}
          />
        )}

        {activeStep === 1 && (
          <StepCountryCode
            next={next}
            prev={prev}
            countryInfo={countryInfo}
            mobileNumber={mobileNumber}
            mobileNumber2={mobileNumber2}
            validateMobileNumber={validateMobileNumber}
            setUserCountryCode={setUserCountryCode}
            setUserCountry={setUserCountry}
            setUserDialCode={setUserDialCode}
            userCountry={userCountry}
            stepCBtn={stepCBtn}
            mobileError={mobileError}
          />
        )}

        {activeStep === 2 && (
          <Step1
            next={next}
            prev={prev}
            mobileNumber={mobileNumber}
            mobileNumber2={mobileNumber2}
            validateMobileNumber={validateMobileNumber}
            step1Btn={step1Btn}
            mobileError={mobileError}
            userCountry={userCountry}
            countryInfo={countryInfo}
            userCountryCode={userCountryCode}
            setUserCountryCode={setUserCountryCode}
            selectOptions={selectOptions}
            userDialCode={userDialCode}
          />
        )}

        {activeStep === 3 && (
          <Step2
            next={next}
            prev={prev}
            password1={password1}
            password2={password2}
            passwordText={passwordText}
            validatePassword={validatePassword}
            setPasswordText={setPasswordText}
            step2Btn={step2Btn}
            passwordError={passwordError}
          />
        )}

        {activeStep === 4 && (
          <Step3
            next={next}
            prev={prev}
            email1={email1}
            email2={email2}
            validateEmail={validateEmail}
            step3Btn={step3Btn}
            emailError={emailError}
          />
        )}
        {activeStep === 5 && (
          <Step4
            next={next}
            prev={prev}
            nationalId={nationalId}
            validateNationalId={validateNationalId}
            step4Btn={step4Btn}
            nationalIdError={nationalIdError}
            userCountryCode={userCountryCode}
          />
        )}
        {activeStep === 6 && (
          <Step5
            next={next}
            prev={prev}
            kingdomList={kingdomList}
            kingdomId={kingdomId}
            validateKingdom={validateKingdom}
            step5Btn={step5Btn}
            kingdomIdError={kingdomIdError}
          />
        )}
        {activeStep === 7 && (
          <Step6
            next={next}
            prev={prev}
            validateReferralId={validateReferralId}
            referralId={referralId}
            // validateIsReferralId={validateIsReferralId}
            isreferralrequire={isreferralrequire}
            isreferralError={isreferralError}
          />
        )}
        {activeStep === 8 && (
          <Step7
            next={next}
            prev={prev}
            validateReferralId={validateReferralId}
            referralId={referralId}
            RegisterUser={RegisterUser}
            captCha={captCha}
            setCaptCha={setCaptCha}
            registerError={registerError}
            validateUnderstand={validateUnderstand}
            step7Btn={step7Btn}
          />
        )}
      </>
      <Footer />
    </>
  );
};

function Step0(props) {
  return (
    <section className="sec buy-sec login-sec">
      <div className="container">
        <div className="setting-box">
          <div className="top-img desk-o">
            <img src="assets/images/icon-signup1.svg" alt="" />
          </div>
          <div className="setting-list">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.next();
              }}
            >
              <div className="setting-head">
                {props.prev && (
                  <span
                    className="back-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      props.prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                )}
                <h3>
                  Hello, create your account<span>& join the adventure</span>
                </h3>
                <a href="#" className="info-h desk-d">
                  <i
                    data-tip
                    data-for="signup-tip"
                    className="fas fa-question-circle"
                  ></i>
                  <ReactTooltip id="signup-tip" place="bottom" effect="solid">
                    {props.staticData?.signup_page_tip}
                  </ReactTooltip>
                </a>
              </div>

              <div className="top-img mob-o">
                <img src="assets/images/icon-signup1.svg" alt="" />
              </div>

              <div className="buy-col">
                <h4>Let’s begin!</h4>
                <div className="buy-con flex-wrap">
                  <div className="set-user">
                    <label>NAME</label>
                    <input
                      type="text"
                      className="user-name"
                      placeholder="Firstname"
                      value={props.firstName}
                      onChange={(e) => props.validateFirstName(e.target.value)}
                    />
                  </div>
                  <div className="set-user">
                    <label>SURNAME</label>
                    <input
                      type="text"
                      className="user-name"
                      placeholder="Lastname"
                      value={props.surName}
                      onChange={(e) => props.validateSurName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="login-col">
                <p className="feild-error-y">{props.fnameError}</p>
                <p className="feild-error-y">{props.lnameError}</p>

                <div className="buy-btn">
                  {props.firstNameValid === true &&
                  props.surNameValid === true ? (
                    <button type="submit" className="custom-btn btn-full">
                      Next
                    </button>
                  ) : (
                    <button disabled className="custom-btn btn-full">
                      Next
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

function StepCountryCode(props) {
  return (
    <section className="sec buy-sec login-sec">
      <div className="container">
        <div className="setting-box">
          <div className="top-img desk-o">
            <img src="assets/images/icon-signup2.svg" alt="" />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.next();
            }}
          >
            <div className="setting-list">
              <div className="top-img mob-o">
                <img src="assets/images/icon-signup2.svg" alt="" />
              </div>
              <div className="buy-col from-head">
                <div className="step-heads">
                  <span
                    className="back-button"
                    onClick={(e) => {
                      e.preventDefault();
                      props.prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <div className="step-heads-title">Select Your Country</div>
                </div>

                <div className="buy-con flex-wrap">
                  <div className="set-user">
                    <label>Your Country</label>
                    <select
                      className="user-kingdom"
                      // value={props.userCountry}
                      onChange={(e) => {
                        console.log("country dropdown: ", e.target.value);
                        props.setUserCountry(e.target.value.split(",")[1]);
                        props.setUserCountryCode(e.target.value.split(",")[1]);
                        props.setUserDialCode(e.target.value.split(",")[0]);
                      }}
                    >
                      <option value={""}>Choose Any one</option>
                      {props.countryInfo.map((data, index) => {
                        return (
                          // <option key={index} value={[data.phone, data.name]} */}
                          <option
                            key={index}
                            value={[data.phone, data.id]}
                            selected={Number(props.userCountry) === data.id}
                          >
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="select-icon-r">
                      <i className="fas fa-sort-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="login-col">
                {/* <p className="feild-error-y">{props.mobileError}</p> */}
                <div className="buy-btn">
                  {!props.userCountry == "" ? (
                    <button type="submit" className="custom-btn btn-full">
                      Next
                    </button>
                  ) : (
                    <button disabled className="custom-btn btn-full">
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

function Step1(props) {
  const filterOption = (option, inputValue) =>
    (option.label.toString().match(inputValue) || []).length > 0;
  return (
    <section className="sec buy-sec login-sec">
      <div className="container">
        <div className="setting-box">
          <div className="top-img desk-o">
            <img src="assets/images/icon-signup2.svg" alt="" />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.next();
            }}
          >
            <div className="setting-list">
              <div className="top-img mob-o">
                <img src="assets/images/icon-signup2.svg" alt="" />
              </div>
              <div className="buy-col from-head">
                <div className="step-heads">
                  <span
                    className="back-button"
                    onClick={(e) => {
                      e.preventDefault();
                      props.prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <div className="step-heads-title">
                    What is your mobile number?
                  </div>
                </div>
                <div className="dropdown_layer">
                  {/* <div className="dropdown_values">
                    <label className="topItemvalue">COUNTRY CODE</label>
                    <select
                      name="datans"
                      id="dataid"
                      onChange={(e) => {
                        props.setUserCountryCode(e.target.value);
                      }}
                    >
                      {props.countryInfo.map((data, index) => {
                        return (
                          // <option key={index} value={[data.phone, data.name]} 
                          <option
                            key={index}
                            value={data.id}
                            selected={Number(props.userCountryCode) === data.id}
                          >
                            + {data.phone}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  <div className="dropdown_values set__hsa">
                    <label className="topItemvalue login_count">
                      COUNTRY CODE
                    </label>
                    <div className="count__itemOp">
                      <Select
                        className="count__item"
                        options={props.selectOptions}
                        defaultValue={
                          {
                            label: props.userDialCode,
                            value: props.userCountryCode,
                          } || "Select"
                        }
                        filterOption={filterOption}
                        placeholder="Select"
                        onChange={(opt) => {
                          console.log("value", opt.value);
                          props.setUserCountryCode(opt.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="buy-con flex-wrap w-100">
                    <div className="set-user">
                      <label>MOBILE NUMBER</label>
                      <input
                        type="text"
                        className="user-mob"
                        placeholder="000 000 000"
                        maxLength={14}
                        value={props.mobileNumber}
                        onChange={(e) =>
                          props.validateMobileNumber(
                            e.target.value,
                            props.mobileNumber2
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="buy-con flex-wrap">
                  <div className="set-user">
                    <label>RE-ENTER MOBILE NUMBER</label>
                    <input
                      type="text"
                      className="user-mob"
                      placeholder="000 000 000"
                      maxLength={14}
                      value={props.mobileNumber2}
                      onChange={(e) =>
                        props.validateMobileNumber(
                          props.mobileNumber,
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="login-col">
                <p className="feild-error-y">{props.mobileError}</p>
                <div className="buy-btn">
                  {props.step1Btn ? (
                    <button type="submit" className="custom-btn btn-full">
                      Next
                    </button>
                  ) : (
                    <button disabled className="custom-btn btn-full">
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

function Step2(props) {
  const [showPassword1, setShowPasword1] = useState(false);
  const [showPassword2, setShowPasword2] = useState(false);
  return (
    <section className="sec buy-sec login-sec">
      <div className="container">
        <div className="setting-box">
          <div className="top-img desk-o">
            <img src="assets/images/icon-signup3.svg" alt="" />
          </div>
          <div className="setting-list">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.next();
              }}
            >
              <div className="top-img mob-o">
                <img src="assets/images/icon-signup3.svg" alt="" />
              </div>

              <div className="buy-col from-head">
                <div className="step-heads">
                  <span
                    className="back-button"
                    onClick={(e) => {
                      e.preventDefault();
                      props.prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <div className="step-heads-title">
                    Choose a strong password.
                  </div>
                </div>

                <div className="buy-con flex-wrap">
                  <div className="set-user">
                    <label>PASSWORD</label>
                    <input
                      type={showPassword1 ? "text" : "password"}
                      className="user-pass"
                      placeholder="Password"
                      value={props.password1}
                      onChange={(e) =>
                        props.validatePassword(e.target.value, props.password2)
                      }
                      onBlur={() => {
                        props.setPasswordText(false);
                      }}
                    />
                    <span
                      className="signup-icon-r"
                      onClick={() => {
                        setShowPasword1(!showPassword1);
                      }}
                    >
                      <img src="assets/images/eye-icon.png" alt="" />
                    </span>
                  </div>
                  <div className="set-user">
                    <label>CONFIRM PASSWORD</label>
                    <input
                      type={showPassword2 ? "text" : "password"}
                      className="user-pass"
                      placeholder="Password"
                      value={props.password2}
                      onChange={(e) =>
                        props.validatePassword(props.password1, e.target.value)
                      }
                    />
                    <span
                      className="signup-icon-r"
                      onClick={() => {
                        setShowPasword2(!showPassword2);
                      }}
                    >
                      <img src="assets/images/eye-icon.png" alt="" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="login-col">
                {props.passwordText ? (
                  ""
                ) : (
                  <p className="feild-error-y">{props.passwordError}</p>
                )}
                {/* <p className='feild-error-y'>{props.passwordError}</p> */}
                {props.passwordText ? (
                  <p className="feild-error-y">
                    Password should be minumum 8 characters, at least one
                    uppercase, one lowercase, one digit, one special character.
                  </p>
                ) : (
                  ""
                )}

                <div className="buy-btn">
                  {props.step2Btn ? (
                    <button type="submit" className="custom-btn btn-full">
                      Next
                    </button>
                  ) : (
                    <button disabled className="custom-btn btn-full">
                      Next
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

function Step3(props) {
  return (
    <section className="sec buy-sec login-sec">
      <div className="container">
        <div className="setting-box">
          <div className="top-img desk-o">
            <img src="assets/images/icon-signup4.svg" alt="" />
          </div>
          <div className="setting-list">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.next();
              }}
            >
              <div className="top-img mob-o">
                <img src="assets/images/icon-signup4.svg" alt="" />
              </div>

              <div className="buy-col from-head">
                <div className="step-heads">
                  <span
                    className="back-button"
                    onClick={(e) => {
                      e.preventDefault();
                      props.prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <div className="step-heads-title">
                    What is your email address ?
                  </div>
                </div>

                <div className="buy-con flex-wrap">
                  <div className="set-user">
                    <label>EMAIL</label>
                    <input
                      type="text"
                      className="user-email"
                      placeholder="name@example.com"
                      value={props.email1}
                      onChange={(e) =>
                        props.validateEmail(e.target.value, props.email2)
                      }
                    />
                  </div>
                  <div className="set-user">
                    <label>CONFIRM EMAIL</label>
                    <input
                      type="text"
                      className="user-email"
                      placeholder="name@example.com"
                      value={props.email2}
                      onChange={(e) =>
                        props.validateEmail(props.email1, e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="login-col">
                <p className="feild-error-y">{props.emailError}</p>
                <div className="buy-btn">
                  {props.step3Btn ? (
                    <button type="submit" className="custom-btn btn-full">
                      Next
                    </button>
                  ) : (
                    <button disabled className="custom-btn btn-full">
                      Next
                    </button>
                  )}
                </div>
                <div
                  className="skip-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    props.next();
                  }}
                >
                  DON’T HAVE EMAIL? <span>SKIP</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

function Step4(props) {
  return (
    <section className="sec buy-sec login-sec">
      <div className="container">
        <div className="setting-box">
          <div className="top-img desk-o">
            <img src="assets/images/icon-signup5.svg" alt="" />
          </div>
          <div className="setting-list">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.next();
              }}
            >
              <div className="top-img mob-o">
                <img src="assets/images/icon-signup5.svg" alt="" />
              </div>

              <div className="buy-col from-head">
                <div className="step-heads">
                  <span
                    className="back-button"
                    onClick={(e) => {
                      e.preventDefault();
                      props.prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <div className="step-heads-title">
                    What is your ID Number?
                  </div>
                </div>

                <div className="buy-con flex-wrap">
                  <div className="set-user">
                    <label>IDENTITY NUMBER</label>
                    <input
                      type="text"
                      className="user-id"
                      placeholder="900530 0155 089"
                      value={props.nationalId}
                      maxLength="13"
                      onChange={(e) => props.validateNationalId(e.target.value)}
                    />
                    <div className="text-muted mt-4">
                      Make sure to enter your correct identity number as a copy
                      of your ID must be presented when claiming any competition
                      winnings.
                    </div>
                  </div>
                </div>
              </div>
              <div className="login-col">
                <p className="feild-error-y">{props.nationalIdError}</p>
                <div className="buy-btn">
                  {props.step4Btn ? (
                    <button type="submit" className="custom-btn btn-full">
                      Next
                    </button>
                  ) : (
                    <button disabled className="custom-btn btn-full">
                      Next
                    </button>
                  )}
                </div>

                {/* {props.userCountryCode === "224" ? (
                  ""
                ) : (
                  <div
                    className="skip-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      props.next();
                    }}
                  >
                    DON’T HAVE NATIONAL ID? <span>SKIP</span>
                  </div>
                )} */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

function Step5(props) {
  return (
    <section className="sec buy-sec login-sec">
      <div className="container">
        <div className="setting-box">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.next();
            }}
          >
            <div className="top-img desk-o">
              <img src="assets/images/icon-signup6.svg" alt="" />
            </div>
            <div className="setting-list">
              <div className="top-img mob-o">
                <img src="assets/images/icon-signup6.svg" alt="" />
              </div>

              <div className="buy-col setting-head from-head">
                <h4 style={{ display: "flex" }}>
                  <span
                    className="back-button"
                    onClick={(e) => {
                      e.preventDefault();
                      props.prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <h3>
                    Viva ma choice! Viva democratization.
                    <span>I support the community that I love in...</span>
                  </h3>
                </h4>
                <div className="buy-con flex-wrap pt-3">
                  <div className="set-user">
                    <label>NOMINATE COMMUNITY</label>
                    <select
                      className="user-kingdom"
                      onChange={(e) => props.validateKingdom(e.target.value)}
                    >
                      <option value={""}>Choose Any one</option>
                      {props.kingdomList.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.id}
                            selected={Number(props.kingdomId) === data.id}
                          >
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="select-icon-r">
                      <i className="fas fa-sort-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="login-col">
                <p className="feild-error-y">{props.kingdomIdError}</p>
                <div className="buy-btn">
                  {props.step5Btn ? (
                    <button type="submit" className="custom-btn btn-full">
                      Next
                    </button>
                  ) : (
                    <button disabled className="custom-btn btn-full">
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

function Step6(props) {
  return (
    <section className="sec buy-sec login-sec">
      <div className="container">
        <div className="setting-box">
          <div className="top-img desk-o">
            <img src="assets/images/icon-signup7.svg" alt="" />
          </div>
          <div className="setting-list">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.next();
              }}
            >
              <div className="top-img mob-o">
                <img src="assets/images/icon-signup7.svg" alt="" />
              </div>

              <div className="buy-col from-head">
                <div className="step-heads">
                  <span
                    className="back-button"
                    onClick={(e) => {
                      e.preventDefault();
                      props.prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <div className="step-heads-title">
                    Do you have a referral code?
                  </div>
                </div>

                <div className="buy-con flex-wrap pt-4">
                  <div className="set-user">
                    <label>ENTER REFERRAL CODE</label>
                    <input
                      type="text"
                      className="user-referral"
                      placeholder="Enter Code Here"
                      value={props.referralId}
                      onChange={(e) => props.validateReferralId(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="login-col">
                <p className="feild-error-y">{props.isreferralError}</p>
                <div className="buy-btn">
                  {props.isreferralrequire ? (
                    <button className="custom-btn btn-full">Next</button>
                  ) : (
                    <button type="submit" className="custom-btn btn-full">
                      Next
                    </button>
                  )}
                </div>
                <div
                  className="skip-btn btn-cursor"
                  onClick={(e) => {
                    e.preventDefault();
                    // props.validateIsReferralId(false)
                    props.next();
                  }}
                >
                  DON’T HAVE A CODE? <span>SKIP</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

function Step7(props) {
  console.log("3DIHTVLE", config.RECAPTCHA_SITE_KEY);
  function onChange(value) {
    props.setCaptCha(value);
    console.log("Captcha value:", value);
  }

  return (
    <section className="sec buy-sec login-sec">
      <div className="container">
        <div className="setting-box">
          <div className="top-img desk-o">
            <img src="assets/images/icon-signup7.svg" alt="" />
          </div>
          <div className="setting-list">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.RegisterUser();
                // props.next();
              }}
            >
              <div className="top-img mob-o">
                <img src="assets/images/icon-signup7.svg" alt="" />
              </div>

              <div className="buy-col from-head">
                <div className="step-heads">
                  <span
                    className="back-button"
                    onClick={(e) => {
                      e.preventDefault();
                      props.prev();
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <div className="step-heads-title">Terms & conditions</div>
                </div>

                <div
                  className="buy-min-max justify-content-center d-flex my-3"
                  style={{
                    gap: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    name="i_understand"
                    onClick={(e) => {
                      props.validateUnderstand(e.target.checked);
                    }}
                  />{" "}
                  I accept all terms and conditions of COWRIE.
                </div>
                <div className="float-center justify-content-center d-flex">
                  <ReCAPTCHA
                    sitekey={config.RECAPTCHA_SITE_KEY}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="login-col">
                <p className="feild-error-y"> {props.registerError}</p>
                <div className="buy-btn">
                  {props.step7Btn && props.captCha.length > 10 ? (
                    <button type="submit" className="custom-btn btn-full">
                      Register
                    </button>
                  ) : (
                    <button
                      disabled
                      className="custom-btn btn-full"
                      style={{ background: "gray" }}
                    >
                      Register
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
